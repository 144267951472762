var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mia-CoverVideoPanel" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", {
          directives: [
            {
              name: "tr",
              rawName: "v-tr",
              value: "LandingPage_CoverPanel_Motto",
              expression: "'LandingPage_CoverPanel_Motto'"
            }
          ],
          staticClass: "mia-cover-text"
        }),
        _vm._v(" "),
        _vm.videoUrl
          ? _c("div", { staticClass: "mia-play-button-wrapper" }, [
              _c(
                "div",
                {
                  staticClass: "mia-play-button",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.open($event)
                    }
                  }
                },
                [
                  _c("inline-svg", {
                    attrs: {
                      src: "static-images/shared/shared/icons/play_video.svg"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          staticClass: "mia-cover-image",
          attrs: {
            src: require("theme/parents/landingpage/coverImage.png"),
            alt: _vm.$tr("CoverImageAlt")
          }
        }),
        _vm._v(" "),
        _vm.videoUrl
          ? _c(
              "lightbox",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isVideoOpen,
                    expression: "isVideoOpen"
                  }
                ],
                on: { close: _vm.close }
              },
              [
                _c("vimeo-player", {
                  ref: "vimeoPlayer",
                  attrs: {
                    "video-src": _vm.videoUrl,
                    "enable-google-tracking": true
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }