import { ProgressIndicator } from "../../utils/ProgressIndicating";

export class PurchaseInformationBox extends ProgressIndicator {
	private readonly infoBox: JQuery;

	constructor(containerId: DOMElementIdentifier, _constructionParameters: ConstructionParameters) {
		super(jQuery("#" + containerId)[0]);

		this.infoBox = jQuery("#" + containerId);
		this.infoBox.on("updatePurchaseInformationBox", this.onUpdate.bind(this));
	}

	private onUpdate(_event: JQuery.Event) {
		this.showProgressIndicator(true, "", false);
		const url = this.infoBox.data("update-url");
		void jQuery.post(url)
			.done(this.onUpdateSucceeded.bind(this))
			.fail(this.onRequestFailed.bind(this));
	}

	private onUpdateSucceeded(data: any) {
		this.hideProgressIndicator();
		if (data.success) {
			this.infoBox.replaceWith(data.newPurchaseInformationBox);
		}
	}

	private onRequestFailed(xhr: JQuery.jqXHR, error: any) {
		// eslint-disable-next-line no-console
		console.log(xhr);
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
