var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container mia-announcementContainer" },
      [
        _c("responsive-image", {
          staticClass: "mia-newIcon",
          attrs: { from: _vm.newIcon }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mia-announcementText" }, [
          _vm.includeLink
            ? _c("div", [
                _c(
                  "a",
                  { attrs: { href: _vm.$tr("miaprepannouncement.link") } },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "tr",
                          rawName: "v-tr",
                          value: "miaprepannouncement.linktext",
                          expression: "'miaprepannouncement.linktext'"
                        }
                      ]
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "tr",
                      rawName: "v-tr",
                      value: "miaprepannouncement.linktextremainder",
                      expression: "'miaprepannouncement.linktextremainder'"
                    }
                  ]
                })
              ])
            : _c("span", {
                directives: [
                  {
                    name: "tr",
                    rawName: "v-tr",
                    value: "miaprepannouncement.plaintext",
                    expression: "'miaprepannouncement.plaintext'"
                  }
                ]
              })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }