import { Component, Prop } from "vue-property-decorator";
import BufferedBase from "./BufferedBase";
import PreloadingModuleAdapter from "./ModuleAdapters/PreloadingModuleAdapter";

@Component
export default class BufferedListItems<S> extends BufferedBase<S[]> {
	@Prop({ required: true, type: PreloadingModuleAdapter }) protected readonly adapter!: PreloadingModuleAdapter<S>;

	public preload(index: number): Promise<void> {
		return this.adapter.load([index]);
	}

	protected getFromBackend(): S[] {
		return this.adapter.module.items;
	}

	protected loadInBackend(): Promise<void> {
		// Preload the first pages of content.
		return this.adapter.load([0]);
	}

	protected invalidateInBackend(): Promise<void> {
		return this.adapter.module.reset();
	}

	public get totalCountEstimate(): number {
		return this.adapter.module.totalCountEstimate;
	}

	protected isBackendResetting(): boolean {
		return this.adapter.module.isResetting;
	}
}
