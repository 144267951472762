import { ProgressIndicator } from "../../utils/ProgressIndicating";

export class ProductSelector extends ProgressIndicator {
	private readonly productSelector: JQuery;

	constructor(containerId: DOMElementIdentifier, _constructionParameters: ConstructionParameters) {
		super(jQuery("#" + containerId)[0]);

		this.productSelector = jQuery("#" + containerId);
		this.productSelector.on("updateProductSelector", this.onUpdate.bind(this));
	}

	private onUpdate(_event: JQuery.Event) {
		this.showProgressIndicator(true, "", false);
		const url = this.productSelector.data("update-url");
		void jQuery.post(url)
			.done(this.onUpdateSucceeded.bind(this))
			.fail(this.onRequestFailed.bind(this));
	}

	private onUpdateSucceeded(data: any) {
		this.hideProgressIndicator();
		if (data.success) {
			this.productSelector.replaceWith(data.newProductSelector);
		}
	}

	private onRequestFailed(xhr: JQuery.jqXHR, error: string) {
		// eslint-disable-next-line no-console
		console.log(xhr);
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
