import { Step } from "../Step";
import { StepType } from "../StepType";

export abstract class RegisterStep extends Step {
	constructor(step: JQuery, type: StepType) {
		super(step, type);
	}

	public onStepChanging(newStepType: StepType): Promise<boolean> {
		if (newStepType === StepType.BuyMembership) {
			return this.register();
		}

		return super.onStepChanging(newStepType);
	}

	protected register(): Promise<boolean> {
		this.showProgressIndicator(true, "", true);

		return new Promise((resolve) => {
			const registerUrl = jQuery(".mia-RegistrationWizard").data("finish-url");
			void jQuery.post(registerUrl, this.wizardData)
				.done((data: any) => {
					if (data.success) {
						jQuery(".mia-RegistrationWizard").find("a[href=\"#next\"]").removeAttr("href");
					} else if (data.errorMessage) {
						this.showErrorMessage(data.errorMessage);
					}

					this.hideProgressIndicator();
					resolve(data.success);
				})
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(this.onRequestFailed);
		});
	}
}
