import { prompt } from "./../Control/Vue/Notifications/Prompts";
import { HandlerType } from "./base/HandlerType";

export class NewspaperAdSaveButton implements HandlerType {
	protected readonly saveAdButton: JQuery;
	protected readonly saveAdUrl: string;
	protected readonly isDraft: boolean;
	constructor(private button: JQuery) {
		this.saveAdButton = this.button;
		this.saveAdButton.on("click", this.onButtonClick.bind(this));
		this.saveAdUrl = button.data("url");
		this.isDraft = button.data("is-draft");
	}

	private onButtonClick(event: JQuery.Event) {
		event.preventDefault();
		const data = {
			attachedItemId: jQuery("#attachedItemSelector").val(),
			body: jQuery(".mia-adBody > textarea").val(),
			heading: jQuery(".mia-adHeading > textarea").val(),
		};

		void jQuery.post(this.saveAdUrl, data)
		.then((response) => {
			if (!this.isDraft) {
				window.location.reload();
			} else {
				void prompt(response);
			}
		});
	}
}
