import { ProgressIndicator } from "../../utils/ProgressIndicating";

export class PurchaseSummary extends ProgressIndicator {
	private readonly purchaseSummary: JQuery;
	private readonly couponCodeModal: JQuery;

	constructor(containerId: DOMElementIdentifier, _constructionParameters: ConstructionParameters) {
		super(jQuery("#" + containerId)[0]);

		this.purchaseSummary = jQuery("#" + containerId);

		this.couponCodeModal = jQuery(".modal.mia-couponCodeModal");
		this.couponCodeModal.on("show.bs.modal", this.onCouponCodeModalShow.bind(this));

		this.purchaseSummary.on("updatePurchaseSummary", this.onUpdate.bind(this));
	}

	private onCouponCodeModalShow(_event: JQuery.Event) {
		jQuery("input")[0].focus();
	}

	private onUpdate(_event: JQuery.Event) {
		this.couponCodeModal.modal("hide");
		this.showProgressIndicator(true, "", false);
		const url = this.purchaseSummary.data("update-url");
		void jQuery.post(url)
			.done(this.onUpdateSucceeded.bind(this))
			.fail(this.onRequestFailed.bind(this));
	}

	private onUpdateSucceeded(data: any) {
		this.hideProgressIndicator();
		if (data.success) {
			this.purchaseSummary.replaceWith(data.newPurchaseSummary);
		}
	}

	private onRequestFailed(xhr: JQuery.jqXHR, error: string) {
		// eslint-disable-next-line no-console
		console.log(xhr);
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
