import I18Next from "../../Control/Vue/I18Next";
import { errorPrompt, prompt } from "./../../Control/Vue/Notifications/Prompts";
import { TextEditor } from "./TextEditor";

export class StatusMessageEditor extends TextEditor {
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onSuccess(res: any): void {
		if (res.message) {
			void prompt(res.message, [{ label: I18Next.tr("OkCancel_Ok"), value: undefined }]);
		}
	}

	protected onPrimaryClick(event: JQuery.Event): void {
		event.preventDefault();

		if (this.element.find("textarea").val()) {
			this.primaryButton.button("loading");
			void jQuery.post(
				this.primaryButton.data("url"),
				{text: this.element.find("textarea").val()}).then((res) => {
					if (res.success) {
						window.location.reload();
					} else {
						errorPrompt(res.message);
						this.primaryButton.button("reset");
					}
			});
		} else {
			void prompt(this.primaryButton.data("error-empty")).then(() => {
				this.element.find("textarea").focus();
			});
		}
	}
}
