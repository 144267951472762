import { VueConstructor, AsyncComponent } from "vue";

type IBackendOptions = ISwiperOptions | IInfiniteLoadingOptions | IBootstrapTableOptions | IExpandableOptions;

export interface IVueListOptions {
	backendOptions: IBackendOptions;
	component: VueConstructor | AsyncComponent;
	pageSize: number;
}

export function isSwiper(options: IBackendOptions):
	options is ISwiperOptions {
	return (options as ISwiperOptions).direction !== undefined;
}

export function isInfiniteLoading(options: IBackendOptions):
	options is IInfiniteLoadingOptions {
	return (options as IInfiniteLoadingOptions).distance !== undefined;
}

export function isBootstrapTable(options: IBackendOptions):
	options is IBootstrapTableOptions {
	return (options as IBootstrapTableOptions).columnKeys !== undefined;
}

export function isExpandable(options: IBackendOptions):
	options is IExpandableOptions {
		return !isSwiper(options) && !isInfiniteLoading(options) && !isBootstrapTable(options);
}

export interface ISwiperBreakpointOptions {
	slidesPerView?: number;
	adjacentItemPortion?: number;
	spaceBetween?: number;
}

export interface ISwiperOptions extends ISwiperBreakpointOptions {
  slidesPerColumn: number,
	direction?: "horizontal" | "vertical";
	centeredSlides?: boolean;
	slidesPerGroup?: number;
	autoHeight?: boolean;
	centerInsufficientSlides?: boolean;
	includeNavigationButtons?: boolean;
	displayAdjacentItems?: boolean;
	navigation?: {
		nextEl: string;
		prevEl: string;
	};
	speed?: number;
	spaceBetween?: number;
	observer?: boolean;
	observeParents?: boolean;
	slidesOffsetAfter: number;
	slidesOffsetBefore: number;
	cssClass?: string;
	grabCursor?: boolean;
	breakpointsInverse?: boolean;
	mousewheel?: {
		invert: boolean;
	};
	breakpoints?: {
		[key: number]: ISwiperBreakpointOptions,
	};
	useTransitionOnEmptying?: boolean;
}

export interface IInfiniteLoadingOptions {
	// The distance to the viewport edge (or the scrollable area edge) that triggers a reload
	distance: number;
}

export interface IBootstrapTableOptions {
	columnKeys: string[];
	hover?: boolean;
	striped?: boolean;
	condensed?: boolean;
	responsive?: boolean;
}

export type IExpandableOptions = Record<string, never>;
