import { Vue } from "vue-property-decorator";
import { StepType } from "../StepType";
import { RegisterStep } from "./RegisterStep";

export class BuyMembership extends RegisterStep {
	constructor(step: JQuery) {
		super(step, StepType.BuyMembership);
	}

	public onStepLoad(): void {
		this.showProgressIndicator(true, "", true);
		void jQuery.post(this.step.data("url"))
			.done((data) => {
				this.step.html(data.stepBody);
				// This is an evil hack to dynamically add a vue component after the page has loaded
				// eslint-disable-next-line no-new
				new Vue({
					el: `mia-checkout`,
					template: jQuery("mia-checkout").first()[0].outerHTML,
				});
				// The same hack for the checkout button via portal-target
				// eslint-disable-next-line no-new
				new Vue({
					el: `portal-target`,
					template: jQuery("portal-target").first()[0].outerHTML,
				});
				this.hideProgressIndicator();
			})
			// eslint-disable-next-line @typescript-eslint/unbound-method
			.fail(this.onRequestFailed);

		const registrationWizard = jQuery(".mia-RegistrationWizard");
		registrationWizard.find(".steps [role='tablist'] [role='tab'].done").removeClass("done").addClass("disabled");
		registrationWizard.find(".actions").hide();
	}

	public trackCompletedStepWithGA(): void {
		if (typeof gtag === 'function') {
			gtag('event', 'completed BuyMembership step', {
				event_category: 'register'
			});
		}
	}
}
