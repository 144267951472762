export class AntiSpamCountryLaw {
	public static OnCountryDropDownChange(event: JQuery.TriggeredEvent): void {
		const countryDropDown = jQuery(event.target);

		const isAntiSpamCountry = AntiSpamCountryLaw.IsAntiSpamCountrySelected(countryDropDown);
		const emailPreferencesBox = jQuery(".mia-emailPreferences");
		AntiSpamCountryLaw.toggleEmailPreferencesBoxVisibility(emailPreferencesBox, isAntiSpamCountry);

		const isPersonalDataProtectionCountry = AntiSpamCountryLaw.isPersonalDataProtection(countryDropDown);
		const personalDataProtectionBox = jQuery(".mia-personalDataProtection");
		AntiSpamCountryLaw.toggleEmailPreferencesBoxVisibility(personalDataProtectionBox, isPersonalDataProtectionCountry);
	}

	public static IsAntiSpamCountrySelected(countryDropDown: JQuery<Element>): boolean {
		const selectedCountryOption = countryDropDown.find("option:selected");
		return selectedCountryOption.data("anti-spam");
	}

	public static GetNewsletterValue(): boolean | null {
		return AntiSpamCountryLaw.getEmailPreferenceValue("acceptNewsletters");
	}

	public static GetChildReportValue(): boolean | null {
		return AntiSpamCountryLaw.getEmailPreferenceValue("acceptChildReports");
	}

	private static isPersonalDataProtection(countryDropDown: JQuery<Element>): boolean {
		const selectedCountryOption = countryDropDown.find("option:selected");
		return selectedCountryOption.data("protection");
	}

	private static toggleEmailPreferencesBoxVisibility(box: JQuery, show: boolean) {
		if (show) {
			box.removeClass("hidden");
			box.slideDown();
		} else {
			box.slideUp();
		}
	}

	private static getEmailPreferenceValue(id: string): boolean | null {
		const acceptNewsletters = jQuery(".mia-emailPreferences #" + id);
		return acceptNewsletters.length
			? acceptNewsletters.is(":checked")
			: null;
	}
}
