import { MiaPlaza } from "../Reinforced.Typings";
import { okCancel, prompt } from "./../Control/Vue/Notifications/Prompts";

export class NewspaperEditor {
	private readonly setPriceOverlay: JQuery;
	private readonly categorySelect: JQuery;
	private readonly saveDraftButton: JQuery;
	private readonly submitButton: JQuery;
	private readonly newspaperExampleArticleTooltip: JQuery;
	private readonly submitAlwaysDisabled: boolean;
	private exampleArticles!: MiaPlaza.Control.Newspaper.IExampleArticle[];
	private readonly categorySelector: string = "#newspaperCreatorCategorySelector";
	private selectedExampleArrticle!: MiaPlaza.Control.Newspaper.IExampleArticle | null;
	private requestPromise: JQueryXHR | null = null;
	constructor(containerId: DOMElementIdentifier, private constructionParameters: ConstructionParameters) {
		const container = jQuery("#" + containerId);
		this.setPriceOverlay = container.find("#setPriceOverlay");
		void jQuery.get(container.data("example-articles")).done((articles: MiaPlaza.Control.Newspaper.IExampleArticle[]) => {
			this.exampleArticles = articles;
		});
		this.saveDraftButton = container.find("#newspaperSaveDraftButton");
		this.saveDraftButton.click(this.onSaveDraftButtonClick.bind(this));

		const deleteDraftButton = container.find("#newspaperDeleteDraftButton");
		deleteDraftButton.click(this.onDeleteDraftButtonClick.bind(this));

		this.submitButton = container.find("#newspaperSubmitArticleButton");
		this.submitButton.click(this.onSubmitClick.bind(this));

		this.categorySelect = jQuery(this.categorySelector);
		this.categorySelect.on("change", this.onCategorySelectChange.bind(this));

		this.newspaperExampleArticleTooltip = jQuery("#newspaperExampleArticleTooltip");
		this.submitAlwaysDisabled = this.submitButton.attr("data-error-not-today") !== undefined;
		this.updateButtons();
		this.updateTooltip();
	}

	private updateButtons() {
		const setStatus = (el: JQuery, status: boolean, changeTooltip: boolean) => {
			el[status ? "removeClass" : "addClass"]("disabled");
			if (changeTooltip) {
				el.prop("title", status ? "" : el.data("error-tooltip") || "ERR_TOOLTIP_UNDEFINED");
			}
		};

		const validCat = this.categorySelect.val() !== "npCreatorCategoryUnselected";
		setStatus(this.submitButton, validCat && !this.submitAlwaysDisabled, !this.submitAlwaysDisabled);
		setStatus(this.saveDraftButton, validCat, true);
	}

	private updateTooltip() {
		if (this.selectedExampleArrticle) {
			this.newspaperExampleArticleTooltip.html(this.selectedExampleArrticle.Category);
		} else {
			this.newspaperExampleArticleTooltip.html("some_default_key_placeholder");
		}
	}

	private onCategorySelectChange(event: JQueryEventObject) {
		event.preventDefault();

		this.selectedExampleArrticle = this.getCategoryExampleById(Number(this.categorySelect.val()));
		if (this.selectedExampleArrticle) {
			jQuery(".mia-showExampleArticleLink").show();
			jQuery(".mia-articleExample").find(".modal-header h3").html(this.selectedExampleArrticle.Category );
			jQuery(".mia-articleExample").find(".modal-body").html("<h3>" + this.selectedExampleArrticle.Title + "</h3>" +
			"<p>" + this.selectedExampleArrticle.Body + "</p>");
		} else {
			jQuery(".mia-showExampleArticleLink").hide();
		}
		this.updateButtons();
		this.updateTooltip();
	}

	private onSaveDraftButtonClick(event: any): void {
		event.preventDefault();
		if (event.currentTarget.classList.contains("disabled")) {
			void prompt(event.currentTarget.data("error-tooltip") ||
				"ERR_TOOLTIP_UNDEFINED");
			return;
		}

		if (this.constructionParameters.isNewArticle) {
			this.saveDraft();
		} else {
			void okCancel(this.constructionParameters.saveDraftConfirmationText)
				.then((isConfirmed: boolean) => {
					if (isConfirmed) {
						this.saveDraft();
					}
				});
		}
	}

	private saveDraft(): void {
		if (this.isRequestExecutionAllowed()) {
			const saveDraftData = {
				body: jQuery("#newspaperCreatorBodyTextBox").val(),
				categoryId: jQuery(this.categorySelector).val(),
				itemId: jQuery("#attachedItemSelector").val(),
				title: jQuery("#newspaperCreatorTitleTextBox").val(),
			};

			this.requestPromise = jQuery.post(this.constructionParameters.saveDraftUrl, saveDraftData)
				.done(this.onSaveDraftSucceeded.bind(this))
				.promise();
		}
	}

	private onSaveDraftSucceeded(data: any): void {
		if (data.success) {
			this.windowRedirectTo(data.redirectUrl);
		} else {
			this.showErrorMessage(data.errorMessage);
		}
	}

	private windowRedirectTo(location: string): void {
		if (location) {
			window.location.href = location;
		} else {
			window.location.reload();
		}
	}

	private showErrorMessage(errorMessage: string): void {
		if (errorMessage) {
			void prompt(errorMessage);
		}
	}

	private onDeleteDraftButtonClick(event: JQuery.Event): void {
		event.preventDefault();

		void okCancel(this.constructionParameters.deleteDraftConfirmationText)
				.then( (isConfirmed) => {
					if (isConfirmed) {
						this.deleteDraft();
					}
				});
	}

	private deleteDraft(): void {
		if (this.isRequestExecutionAllowed()) {
			this.requestPromise = jQuery.post(this.constructionParameters.deleteDraftUrl)
				.done(this.onDeleteDraftSucceeded.bind(this))
				.promise();
		}
	}

	private onDeleteDraftSucceeded(data: any): void {
		this.windowRedirectTo(data.redirectUrl);
	}

	private onSubmitClick(event: any): void {
		event.preventDefault();
		if (event.currentTarget.classList.contains("disabled")) {
			void prompt(event.currentTarget.data("error-not-today") ||
				event.currentTarget.data("error-tooltip") ||
				"ERR_TOOLTIP_UNDEFINED");
			return;
		}

		this.showSetPriceOverlay();

		const overlaySubmitButton = this.setPriceOverlay.find("#newspaperOverlayButtonSubmit");
		overlaySubmitButton.click(this.onOverlaySubmitClick.bind(this));

		const overlayCancelButton = this.setPriceOverlay.find("#newspaperOverlayButtonCancel");
		overlayCancelButton.click(this.onOverlayCancelClick.bind(this));
	}

	private showSetPriceOverlay(): void {
		this.setPriceOverlay.removeClass("hidden");
	}

	private hideSetPriceOverlay(): void {
		this.setPriceOverlay.addClass("hidden");
	}

	private onOverlaySubmitClick(event: JQuery.Event): void {
		event.preventDefault();

		if (this.isRequestExecutionAllowed()) {
			const submitData = {
				body: jQuery("#newspaperCreatorBodyTextBox").val(),
				categoryId: jQuery("#newspaperCreatorCategorySelector").val(),
				itemId: jQuery("#attachedItemSelector").val(),
				price: this.setPriceOverlay.find("#newspaperOverlayPriceInput").val(),
				title: jQuery("#newspaperCreatorTitleTextBox").val(),
			};

			this.requestPromise = jQuery.post(this.constructionParameters.submitArticleUrl, submitData)
				.done(this.onSubmitArticleSucceeded.bind(this))
				.promise();
		}
	}

	private isRequestExecutionAllowed() {
		return this.requestPromise == null || this.requestPromise.state() !== "pending";
	}

	private onSubmitArticleSucceeded(data: any): void {
		if (data.success) {
			this.windowRedirectTo(data.redirectUrl);
		} else {
			if (data.hideOverlay) {
				this.hideSetPriceOverlay();
			}
			this.showErrorMessage(data.errorMessage);
		}
	}

	private onOverlayCancelClick(event: JQuery.Event): void {
		event.preventDefault();

		this.hideSetPriceOverlay();
	}

	private getCategoryExampleById(categoryId: number)
	: MiaPlaza.Control.Newspaper.IExampleArticle | null {
		for (const elem of this.exampleArticles) {
			if (elem.CategoryId === categoryId) {
				return elem;
			}
		}
		return null;
	}
}
