import I18Next from "../../Control/Vue/I18Next";
import { prompt } from "./../../Control/Vue/Notifications/Prompts";
import { HandlerType } from "./HandlerType";

/**
 * This is a base class of the classes that are attached to controls,
 * that require a singular request, and informative dialogs on errors
 */
export abstract class RequestHandler implements HandlerType {
	protected promise?: JQueryXHR;

	private errorRequestDoneMessage: Promise<string>;
	private errorRequestFailMessage: Promise<string>;

	constructor(protected readonly element: JQuery) {
		const customErrorRequestDoneMessage = element.data("error-request-done");
		const customErrorRequestFailMessage = element.data("error-request-fail");

		this.errorRequestDoneMessage = customErrorRequestDoneMessage
			? Promise.resolve(customErrorRequestDoneMessage)
			: I18Next.tr("error_request_data_not_saved");

		this.errorRequestFailMessage = customErrorRequestFailMessage
			? Promise.resolve(customErrorRequestFailMessage)
			: I18Next.tr("error_request_failed");
	}

	protected setRequest(xhr: JQuery.jqXHR): void {
		if (this.promise && this.promise.state() === "pending") {
			return this.onFail(null);
		}
		this.promise = xhr.done(this.onDone.bind(this)).fail(this.onFail.bind(this)).promise();
	}

	/* This determines what happens post-request,
	 * when the request returns with success in its body
	 */
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected abstract onSuccess(res: any): void;

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onError(_res: any): void {
		void this.errorRequestDoneMessage.then((message) => prompt(message,
			[{ label: I18Next.tr("OkCancel_Ok"), value: undefined }]));
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onDone(res: any): void {
		if (res.success) {
			this.onSuccess(res);
		} else {
			this.onError(res);
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onFail(_res: any): void {
		void this.errorRequestFailMessage.then((message) => prompt(message,
			[{ label: I18Next.tr("OkCancel_Ok"), value: undefined }]));
	}
}
