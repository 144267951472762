import I18Next from "../Control/Vue/I18Next";
import { HandlerType } from "../controls/base/HandlerType";
import { Async } from "../utils/Async";
import { errorPrompt, prompt } from "./../Control/Vue/Notifications/Prompts";

export class CurrencyReward implements HandlerType {
	private readonly messageSelector: JQuery;
	private readonly messageInput: JQuery;
	private readonly amountInput: JQuery;
	private readonly rewardButton: JQuery;
	private requestPromise?: JQueryXHR;

	constructor(private container: JQuery) {
		this.messageSelector = container.find("#reward-message-select");
		this.messageInput = container.find("#reward-message-input");
		this.amountInput = container.find("#reward-amount-input");

		this.messageSelector.change(this.onRewardMessageChange.bind(this));

		this.rewardButton = container.find(".mia-rewardButton");
		this.rewardButton.click(this.onRewardButtonClick.bind(this));
	}

	private onRewardMessageChange(event: JQuery.Event) {
		event.preventDefault();
		const message = this.messageSelector.val();
		if (message) {
			this.messageInput.val(message);
			this.messageSelector.prop("selectedIndex", 0);
		}
	}

	private async onRewardButtonClick(event: JQuery.Event) {
		event.preventDefault();

		let amount = this.amountInput.val() as number;
		if (!amount || isNaN(amount) || amount === 0) {
			void prompt(this.amountInput.data("error"));
			return;
		}
		if (amount % 1 !== 0) {
			errorPrompt(await I18Next.tr("currencyreward_error_invalid_amount"));
			return;
		}
		amount = Math.floor(amount);
		const message = this.messageInput.val() as string;

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (Async.IsRequestExecutionAllowed(this.requestPromise!)) {
			this.rewardButton.button("loading");

			const url = this.rewardButton.data("url")
				.replace("PLH_amount", amount)
				.replace("PLH_Reason", message);

			this.requestPromise = jQuery.post(url)
				.done(this.onRewardDone.bind(this))
				.fail(this.onRewardFail.bind(this))
				.promise();
		}
	}

	private onRewardDone(data: any) {
		this.rewardButton.button("reset");
		if (data.success) {
			this.resetForm(data.amountLeft);
			void prompt(data.message, [{ label: I18Next.tr("OkCancel_Ok"), value: undefined }]);
		} else if (data.errorMessage) {
			void prompt(data.errorMessage);
		}
	}

	private resetForm(amountLeft: number) {
		this.messageInput.val("");
		this.amountInput.val("");

		const currentMax = Number(this.amountInput.attr("max"));
		if (isNaN(currentMax) || currentMax > amountLeft) {
			this.amountInput.attr("max", amountLeft);
		}

		const countdownNumber = this.container.find(".mia-CountdownBox .mia-number");
		countdownNumber.text(amountLeft);
	}

	private onRewardFail(xhr: JQuery.jqXHR, error: string) {
		this.rewardButton.button("reset");
		Async.OnRequestFailed(xhr, error);
	}
}
