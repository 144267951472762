/**
 * A utility class used to generate IDs for modals, tooltips, etc..
 */
export default class IdGenerator {
	public static Generate(prefix?: string): string {
		const randomString = Math.random().toString(36).substring(2);
		const prefixStatement = prefix ? prefix + '-' : '';
		
		const id = prefixStatement + randomString;
		
		return id;
	}
}
