export class NicknameValidator {
	private static readonly MAX_NICKNAME_LENGTH: number = 10;
	private static readonly allowedCharactersRegEx = /^[a-zA-Z.,`' -]*$/;
	private static readonly emojiRegEx = /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|\uFE0F/;

	private static readonly validNicknameRegEx = new RegExp(
		NicknameValidator.allowedCharactersRegEx.source + "|" + NicknameValidator.emojiRegEx.source
	);

	static characterCount(str: string): number {
		const normalized = str.normalize();

		const variantsSelector = /[\u{fe00}-\u{fe0f}]/gu;
		const skinModifiers = /[^\\p{L}\s\p{Punctuation}]?([\u{1f3fb}-\u{1f3ff}])/gu;
		const zeroJoinRegEx = /\u{200d}/gu;
		const regionalIndicators = /[\u{1f1e6}-\u{1f1ff}]{2}/gu;

		const purifiedStr = normalized
			.split(variantsSelector)
			.join("")
			.split(skinModifiers)
			.join("")
			.split(regionalIndicators)
			.join("R");

		const splitWithZero = purifiedStr.split(zeroJoinRegEx);

		if (splitWithZero.length === 1) {
			return [...splitWithZero.join("")].length;
		}

		const total = splitWithZero.reduce((sum, curr, currIndex) => {
			if (currIndex === 0) {
				return (sum += [...curr].length);
			}
			sum += [...curr].length - 1;
			return sum;
		}, 0);

		return total;
	}

	static isNicknameValid(nickname: string): boolean {
		return (
			NicknameValidator.characterCount(nickname) <= NicknameValidator.MAX_NICKNAME_LENGTH
			&& NicknameValidator.validNicknameRegEx.test(nickname)
		);
	}
  }
