var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      directives: [
        {
          name: "tr",
          rawName: "v-tr",
          value: "landingpage_membershippanel_explanation",
          expression: "'landingpage_membershippanel_explanation'"
        }
      ],
      staticClass: "mia-description"
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mia-informationTiles" },
      [
        _c("flip-card", {
          staticClass: "mia-tile",
          attrs: {
            icon: _vm.familyIcon,
            "header-tr": "membership_homeschooldiscounts_header",
            "description-tr": "membership_homeschooldiscounts_description",
            "button-text-tr": "membership_homeschooldiscounts_buttonText",
            "button-link": _vm.contactsUrl
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }