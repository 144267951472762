import { CheckboxPanel } from "./CheckboxPanel";

export class VideoCheckboxPanel extends CheckboxPanel {
	private readonly videoContainer: JQuery;
	private readonly toggleButton: JQuery;
	private readonly toggleButtonIcon: JQuery;

	private isVideoPlayerExpanded?: boolean;

	constructor(panel: JQuery) {
		super(panel);

		this.videoContainer = this.panel.find(".mia-playerContainer");
		this.toggleButton = this.panel.find(".mia-toggleButton");
		this.toggleButtonIcon = this.toggleButton.find(".mia-Glyphicon");

		this.toggleButton.click(this.onToggleClick.bind(this));
	}

	private onToggleClick(event: JQuery.Event) {
		event.preventDefault();
		event.stopPropagation();

		if (this.isVideoPlayerExpanded) {
			this.videoContainer.hide();
			this.toggleButtonIcon.removeClass(this.toggleButton.data("hide-button"));
			this.toggleButtonIcon.addClass(this.toggleButton.data("show-button"));
		} else {
			this.videoContainer.show();
			this.toggleButtonIcon.removeClass(this.toggleButton.data("show-button"));
			this.toggleButtonIcon.addClass(this.toggleButton.data("hide-button"));
		}
		this.isVideoPlayerExpanded = !this.isVideoPlayerExpanded;
	}
}
