













import { Component, Prop, Vue } from "vue-property-decorator";

import { MiaPlaza } from "../../../Reinforced.Typings";
import FlipCard from "@/Control/Vue/FlipCard.vue";

@Component({
	components: { FlipCard },
})
export default class MembershipFlipTiles extends Vue {
	@Prop({required: true}) protected contactsUrl!: string;
	@Prop({required: true}) protected familyIcon!: MiaPlaza.Navigation.JSON.Converters.ILazySizes;
}
