var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mia-flipCard",
      class: { reverted: _vm.isFlipped },
      on: {
        click: function($event) {
          _vm.isFlipped = !_vm.isFlipped
        }
      }
    },
    [
      _c("div", { staticClass: "mia-flipCard-inner" }, [
        _c(
          "div",
          { staticClass: "mia-frontSide" },
          [
            _c("h4", {
              directives: [
                {
                  name: "tr",
                  rawName: "v-tr",
                  value: _vm.headerTr,
                  expression: "headerTr"
                }
              ]
            }),
            _vm._v(" "),
            _c("h5", {
              directives: [
                {
                  name: "tr",
                  rawName: "v-tr",
                  value: "learnmore_with_arrow",
                  expression: "'learnmore_with_arrow'"
                }
              ],
              staticClass: "mia-learnMore"
            }),
            _vm._v(" "),
            _vm.icon
              ? _c("responsive-image", { attrs: { from: _vm.icon } })
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mia-backSide" }, [
          _c("h4", {
            directives: [
              {
                name: "tr",
                rawName: "v-tr",
                value: _vm.headerTr,
                expression: "headerTr"
              }
            ]
          }),
          _vm._v(" "),
          _c("p", {
            directives: [
              {
                name: "tr",
                rawName: "v-tr",
                value: _vm.descriptionTr,
                expression: "descriptionTr"
              }
            ]
          }),
          _vm._v(" "),
          _c("a", {
            directives: [
              {
                name: "tr",
                rawName: "v-tr",
                value: _vm.buttonTextTr,
                expression: "buttonTextTr"
              }
            ],
            staticClass: "btn mia-btn-primary",
            attrs: { href: _vm.buttonLink }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }