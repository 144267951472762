import { ProgressIndicator } from "../../utils/ProgressIndicating";
import I18Next from "./../../Control/Vue/I18Next";
import { prompt } from "./../../Control/Vue/Notifications/Prompts";
import { Step } from "./Step";
import { StepFactory } from "./StepFactory";

export class StepWizard extends ProgressIndicator {
	private readonly stepWizard: JQuery;
	private readonly loaderControl: JQuery;
	private readonly steppedControl: JQuery;
	private readonly form: JQuery;
	private readonly steps: Step[] = new Array<Step>();

	private wizardData: Record<string, unknown> = {};
	private requestPromise: JQueryXHR | null = null;

	constructor(containerId: DOMElementIdentifier, private constructionParameters: ConstructionParameters) {
		super(jQuery("#" + containerId)[0]);

		jQuery(window).bind("pageshow", () => {
			this.reloadPageIfNavigatedBackOrForward();
		});

		this.stepWizard = jQuery("#" + containerId);
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		this.loaderControl = jQuery(`#${this.constructionParameters.loadingControlId}`);
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		this.steppedControl = jQuery(`#${this.constructionParameters.steppedControlId}`);
		this.form = jQuery("form");

		jQuery(document).ready(this.initialize.bind(this));
	}

	/**
	 * To make sure that the wizard is always up to date we reload the page
	 * if the member navigated to the page with a wizard using browser navigation buttons.
	 *
	 * This is not ideal as the browser will start to load the cached page
	 * and then immediately reload the page whether it is needed or not.
	 * The user will experience a blink of the page but that is much better
	 * than allowing the user to work with the not up to date wizard.
	 *
	 * E.g. In the registration wizard "Check out" step, the member selects with PayPal payment option.
	 * After redirect to PayPal the member changes his/her mind and hits back browser button.
	 * The member lands on the registration wizard once more. Without this page reload the member
	 * would land at the first "Create child" step although the account has been already created.
	 */
	private reloadPageIfNavigatedBackOrForward() {
		if (window.performance
			&& window.performance.navigation
			&& window.performance.navigation.type === PerformanceNavigation.TYPE_BACK_FORWARD) {
			window.location.reload();
		}
	}

	private initialize() {
		this.showProgressIndicator(true, "div", true);

		this.steppedControl.steps({
			bodyTag: this.stepWizard.data("body-tag"),
			headerTag: this.stepWizard.data("header-tag"),
			onFinished: this.onFinished.bind(this),
			onFinishing: this.onFinishing.bind(this),
			onInit: this.onInit.bind(this),
			onStepChanged: this.onStepChanged.bind(this),
			// We use @types/jquery-steps but a different implementation which allows promises onStepChanging
			// therefore Promise<boolean> is actually the correct type but the typing file does not know.
			onStepChanging: this.onStepChanging.bind(this) as any,
			titleTemplate: "<div class=\"title\">#title#</div><span class=\"number\">#index#</span>",
		});
		this.setUpForm();
	}

	private setUpForm() {
		this.form.validate().settings.errorPlacement = (error: JQuery, element: JQuery) => {
			element.parents(".form-group").find(".error-container").append(error);
		};

		// allow validation of hidden elements
		this.form.validate().settings.ignore = ":hidden:not(.body.current #grade)";

		this.steps.forEach((step) => step.setUpValidation(this.form.validate().settings.rules));

		this.form.keypress(this.onFormKeypress.bind(this));
	}

	private onInit(_event: string, currentIndex: number) {
		this.initializeSteps();
		this.hideProgressIndicator();
		this.loaderControl.hide();
		this.showWizad();
		this.steps.forEach((step) => step.initialize());
		this.steps[currentIndex].onStepLoad();
		this.enableNextStep();
	}

	private initializeSteps() {
		const bodyTag = this.stepWizard.data("body-tag");
		const stepControls = this.stepWizard.find(bodyTag);
		jQuery.each(stepControls, (_index: number, step: any) => {
			const stepType = step.data("step");
			this.steps.push(StepFactory.GetStep(stepType, jQuery(step)));
		});
	}

	private showWizad() {
		this.steppedControl.css({
			position: "static",
			visibility: "visible",
		});
	}

	private enableNextStep() {
		const currentStep = this.steppedControl.find(".steps > ul > li.current");
		currentStep.next("li").removeClass("disabled");
	}

	private onStepChanging(_event: string, currentIndex: number, newIndex: number)
		: Promise<boolean> {

		// Allways allow previous action even if the current form is not valid!
		if (currentIndex > newIndex) {
			return Promise.resolve(true);
		}

		if (!this.form.valid()) {
			return Promise.resolve(false);
		}

		this.fillWizardInWithCurrentStepData(currentIndex, newIndex);
		const stepChangingPromise = this.steps[currentIndex].onStepChanging(this.steps[newIndex].Type);
		void jQuery.when(stepChangingPromise).done((status: boolean) => {
			if (status) {
				this.steps[currentIndex].trackCompletedStepWithGA();
			}
		});
		return stepChangingPromise;
	}

	private onStepChanged(_event: string, currentIndex: number, _priorIndex: number) {
		this.steps[currentIndex].onStepLoad();
		this.enableNextStep();
	}

	private fillWizardInWithCurrentStepData(currentIndex: number, newIndex: number) {
		this.steps[currentIndex].fillInData(this.wizardData);
		this.steps[currentIndex].setWizardData(this.wizardData);
		this.steps[newIndex].setWizardData(this.wizardData);
	}

	private onFinishing(_event: string, _currentIndex: number): boolean {
		return this.form.valid();
	}

	private onFinished(_event: string, currentIndex: number) {
		this.steps[currentIndex].trackCompletedStepWithGA();

		if (this.isRequestExecutionAllowed()) {
			this.showProgressIndicatorInCurrentStepBody();

			const data = this.wizardData;
			this.steps[currentIndex].fillInData(data);

			const finishUrl = this.stepWizard.data("finish-url");
			this.requestPromise = jQuery.post(finishUrl, data)
				.done(this.onFinishedSucceeded.bind(this))
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(this.onRequestFailed)
				.promise();
		}
	}

	private onFinishedSucceeded(data: any): boolean {
		this.hideProgressIndicator();
		if (data.success) {
			window.location.href = data.redirectUrl;
		} else {
			this.showErrorMessage(data.errorMessage);
		}
		return data.success;
	}

	private onFormKeypress(event: JQuery.Event) {
		if (event.which === 13) {
			const nextLink = jQuery("a[href=\"#next\"]");
			if (nextLink.is(":visible")) {
				nextLink.click();
				return;
			}

			const finishLink = jQuery("a[href=\"#finish\"]");
			if (finishLink.is(":visible")) {
				finishLink.click();
			}
		}
	}

	private showProgressIndicatorInCurrentStepBody() {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		this.showProgressIndicator(true, `${this.constructionParameters.bodyTag}.current`, true);
	}

	private showErrorMessage(errorMessage: string) {
		void prompt(errorMessage, [{ label: I18Next.tr("OkCancel_Ok"), value: undefined }]);
	}

	private isRequestExecutionAllowed() {
		return this.requestPromise == null || this.requestPromise.state() !== "pending";
	}

	private onRequestFailed(xhr: JQuery.jqXHR, error: string): void {
		// eslint-disable-next-line no-console
		console.log(xhr);
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
