var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal modal-centered fade",
      attrs: {
        id: _vm.modalId,
        "modal-id": _vm.modalId,
        tabindex: "-1",
        role: "dialog"
      }
    },
    [
      _vm.renderModalWhenClosed || _vm.shown
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: function() {
                    return _vm.$emit("clickOutside")
                  },
                  expression: "() => $emit('clickOutside')"
                }
              ],
              staticClass: "modal-dialog",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c(
                  "div",
                  { staticClass: "modal-header" },
                  [
                    _vm.cross
                      ? _c(
                          "a",
                          {
                            staticClass: "close",
                            attrs: { href: "#", "data-dismiss": "modal" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.$emit("crossClick")
                              }
                            }
                          },
                          [
                            _c("responsive-image", {
                              attrs: {
                                from: _vm.cross,
                                "inline-svg": _vm.inlineSvgCross
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("title")
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
                _vm._v(" "),
                _vm.$slots["footer"]
                  ? _c(
                      "div",
                      { staticClass: "modal-footer" },
                      [_vm._t("footer")],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$slots["nested-modal"]
                  ? _c(
                      "div",
                      { staticClass: "nested-modal" },
                      [_vm._t("nested-modal")],
                      2
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }