import range from "lodash-es/range";
import ListModule from "../ListModule";
import IModuleAdapter from "./IModuleAdapter";
import WrappingModuleAdapterBase from "./WrappingModuleAdapterBase";

const PRELOAD_PRECEDING_PAGES = 1;
const PRELOAD_FOLLOWING_PAGES = 3;

export default class PreloadingModuleAdapter<S> extends WrappingModuleAdapterBase<number, S, ListModule<S>> {
	protected readonly pageSize: number;

	constructor(wrapped: IModuleAdapter<number, S, ListModule<S>>, pageSize: number) {
		super(wrapped);
		this.pageSize = pageSize;
	}

	public async load(keys: number[]): Promise<void> {
		// We make sure that the previous and the next page + one item is always preloaded.
		// (We want the extra item, so the prev/next page buttons do not flicker when we go to the next page.)
		const minimalKeys = new Set<number>();
		for (const key of keys) {
			for (const neighbouringKey of range(Math.max(0, key - this.pageSize - 1),
				// this page
				(key + this.pageSize - 1) +
				// the next page + 1 item
				(this.pageSize + 1)
				// +1 as the range is [a, b)
				+ 1)) {
				minimalKeys.add(neighbouringKey);
			}
		}
		for (const key of range(this.module.items.length)) {
			minimalKeys.delete(key);
		}
		if (minimalKeys.size === 0) {
			// The previous and the next page are already loaded, so there is nothing to do.
			return;
		}
		// Since we have to load something anyway, we load more than necessary at once so that
		// we don't have to load again with the next page flip.
		return await this.wrapped.load(range(
			Math.max(0, Math.min(...minimalKeys) - this.pageSize * (PRELOAD_PRECEDING_PAGES - 1)),
			Math.max(...minimalKeys) + this.pageSize * (PRELOAD_FOLLOWING_PAGES - 1)));
	}
}
