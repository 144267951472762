






















import { Component, Vue } from "vue-property-decorator";
import ResponsiveImage from "@/Control/Vue/ResponsiveImage";
import LearnersMindPicture from "theme/parents/landingpage/learners_mind.svg";
import TimersIcon from "theme/parents/landingpage/icons/icon_timer.svg";
import VideoIcon from "theme/parents/landingpage/icons/icon_video.svg";
import LessonOptionsIcon from "theme/parents/landingpage/icons/icon_lesson_option.svg";
import SuplementalsIcon from "theme/parents/landingpage/icons/icon_supplemental_materials.svg";
import CurriculumIcon from "theme/parents/landingpage/icons/icon_curriculum.svg";

@Component({
	components: { ResponsiveImage },
})
export default class AccessibilityInfoPanel extends Vue {
	private get infoPoints(): InfoBulletPoint[] {
		return [{
				IconUrl: TimersIcon,
				MessageTextResource: "accessibility_info_panel_optional_timers"
			}, {
				IconUrl: VideoIcon,
				MessageTextResource: "accessibility_info_panel_engaging_videos"
			}, {
				IconUrl: LessonOptionsIcon,
				MessageTextResource: "accessibility_info_panel_read_aloud_feature"
			}, {
				IconUrl: SuplementalsIcon,
				MessageTextResource: "accessibility_info_panel_suplemental_materials"
			}, {
				IconUrl: CurriculumIcon,
				MessageTextResource: "accessibility_info_panel_personalized_curriculum"
			},
		] as InfoBulletPoint[];
	}

	private get mainImg(): string {
		return LearnersMindPicture;
	}
}

interface InfoBulletPoint {
	IconUrl: string,
	MessageTextResource: string,
}
