














































import { Vue, Component, Prop } from "vue-property-decorator";
import { MiaPlaza } from "@/Reinforced.Typings";
import Funds from "@/common/Funds.vue";
import Logo from "@/layout/common/Logo.vue";
import ResponsiveImage from "@/Control/Vue/ResponsiveImage";
import FundsModuleRegistration from "@/Control/Vue/Vuex/FundsModuleRegistration"
import FundsHubConnection from "@/SignalR/FundsHubConnection";

@Component({
	components: { 
		Funds, 
		Logo, 
		ResponsiveImage, 
		FundsModuleRegistration, 
		FundsHubConnection, 
		TutorialVideosDropdown: async() => await import("@/Control/Vue/TutorialVideos/TutorialVideosDropdown.vue") 
	}
})
export default class Header extends Vue {
	@Prop({ type: Object, required: true }) private profileData!: MiaPlaza.Control.Vue.IProfileData;
	@Prop({ type: String, required: true }) private parentsPortalLink!: string;
	@Prop({ type: String, required: true }) private logoutLink!: string;
	@Prop({ type: Boolean, required: true }) private profilePage!: boolean;
	@Prop({ type: Boolean, required: false, default: false }) private maintenance!: boolean;
	@Prop({ type: Boolean, required: true }) private development!: boolean;
	@Prop({ type: Boolean, required: true }) private isLearningRequired!: boolean;
	@Prop({ type: Boolean, required: true }) private feedbackSoundOn!: boolean;
	@Prop({ type: Boolean, required: false, default: false}) isActiveMohsStudent!: boolean;
	@Prop({ required: true, type: Array }) private videos!: MiaPlaza.ConfigModel.Video.IVideoSetting[];
}

