var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mia-Feature" }, [
    _c(
      "div",
      { staticClass: "mia-featureContent row" },
      [
        _c("responsive-image", {
          staticClass: "mia-featureImage",
          attrs: {
            from:
              "/static-images/shared/shared/features/" + _vm.featureKey + ".svg"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "mia-featureDescription col-xs-50" }, [
          _c("h3", {
            directives: [
              {
                name: "tr",
                rawName: "v-tr",
                value:
                  "landingpage_rewardandsocial_" + _vm.featureKey + "_header",
                expression: "`landingpage_rewardandsocial_${featureKey}_header`"
              }
            ],
            staticClass: "responsive-h3"
          }),
          _vm._v(" "),
          _c("p", {
            directives: [
              {
                name: "tr",
                rawName: "v-tr",
                value:
                  "landingpage_rewardandsocial_" + _vm.featureKey + "_desc",
                expression: "`landingpage_rewardandsocial_${featureKey}_desc`"
              }
            ],
            staticClass: "responsive-paragraph"
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }