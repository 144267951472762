import { ProgressIndicator } from "../../utils/ProgressIndicating";
import I18Next from "./../../Control/Vue/I18Next";
import { prompt } from "./../../Control/Vue/Notifications/Prompts";
import { StepType } from "./StepType";
import noop from "lodash-es/noop";

export abstract class Step extends ProgressIndicator {

	protected requestPromise: JQueryXHR | null = null;

	/**
	 * Contains wizard data from previous steps.
	 * Use fillInData method to pass data of the current step.
	 */
	protected wizardData: Record<string, unknown> = {};

	constructor(protected step: JQuery, public readonly Type: StepType) {
		super(step[0]);
	}

	/**
	 * Set wizard data for the current step.
	 */
	public setWizardData(data: Record<string, unknown>): void {
		this.wizardData = data;
	}

	/**
	 * Initializes step on wizard initialization.
	 */
	public initialize(): void {
		noop();
	}

	/**
	 * Contains actions that should be executed on the step load (e.g. focus some form control)
	 */
	public onStepLoad(): void {
		noop();
	}

	/**
	 * Adds step validation rules to wizard validation.
	 */
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public setUpValidation(_rules: any) {
		noop();
	}

	/**
	 * Fires before the step changes after data validation.
	 * Front-end validation should be done with jQuery Validation. See setUpValidation method.
	 * Can be used to prevent step changing by returning resolving returned promise as false.
	 */
	public onStepChanging(_newStepType: StepType): Promise<boolean> {
		return Promise.resolve(true);
	}

	/**
	 * Send an event to Google Analytics about the completed step.
	 */
	public trackCompletedStepWithGA(): void {
		noop();
	}

	/**
	 * Adds step data to the passed object.
	 */
	public fillInData(_data: Record<string, unknown>): void {
		noop();
	}

	protected showErrorMessage(errorMessage: string): void {
		void prompt(errorMessage, [{ label: I18Next.tr("OkCancel_Ok"), value: undefined }]);
	}

	protected isRequestExecutionAllowed(): boolean {
		return this.requestPromise == null || this.requestPromise.state() !== "pending";
	}

	protected onRequestFailed(xhr: JQuery.jqXHR, error: string, _exception: string): void {
		// eslint-disable-next-line no-console
		console.log(xhr);
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
