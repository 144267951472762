import { HandlerType } from "../base/HandlerType";
import { ConfigureChild } from "../step-wizard/registration/ConfigureChild";

export class BootstrapSlider implements HandlerType {
	/**
	 * Data key which represents whether a selection has been deliberately made by the user.
	 *
	 * Unfortunately, the bootstrap slider doesn't support undefined default value.
	 * When there is no value defined on the slider initialization, number 5 is used by default,
	 * see here https://github.com/seiyria/bootstrap-slider#options.
	 */
	private static readonly VALUE_IS_SELECTED: string = "is-selected";

	private handle?: JQuery;
	private ticksLabels?: string[];
	private min: number | undefined;

	constructor(private bootstrapSlider: JQuery) {
		const isInitialized = bootstrapSlider.find(".slider").length > 0;
		if (!isInitialized && this.documentIsReadyToInitializeSlider()) {
			jQuery(document).ready(this.initialize.bind(this));
		}
	}

	public GetValue(): string | undefined {
		if (this.bootstrapSlider.data(BootstrapSlider.VALUE_IS_SELECTED)) {
			return this.bootstrapSlider.find("input").val() as string;
		}

		return undefined;
	}

	/**
	 * Ensures that the slider is initialized only once when the document is ready.
	 * When a slider is located inside a StepWizard control the slider element script runs:
	 * 1. when the slider element is added to the document
	 * 2. when the slider element is transferred by StepWizard from its original
	 * location to the specific step content element.
	 * To avoid double slider initialization we check whether the StepWizard exists and if so
	 * whether it has been already added to the document.
	 */
	private documentIsReadyToInitializeSlider() {
		const stepWizard = jQuery(".mia-StepWizard");
		return stepWizard.length === 0 || ConfigureChild.IsLoaded;
	}

	private initialize() {
		this.min = this.bootstrapSlider.data("min") as number;
		const max = this.bootstrapSlider.data("max") as number;
		const defaultValue = this.bootstrapSlider.data("default-value") as number | undefined;
		this.ticksLabels = this.bootstrapSlider.data("ticks-labels") as string[];

		this.bootstrapSlider.find("input").bootstrapSlider({
			handle: "square",
			max,
			min: this.min,
			range: false,
			selection: "none",
			ticks_labels: this.ticksLabels,
			tooltip: "hide",
			value: defaultValue,
		});

		this.handle = this.bootstrapSlider.find(".slider-handle.min-slider-handle");

		if (defaultValue) {
			this.makeSelection(defaultValue);
		} else {
			this.handle.addClass("hidden");
			this.bootstrapSlider.data(BootstrapSlider.VALUE_IS_SELECTED, false);
			this.bootstrapSlider.find("input").on("slideStart", this.onSlideStart.bind(this));
		}

		this.bootstrapSlider.find("input").on("change", this.onChange.bind(this));
	}

	private onSlideStart(event: any) {
		const value = event.value as number;
		if (value && !this.bootstrapSlider.data(BootstrapSlider.VALUE_IS_SELECTED)) {
			this.bootstrapSlider.find("input").off("slideStart");

			this.makeSelection(event.value);
		}
	}

	private onChange(event: any) {
		if (!this.bootstrapSlider.data(BootstrapSlider.VALUE_IS_SELECTED)) {
			this.makeSelection(event.value.newValue);
		}

		this.updateHandleTickLabel(event.value.newValue);
	}

	private makeSelection(value: number) {
		this.updateHandleTickLabel(value);
		this.handle?.removeClass("hidden");

		this.bootstrapSlider.data(BootstrapSlider.VALUE_IS_SELECTED, true);
	}

	private updateHandleTickLabel(value: number) {
		const newTickLabel = this.getTickLabel(value);
		this.handle?.text(newTickLabel);
	}

	private getTickLabel(index: number): string {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return this.ticksLabels![index - (this.min as number)];
	}
}
