import Vue, { VNode, VNodeDirective, VueConstructor } from "vue";
import noop from "lodash-es/noop";

/**
 * Setup the v-promise-link directive.
 * This directive works the same as v-promise-btn but you do not need to setup a @click handler.
 * Instead a @click handler is added automatically that never resolves, i.e., the spinner shows forever.
 *
 * @param options ignored, use vue-promise-button options instead
 */
function install(_vue: VueConstructor, _options: Record<string, unknown>): void {
	const promiseButton = Vue.directive("promise-btn");
	const directive = {
		...promiseButton,
		init(el: Element, binding: VNodeDirective, vnode: VNode) {
			if (vnode.data == null) {
				vnode.data = {};
			}
			if (vnode.data.on == null) {
				vnode.data.on = {};
			}
			if (vnode.data.on.click) {
				throw new Error("Make your @click handler return a promise and use v-promise-btn instead.");
			}
			// Return a promise that never resolves in the click handler so that the spinner
			// spins until the next page has loaded.
			// We might want to improve this eventually so that this actually reacts to navigation events, e.g.,
			// aborting the page load, stops the spinner, going to an anchor does not trigger the spinner, …
			vnode.data.on.click = () => new Promise(noop);
			// This is a bit of a hack that uses knowledge of how v-promise-btn works internally.
			// It is not clear from the Vue.js API if there is a clean way of really adding a @event handler
			// to the Vue instance (not to the DOM element.)
			(vnode.data.on.click as any)._withTask = vnode.data.on.click;
			(promiseButton as any).init(el, binding, vnode);
		},
	};
	Vue.directive("promise-link", directive);
}

export default install;
