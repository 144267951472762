





















import { Component, Vue, Prop } from "vue-property-decorator";
import Lightbox from "@/Control/Vue/Lesson/Lightbox.vue";
import VimeoPlayer from "@/Control/Vue/VimeoPlayer.vue";

@Component({
	components: {
		Lightbox,
		VimeoPlayer
	}
})
export default class CoverVideoPanel extends Vue {
	@Prop({ required: false, type: String, default: null }) private videoUrl!: string | null;

	private vimeoPlayer!: VimeoPlayer;
	private isVideoOpen = false;

	mounted() : void {
		this.vimeoPlayer = this.$refs.vimeoPlayer as VimeoPlayer;
	}

	private close() : void {
		this.isVideoOpen = false;
		void this.vimeoPlayer.pause();
	}

	private open() : void {
		this.isVideoOpen = true;
		void this.vimeoPlayer.setVideoProgress(0);
		void this.vimeoPlayer.play();
	}
}
