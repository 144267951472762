import { prompt } from "./../../../Control/Vue/Notifications/Prompts";
import { CheckboxPanel } from "./CheckboxPanel";

export class AsyncCheckboxPanel extends CheckboxPanel {
	private currentAJAXRequest?: JQuery.jqXHR;

	constructor(panel: JQuery) {
		super(panel);
	}

	protected OnCheckboxChecked(): void {
		super.OnCheckboxChecked();
		this.updateSetting(true);
	}

	protected OnCheckboxUnhecked(): void {
		super.OnCheckboxUnhecked();
		this.updateSetting(false);
	}

	private updateSetting(value: boolean) {
		if (this.currentAJAXRequest != null) {
			this.currentAJAXRequest.abort();
			this.currentAJAXRequest = undefined;
		}

		const url = this.panel.data("url");

		this.currentAJAXRequest = jQuery.post(url, { value })
			.done(this.onUpdateSettingDone.bind(this))
			.fail(this.showErrorDialog.bind(this));
	}

	private onUpdateSettingDone(data: any) {
		this.currentAJAXRequest = undefined;
		if (data && !data.success) {
			this.showErrorDialog();
		}
	}

	private showErrorDialog() {
		this.currentAJAXRequest = undefined;
		const errorMessage = this.panel.data("error");
		void prompt(errorMessage);
	}
}
