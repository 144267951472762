var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "mia-ConditionalReplacement-animation", mode: "out-in" } },
    [
      _c(
        "div",
        { key: _vm.condition || !_vm.applyTransition },
        [
          _vm.condition
            ? _vm._t("default")
            : _vm._t("replacement", [
                _c(
                  "div",
                  { staticClass: "alert alert-info", attrs: { role: "alert" } },
                  [
                    _vm._v(
                      "\n\t\t\t\t" + _vm._s(_vm.messageOtherwise) + "\n\t\t\t"
                    )
                  ]
                )
              ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }