import { HandlerType } from "../base/HandlerType";

export class ToggleableControl implements HandlerType {
	private target: JQuery;
	private showButton: JQuery;
	private hideButton: JQuery;

	constructor(private container: JQuery) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		this.target = this.container.find(`#${this.container.data("target")}`);
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		this.showButton = jQuery(`#${this.container.data("show-button")}`);
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		this.hideButton = jQuery(`#${this.container.data("hide-button")}`);

		if (this.hideButton.length !== 0) {
			this.showButton.click(this.toggleShow.bind(this));
			this.hideButton.click(this.toggleHide.bind(this));
		} else {
			this.showButton.click(this.toggle.bind(this));
		}
	}

	private toggleShow(event: JQuery.Event) {
		event.preventDefault();

		this.target.slideDown();
		this.showButton.hide();
		this.hideButton.show();
	}

	private toggleHide(event: JQuery.Event) {
		event.preventDefault();

		this.target.slideUp();
		this.showButton.show();
		this.hideButton.hide();
	}

	private toggle(event: JQuery.Event) {
		event.preventDefault();

		this.target.slideToggle();
	}
}
