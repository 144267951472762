



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ConditionalReplacement extends Vue {
	@Prop({type: Boolean, required: true}) protected condition!: boolean;
	@Prop({type: String, required: true}) protected messageOtherwise!: string;
	@Prop({type: Boolean, required: false, default: true}) protected applyTransition!: boolean;
}
