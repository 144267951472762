import { HandlerType } from "./base/HandlerType";

export class AdminNavigationBar implements HandlerType {
	private readonly searchBox: JQuery;
	private readonly tabs: JQuery;

	constructor(adminBar: JQuery) {
		this.searchBox = adminBar.find(".mia-searchBox");
		this.tabs = adminBar.find(".mia-tabList > li");

		this.searchBox.focus();
		this.searchBox.keyup(this.onSearchBoxKeyUp.bind(this));
		this.searchBox.keypress(this.onSearchBoxKeyPress.bind(this));
	}

	private onSearchBoxKeyUp() {
		let searchText = this.searchBox.val() as string;
		searchText = searchText.trim().toLowerCase();

		this.tabs.each((index: number) => {
			const tab = jQuery(this.tabs[index]);
			const tabText = tab.find("a").text().toLowerCase();

			if (tabText.indexOf(searchText) > -1) {
				tab.removeClass("hidden");
			} else {
				tab.addClass("hidden");
			}
		});
	}

	private onSearchBoxKeyPress(event: JQuery.Event) {
		if (event.keyCode === 13) {
			const firstTab = this.tabs.filter(":visible").first();
			if (firstTab.length) {
				const firstTabHref = firstTab.find("a").attr("href");
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				window.location.href = firstTabHref!;
			}
		}
	}
}
