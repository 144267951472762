import { HandlerType } from "../base/HandlerType";

export class ProgressBar implements HandlerType {
	constructor(container: JQuery) {
		const progressBar = container.find(".progress-bar");

		const valueNow = Number(progressBar.attr("aria-valuenow"));
		const valueMin = Number(progressBar.attr("aria-valuemin"));
		const valueMax = Number(progressBar.attr("aria-valuemax"));

		const percentage = Math.round(100 * (valueNow - valueMin) / (valueMax - valueMin));
		progressBar.width(`${percentage}%`);
	}
}
