import I18Next from "../Control/Vue/I18Next";
import { HandlerType } from "../controls/base/HandlerType";
import { Async } from "../utils/Async";
import { prompt } from "./../Control/Vue/Notifications/Prompts";

export class ChildCredentials implements HandlerType {
	private readonly submitButton: JQuery;
	private readonly passwordInput: JQuery;
	private requestPromise?: JQueryXHR;

	constructor(private form: JQuery) {
		this.passwordInput = this.form.find("#child-credentials-password");

		this.submitButton = this.form.find(".mia-submitButton");
		this.submitButton.click(this.onSubmitButtonClick.bind(this));
	}

	private onSubmitButtonClick(event: JQuery.Event) {
		event.preventDefault();

		const submitURL = this.submitButton.data("url");
		const requestData = {
			firstName: this.form.find("#child-credentials-fname").val(),
			lastName: this.form.find("#child-credentials-lname").val(),
			password: this.passwordInput.val(),
		};

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (Async.IsRequestExecutionAllowed(this.requestPromise!)) {
			this.submitButton.button("loading");
			this.requestPromise = jQuery.post(submitURL, requestData)
				.done(this.onSubmitDone.bind(this))
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(Async.OnRequestFailed)
				.promise();
		}
	}

	private onSubmitDone(data: any) {
		this.submitButton.button("reset");
		if (data.success) {
			this.passwordInput.val("");
			void prompt(data.message, [{ label: I18Next.tr("OkCancel_Ok"), value: undefined }]);
		} else {
			if (data.errorMessage) {
				void prompt(data.errorMessage);
			}
		}
	}
}
