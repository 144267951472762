import { NicknameValidator } from "@/utils/NicknameValidator";
import { RequestHandler } from "../base/RequestHandler";
import { prompt } from "./../../Control/Vue/Notifications/Prompts";

export class Name extends RequestHandler {
	private readonly closeBtn: JQuery;
	private readonly submitBtn: JQuery;

	constructor(element: JQuery) {
		super(element);
		this.submitBtn = this.element.find(".mia-submit");
		this.submitBtn.click(this.onSubmitClick.bind(this));

		this.closeBtn = this.element.find(".close");
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onFail(res: any) {
		this.closeBtn.click();
		super.onFail(res);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onDone(res: any) {
		this.closeBtn.click();
		super.onDone(res);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onSuccess(_res: any) {
		this.closeBtn.click();
		location.reload(true);
	}

	private onSubmitClick(event: JQuery.Event) {
		event.preventDefault();
		const writtenNickname = this.element.find("input.mia-nickname").val() ?? "";

		if (!NicknameValidator.isNicknameValid(writtenNickname.toString())){
			void prompt(this.element.data("nickname-invalid"));
			return;
		}

		this.setRequest(jQuery.post(this.submitBtn.data("url"),
			{nickname: writtenNickname}).done((response) => {
				if (response.msg) {
					void prompt(response.msg);
				}
			}));
	}
}
