import { Async } from "../../utils/Async";
import { HandlerType } from "../base/HandlerType";

export class ChallengeListItem implements HandlerType {
	private requestPromise: JQueryXHR | null = null;

	constructor(private challengeListItem: JQuery) {
		const nextChallenge = challengeListItem.find(".mia-nextChallenge");
		nextChallenge.click(this.onNextChallengeLinkClick.bind(this));

		const hideChallenge = challengeListItem.find(".mia-hideChallenge");
		hideChallenge.click(this.onHideChallengeLinkClick.bind(this));
	}

	private onNextChallengeLinkClick(event: JQuery.ClickEvent) {
		event.preventDefault();

		const link = jQuery(event.target);
		link.trigger("loadNextChallenge");
	}

	private onHideChallengeLinkClick(event: JQuery.ClickEvent) {
		event.preventDefault();

		if (Async.IsRequestExecutionAllowed(this.requestPromise)) {
			const url = jQuery(event.target).data("url");
			this.requestPromise = jQuery.post(url)
				.done(this.onHideChallengeSucceeded.bind(this))
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(Async.OnRequestFailed)
				.promise();
		}
	}

	private onHideChallengeSucceeded(_data: any) {
		this.challengeListItem.remove();
	}
}
