var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      attrs: { id: "dateSelectorInput", type: "hidden" },
      domProps: { value: _vm.date }
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c("v-date-picker", {
          attrs: {
            mode: "single",
            "is-inline": "",
            "min-date": _vm.minDate ? new Date(_vm.minDate) : null,
            "max-date": _vm.maxDate ? new Date(_vm.maxDate) : null
          },
          model: {
            value: _vm.date,
            callback: function($$v) {
              _vm.date = $$v
            },
            expression: "date"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }