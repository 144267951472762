import { AntiSpamCountryLaw } from "../../../utils/AntiSpamCountryLaw";
import { BootstrapSlider } from "../../form/BootstrapSlider";
import { StepType } from "../StepType";
import I18Next from "./../../../Control/Vue/I18Next";
import { FeatureLevelSelect } from "./../../form/FeatureLevelSelect";
import { RegisterStep } from "./RegisterStep";

export class ConfigureChild extends RegisterStep {
	public static IsLoaded = false;

	private bootstrapSlider?: BootstrapSlider;

	constructor(step: JQuery) {
		super(step, StepType.ConfigureChild);
	}

	public onStepLoad(): void {
		ConfigureChild.IsLoaded = true;

		const bootstrapSliderElement = this.step.find(".mia-BootstrapSlider");
		this.bootstrapSlider = new BootstrapSlider(bootstrapSliderElement);

		// eslint-disable-next-line no-new
		new FeatureLevelSelect(this.step.find("#featureLevel"));
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public async setUpValidation(rules: any) {
		jQuery.validator.addMethod("requiredBootstrapSlider", () => {
			return this.bootstrapSlider?.GetValue() !== undefined;
		}, await I18Next.tr("Steps_ConfigureChild_GradeSelection_ErrorMessage"));

		rules.grade = {
			requiredBootstrapSlider: true,
		};
	}

	public trackCompletedStepWithGA(): void {
		if (typeof gtag === 'function') {
			gtag("event", "completed ConfigureChild step", {
				event_category: "register",
			});
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public fillInData(data: any): void {
		data.featureLevel = this.step.find("#featureLevel").val();

		data.grade = this.bootstrapSlider?.GetValue();

		data.acceptChildReports = AntiSpamCountryLaw.GetChildReportValue();
	}
}
