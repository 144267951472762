import axios from "axios";
import { errorPrompt, prompt } from "./../Control/Vue/Notifications/Prompts";
import { HandlerType } from "./base/HandlerType";

export class ChangeGameVideoPriority implements HandlerType {
	private readonly savePriorityBtn: JQuery;
	private readonly priorityInput: JQuery;
	private readonly savePriorityLink: string;

	constructor(container: JQuery) {
		this.savePriorityBtn = container.find(".mia-savePriorityBtn");
		this.priorityInput = container.find(".mia-priorityInput");
		this.savePriorityLink = container.data("url");
		this.savePriorityBtn.on("click", this.onButtonClick.bind(this));
	}

	private onButtonClick(event: JQuery.Event) {
		event.preventDefault();
		const newPriority = Number(this.priorityInput.val());
		void axios.get(this.savePriorityLink.replace("PLH_priority", newPriority.toString())).then((response) => {
			if (response && response.data) {
				response.data.successful ? void prompt(response.data.message) : void errorPrompt(response.data.message);
			}
		});
	}
}
