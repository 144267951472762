import { VNode } from 'vue';
import FundsModule, {moduleName} from "@/Control/Vue/Vuex/FundsModule";
import SignalRConnection from "@/utils/SignalRConnection";
import { Component, Prop, Vue } from "vue-property-decorator";
import { useModule } from "vuex-simple";
/**
 * Connect to funds hub which updates FundsModule with
 * the actual amount of funds for a child	
 */
@Component
export default class FundsHubConnection extends Vue {
	@Prop({ required: true, type: Boolean }) private feedbackSoundOn!: boolean;

	protected created(): void {
		// Make PlayFeedbackSound available in js code
		(window as any).PlayFeedbackSound = this.feedbackSoundOn;
		this.connectToFundsHub();
	}


	private connectToFundsHub() {
		void SignalRConnection.connect("/fundsHub", (connection) => {
			connection.on("UpdateFunds", (fundsUpdate) => {
				if(this.fundsAmount 
					&& this.fundsAmount < fundsUpdate.Balance 
					&& fundsUpdate.PlaySound
					&& (window as any).PlayFeedbackSound) {
					this.playIncomeSound();
				}
				useModule<FundsModule>(this.$store, [moduleName])?.set(fundsUpdate.Balance);
			})
		});
	}

	private playIncomeSound() {
		const moneySound = window.soundManager.createSound({id: 'money', url: '/snd/money.mp3'});
		moneySound.play();
	}

	private get fundsAmount() {
		return useModule<FundsModule>(this.$store, [moduleName])?.amount;
	}

	protected render(): VNode[] | undefined {
		return undefined;
	}
}
