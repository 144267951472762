var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mia-RewardAndSocialFeatures" }, [
    _c("div", { staticClass: "container" }, [
      _c("h1", {
        directives: [
          {
            name: "tr",
            rawName: "v-tr",
            value: "landingpage_rewardandsocial_header",
            expression: "'landingpage_rewardandsocial_header'"
          }
        ],
        staticClass: "mia-rewardAndSocialFeaturesHeader mia-Heading"
      }),
      _vm._v(" "),
      _c("div", { staticClass: "mia-tilesContainer" }, [
        _c(
          "div",
          { staticClass: "mia-FeatureList" },
          [
            _c("feature", { attrs: { "feature-key": "earnRewards" } }),
            _vm._v(" "),
            _c("feature", { attrs: { "feature-key": "newspaper" } }),
            _vm._v(" "),
            _c("feature", { attrs: { "feature-key": "shop" } }),
            _vm._v(" "),
            _c("feature", { attrs: { "feature-key": "community" } })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }