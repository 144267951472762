
















































import { Component, Watch } from "vue-property-decorator";
import AsyncComputed from "../../AsyncComputed";
import Conditional from "../../ConditionalReplacement.vue";
import I18Next from "../../I18Next";
import ListItemLoader from "./ListItemLoader.vue";
import VueListBase from "./VueListBase";
import { IBootstrapTableOptions } from "./VueListOptions";

@Component({
	components: { Conditional, ListItemLoader },
})
export default class BootstrapTableList<S, T> extends VueListBase<S, T> {
	/**
	 * The offset of visibleItems and items, i.e., visibleItems[i] == items[i + offset].
	 */
	protected offset = 0;

	/**
	 * Jump to an offset (zero-based), i.e., make sure that this offset is visible on the current page.
	 */
	public goto(offset: number): void {
		this.gotoPage(Math.floor(offset / this.pageSize));
		void this.preload(this.offset);
	}

	@Watch("isResetting", {immediate: true})
	protected onReset(isResetting: boolean): void {
		if (!isResetting) {
			this.goto(0);
		}
	}

	get visibleItems(): S[] {
		if (this.offset >= this.totalCount) {
			// If for some reason, the offset became invalid, e.g., because the data in the backend changed,
			// we go back to a reasonable offset.
			this.goto(this.pages - 1);
		}
		return this.items.slice(this.offset, this.offset + this.options.pageSize);
	}

	/**
	 * The current page, zero-based.
	 */
	get page(): number {
		return Math.floor(this.offset / this.options.pageSize);
	}

	/**
	 * The indices of the pages that are accessible through pagination, zero-based.
	 */
	get paginationPages(): number[] {
		const previousPages = 3;
		const followingPages = 3;
		const totalPages = previousPages + followingPages + 1;

		// We'll offer the range [a, b) to the user
		// The idea is to put the current page in the center of the pagination, that's what Google Search
		// does, so apparently that's a good idea.
		let a = this.page - previousPages;
		let b = a + totalPages;
		if (a < 0) {
			a = 0;
			b = a + totalPages;
		}
		if (b >= this.pages) {
			b = this.pages;
			a = Math.max(b - totalPages, 0);
		}

		return Array.from({length: b - a}, (x, i) => i + a);
	}

	get hasPreviousPage(): boolean {
		return this.page !== 0;
	}

	get hasNextPage(): boolean {
		return this.totalCount > this.visibleItems.length + this.offset;
	}

	get pages(): number {
		return Math.ceil(this.totalCount / this.options.pageSize);
	}

	/**
	 * Jump to a page (zero-based.)
	 */
	protected gotoPage(page: number): void {
		this.offset = page * this.options.pageSize;
		if (this.offset < 0) {
			this.offset = 0;
		}
		if (this.offset >= this.totalCount) {
			this.offset = (this.pages - 1) * this.options.pageSize;
		}
		void this.preload(this.offset);
	}

	get columnKeys(): string[] {
		return ((this as BootstrapTableList<S, T>).options.backendOptions as IBootstrapTableOptions).columnKeys;
	}

	@AsyncComputed({default() {
		return (this as BootstrapTableList<S, T>).columnKeys;
	}})
	protected columnHeaders(): Promise<string[]> {
		return Promise.all(this.columnKeys.map((key) => I18Next.tr(key)));
	}
}
