
import { VNode } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { registerModule } from "vuex-simple";
import FundsModule, { moduleName } from "./FundsModule";
/**
 * 	Registers a Vuex module that stores the information about funds for a child
 */
@Component
export default class FundsModuleRegistration extends Vue {
	@Prop({required: true, type: Number}) protected amount!: number;

	protected created(): void {
		this.registerFundsModule();
	}

	private registerFundsModule() {
		if (!this.$store.hasModule(moduleName)) {
			registerModule(this.$store, [moduleName], new FundsModule(this.amount));
		}
	}

	protected render(): VNode[] | undefined {
		return undefined;
	}
}
