var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mia-vimeoPlayer" }, [
    _c("iframe", {
      ref: "vimeoPlayer",
      attrs: {
        src: _vm.videoSrc,
        allowfullscreen: "allowfullscreen",
        "data-ready": "true",
        allow: "autoplay; encrypted-media"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }