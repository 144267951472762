

















import { Component, Prop, Vue } from "vue-property-decorator";

import { MiaPlaza } from "../../../Reinforced.Typings";
import { ScreenSize } from "../../../utils/ScreenSize";
import ResponsiveImage from "@/Control/Vue/ResponsiveImage";

@Component({
	components: {
		ResponsiveImage,
	},
})
export default class MembershipDescription extends Vue {
	@Prop({required: true}) protected satisfactionGuaranteedIcon!: MiaPlaza.Navigation.JSON.Converters.ILazySizes;

	private get isDesktop() {
		return ScreenSize.isDesktop();
	}
}
