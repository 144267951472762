/**
 * The configuration object for the 'micromark' Markdown parser.
 *
 * It has two sections: 'disable' and 'enable'. 
 * These sections are used to explicitly turn off or on certain features of Markdown syntax 
 * during the parsing process.
 *
 * - 'disable': Contains features that should be explicitly turned off during parsing.
 * - 'enable': Contains features that should be explicitly turned on during parsing.
 *
 * By default, all Markdown syntax features supported by 'micromark' are enabled. If a feature 
 * is not listed in either the 'disable' or 'enable' arrays, it remains enabled.
 *
 * When new features are added to 'micromark', if they are not listed in either 'disable' or 'enable' 
 * in this configuration, they will be enabled by default. To disable these features, 
 * they should be added to the 'disable' array in this configuration object.
 */
export const MarkdownExtentions = {
	disable: {
		null: [	
			'autolink', // Automatic links, such as URLs or email addresses enclosed in angle brackets (< and >).
			'blankLine', // Blank lines separating block-level elements.
			'blockQuote', // Blockquotes, which are created by prefixing a line with >.
			'characterReference', // HTML character references, like &amp; for &.
			'characterEscape', // Backslash escapes, which prevent Markdown characters from being interpreted as syntax.
			'codeFenced', // Fenced code blocks, which are created by surrounding a block of text with triple backticks (```) or triple tildes (~~~).
			'codeIndented', // Indented code blocks, which are created by indenting lines with four spaces or one tab.
			'codeText', // Inline code spans, which are created by enclosing text in single backticks (`).
			'content', // Generic content construct, which might be related to text and other inline elements.
			'definition', // Link and image reference definitions.
			'hardBreakEscape', // Hard line breaks created by ending a line with two or more spaces and a line ending character.
			'headingAtx', // ATX-style headings, which are created by prefixing a line with one to six hash symbols (#).
			'htmlFlow', // Block-level HTML elements, or "flow content," like <div>, <table>, and <blockquote>.
			'htmlText', // Inline HTML elements, or "text content," like <span>, <strong>, and <em>.
			'labelEnd', // The end part of a reference label for links and images.
			'labelStartImage', // The start of an image reference label.
			'labelStartLink', // The start of a link reference label.
			'list', // Lists, both ordered and unordered, created by prefixing lines with numbers and periods or bullets (*, -, +).
			'setextUnderline', // Setext-style headings, which are created by underlining text with equal signs (=) or hyphens (-).
			'thematicBreak', // Thematic breaks, or horizontal rules, created by typing three or more hyphens (---), asterisks (***), or underscores (___).
		],
	},
	enable: {
		null: [
			'attention', // Inline text marked with emphasis or strong emphasis (italics and bold).
			'lineEnding', // Line ending characters, such as newline (\n) and carriage return (\r).
		],
	},
};
