var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("buffered-conversion", {
    key: _vm.from,
    attrs: { from: _vm.from, adapter: _vm.adapter },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var buffered = ref.buffered
          return [
            _c(
              _vm.effectiveComponent(buffered),
              _vm._b(
                {
                  tag: "component",
                  attrs: { index: _vm.index, from: buffered }
                },
                "component",
                _vm.itemProps,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }