import axios from "axios";
import Vue from "vue";
import { Action, Getter, Mutation, State } from "vuex-simple";
import { MiaPlaza } from "../../../Reinforced.Typings";
import IModule from "./IModule";
import QueueModule from "./QueueModule";

export type DataProvider<T> = (offset: number, limit: number) => Promise<MiaPlaza.Control.Vue.Vuex.Shared.IRangeWithOffset<T> | T[]>;

type QueueKey = {startOffset: number, endOffset: number};

export default class ListModule<T> extends QueueModule<QueueKey> implements IModule<number, T> {
	// The offset in the backing multitude that corresponds to the
	// first item that is not in data yet, i.e., the offset of the
	// item at data.length.
	// See IMultitude.GetRange() for details.
	protected unfilteredOffset = 0;

	@State()
	protected dataProvider: DataProvider<T>;

	@State()
	protected data: T[] = [];

	@State()
	protected dataTotalCountEstimate = 0;

	constructor(dataProvider: DataProvider<T>, initialItems: T[], totalCountEstimate: number, unfilteredOffset: number) {
		super();
		this.dataProvider = dataProvider;
		this.data = initialItems;
		this.dataTotalCountEstimate = totalCountEstimate;
		this.unfilteredOffset = unfilteredOffset;
	}

	public static makeDataProvider<T>(dataUrl: string | T[]) : DataProvider<T> {
		return async (offset: number, limit: number) => {
			if (typeof dataUrl === "string") {
				const response = await axios.get<MiaPlaza.Control.Vue.Vuex.Shared.IRangeWithOffset<T>>(dataUrl
						.replace(MiaPlaza.Control.Vue.Vuex.Shared.AjaxHelpers.PLACEHOLDER_OFFSET, offset.toString())
						.replace(MiaPlaza.Control.Vue.Vuex.Shared.AjaxHelpers.PLACEHOLDER_LIMIT, limit.toString()));
				return response.data;
			} else {
				return Promise.resolve({
					Items: dataUrl.slice(offset, offset + limit),
					UnfilteredOffset: offset,
					UnfilteredLimit: limit,
					TotalCountEstimate: dataUrl.length,
				});
			}
		}
	}

	protected async doLoad(keys: QueueKey[], isValid: () => boolean): Promise<void> {
		const startOffset = this.data.length;
		const endOffset = Math.max(...keys.map((key) => key.endOffset));

		if (startOffset >= endOffset) {
			return;
		}

		if (this.dataTotalCountEstimate <= this.data.length) {
			// By convention with the server:
			// When the number of items match the count estimate, then there is nothing to load from the server anymore.
			return;
		}

		const offset = this.unfilteredOffset;
		const limit = endOffset - startOffset;

		let response = await this.dataProvider(offset, limit);

		if (Array.isArray(response)) {
			response = ListModule.wrapArrayResponse(response, offset, limit);
		}

		if (!isValid()) {
			// If reset has been called since we started this request, we ignore this obsolete data.
			return;
		}

		this.insert(response.Items);
		this.estimateLength(response.TotalCountEstimate);
		this.unfilteredOffset = response.UnfilteredOffset + response.UnfilteredLimit;
	}

	private static wrapArrayResponse<T>(response: T[], offset: number, limit: number) {
		const exhausted = response.length < limit;
		return {
			Items: response,
			TotalCountEstimate: offset + response.length + (exhausted ? 0 : 1),
			UnfilteredOffset: offset,
			UnfilteredLimit: limit,
		};
	}

	// Return a promise that resolves once all keys have been loaded.
	@Action()
	public async load(keys: number[]): Promise<void> {
		if (keys == null || keys.length === 0) {
			return;
		}
		const startOffset = Math.min(...keys);
		const endOffset = Math.max(...keys) + 1;
		this.enqueue({startOffset, endOffset});
		await this.dequeue();
	}

	@Action()
	public async reset(dataProvider?: string | T[] | DataProvider<T>): Promise<void> {
		this.markInvalid();
		if (dataProvider != null) {
			if (typeof(dataProvider) === "string" || Array.isArray(dataProvider)) {
				dataProvider = ListModule.makeDataProvider<T>(dataProvider);
			}
			this.dataProvider = dataProvider;
		}
		this.unfilteredOffset = 0;
		this.doReset();
		await Promise.resolve();
	}

	@Mutation()
	protected insert(items: T[]): void {
		for (const item of items) {
			// see https://vuejs.org/v2/guide/list.html#Caveats for
			// why we cannot do: state.data[at] = item
			Vue.set(this.data, this.data.length, item);
		}
	}

	@Mutation()
	protected estimateLength(totalCountEstimate: number): void {
		this.dataTotalCountEstimate = totalCountEstimate;
	}

	@Mutation()
	protected doReset(): void {
		this.data = [];
		this.dataTotalCountEstimate = 1;
	}

	@Getter()
	public get isResetting(): boolean {
		return this.data.length === 0 && this.dataTotalCountEstimate === 1;
	}

	@Getter()
	public get items(): T[] {
		return this.data;
	}

	@Getter()
	public get totalCountEstimate(): number {
		return this.dataTotalCountEstimate;
	}
}
