export class Async {
	public static IsRequestExecutionAllowed(requestPromise: JQueryXHR | null): boolean {
		return requestPromise == null || requestPromise.state() !== "pending";
	}

	public static OnRequestFailed(xhr: JQuery.jqXHR, error: string): void {
		// eslint-disable-next-line no-console
		console.log(xhr);
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
