import Mailcheck from "mailcheck";

import I18Next from "../../Control/Vue/I18Next";

export class FormHelper {
	// Event handler(designed for input event but may be used also for different events)
	// which gives suggestions when user-entered second-level or first-level domains are incorrect
	// (check is performed on mailcheck inbuilt defaults of domains but may also be extended).
	// This function is designed to work with those type of forms, which support JQuery validation plugin, i.e. implement
	// <c>FormWithValidation</c>(e.g. stepped registration, parent and child email change page, etc.)
	// Suggestions are given when user has already entered second-level domain and error container is not filled
	// with more important messages which are generated by JQuery validation plugin.
	// Suggestion is span element with text like 'Did you mean ' and suggested email.
	// Suggested email is a link and when you click on it suggestion is being deleted and suggested email replaces email
	// with incorrect domains which is in appropriate email input
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public static async proposeEmailSuggestion(event: any): Promise<void> {
		const emailInput = jQuery(event.target);
		const errorContainer = FormHelper.getParentFormGroup(emailInput).find(".error-container");

		if (FormHelper.hasUserAlreadyEnteredSecondDomain(emailInput)) {
			const suggestionText: string = await I18Next.tr("mailcheck_suggestion");
			if (!errorContainer.text() || errorContainer.text().startsWith(suggestionText)) {
				Mailcheck.run({
					email: emailInput.val() as string,
					empty: () => {
						errorContainer.html("");
					},
					suggested: (mailCheckSuggestion: any) => {
						const suggestedMail = mailCheckSuggestion.full;

						const suggestion = jQuery("<span>").addClass("mia-mailSuggestion").text(suggestionText + " ");
						const suggestionLink = jQuery("<a>").attr("href", "#").text(suggestedMail);
						suggestionLink.on("click", (clickOnSuggestion) => {
							clickOnSuggestion.preventDefault();
							FormHelper.applySuggestion(emailInput, suggestedMail, errorContainer);
						});

						suggestion.append(suggestionLink);
						errorContainer.html("");
						errorContainer.append(suggestion);
					},
				});
			}
		}
	}

	public static getParentFormGroup(input: JQuery): JQuery {
		return input.parents(".form-group");
	}

	private static hasUserAlreadyEnteredSecondDomain(emailInput: JQuery) {
		let dotsAmount: RegExpMatchArray | null = null;
		const email = emailInput.val() as string;
		if (email) {
			const partAfterAt = email.substring(email.lastIndexOf("@"));
			dotsAmount = partAfterAt.match(/\./g);
		}

		return dotsAmount && dotsAmount.length > 0;
	}

	private static applySuggestion(emailInput: JQuery, suggestedMail: string, errorContainer: JQuery) {
		emailInput.val(suggestedMail);
		errorContainer.html("");
	}
}
