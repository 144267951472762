/*
 * Integrates TextResources through i18next into vuejs.
 *
 * You can use TRs in three different ways.
 * * use a `v-tr` attribute such as `<span v-tr="'My_Text_Resource'" />` (preferred),
 * * use `$tr` inside a Vue component or a template such as `const tr = this.$tr("key");`, i.e., inside reactive code.
 *	 This returns "…" on the first invocation. Once the resource is ready, a rerender happens and the real text is
 *	 returned by `$tr`.
 * * more low-level, call `I18Next.tr` to get a promise that resolves to the TextResource's value.
 *
 * Interpolation
 * -------------
 * The basics are explained at https://www.i18next.com/translation-function/interpolation#interpolation, however we
 * use `{this form}` for placeholders instead of `{{this form}}`.
 * To use interpolations:
 * * Do `<span v-tr="{key: 'My_Text_Resource', [an anchor]: 'replacement'}" />`,
 * * or use `$tr('My_Text_Resource', {[an anchor]: 'replacement})` from reactive code,
 * * or use the low-level `I18Next.tr('My_Text_Resource', {[an anchor]: 'replacement'})`.
 * It's safe to put arbitrary user provided content into interpolations as it gets escaped,
 * see https://www.i18next.com/translation-function/interpolation#unescape.
 * If you are really in for some trouble, a replacement of `{-trusted this form}` does not get escaped.
 * (The key has to start with `trusted` and there has to be the extra dash.) Note that you'll have to take the blame
 * for the resulting XSS attacks.
 *
 * Plurals, Grammar, …
 * -------------------
 * There are no automatic plurals; though some of this https://www.i18next.com/translation-function/plurals#plurals
 * might already work, we probably want to change to fluent format for this: https://github.com/i18next/i18next-fluent
 *
 * Glossary
 * --------
 * Some standard replacements are available as $t(glossary:whatever). They are configured in the Configuration tab
 * of the admin interface.
 *
 * HTML
 * ----
 * Some HTML is supported in TextResources. Please see the sanitation code below for details.
 * Note that Vue.js removes HTML from `{{ $tr(...) }}` because it always does
 * so with `{{ }}` interpolations.
 *
 * Cultures
 * --------
 * Note that TextResources exist for several "cultures", US (default) and C (shows raw TextResource [keys],)
 * call window.MiaCulture() to see the raw keys.
 *
 * Rant
 * ----
 * TextResources™ by miaplaza are a weird animal. We keep translation strings in the database
 * so that content developers can update them without a release. We also have a few tools to transfer text
 * resources between different portals (AI/CD/Miacademy.) See https://dev.miaplaza.com/miaplaza/website/issues/11272
 * for ideas on how to improve upon this.
 */

import Vue, { VNode } from "vue";
import { MiaPlaza } from "../../Reinforced.Typings";

import axios from "axios";
import DOMPurify from "dompurify";
import i18next from "i18next";
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import MultiloadAdapter from "i18next-multiload-backend-adapter";
import XHR from "i18next-xhr-backend";
import mapValues from "lodash-es/mapValues";
import { Store } from "vuex";
import { useModule } from "vuex-simple";
import Log from "../../utils/Log";
import I18NextModule from "./Vuex/I18NextModule";

export interface II18NextXHRFormat {
	[culture: string]: {
		[namespace: string]: {
			[key: string]: string,
		};
	};
}

// The event data provided by i18next's loaded event.
export interface II18NextLoad {
	[culture: string]: string[];
}

function sanitize(dirty: string): string {
	return DOMPurify.sanitize(dirty, {
		// We allow these attributes on all the below tags. We only
		// need them for <a> tags but they won't hurt on the other ones
		// either and there is no way to configure dompurify to only allow
		// certain combinations.
		ALLOWED_ATTR: [ "href", "name", "target", "rel" ],
		ALLOWED_TAGS: [ "h3", "h4", "h5", "h6", "blockquote", "p", "a",
			"ul", "ol", "nl", "li", "b", "i", "strong", "em", "strike",
			"abbr", "code", "hr", "br", "div", "pre", "#text" ],
	});
}

export { sanitize };

export default class I18Next {
	// Initialize i18next to talk to our server as the backend.
	// Ideally, we would do this in the Vue.use() call but it's hard to pass parameters
	// there, so we postpone this.
	public static async initialize(store: Store<any>): Promise<void> {
		if (!I18Next.uninitialized) {
			return;
		}

		const singleton = new I18Next(store);

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const module = useModule<I18NextModule>(store, [MiaPlaza.Control.Vue.I18Next.MODULE])!;
		// We do not yet depend reactively on the version/xhr as we apparently have to specify it upon initialization
		// of i18next. Therefore, changing the version in the module does not update texts on the site.
		const version = module.version;
		const xhr = module.xhr;

		await i18next
		.use(ChainedBackend)
		.use(I18Next.sanitizer)
		.init({
			backend: {
				backendOptions: [
					{
						// We do explicit versioning but just to be on the safe side we
						// also trash our caches after 7 days in case our versioning breaks.
						expirationTime: 7 * 24 * 60 * 60 * 1000,
						prefix: "i18next_",
						versions: {
							[MiaPlaza.Control.Vue.I18Next.US]: version,
						},
					}, {
						backend: XHR,
						backendOption: {
							// eslint-disable-next-line @typescript-eslint/unbound-method
							ajax: singleton.ajax,
							allowMultiloading: true,
							crossDomain: false,
							loadPath: (lngs: string|string[], namespaces: string|string[]) => {
								// i18next-xhr does not properly escape whitespace
								// (it joins all the keys with a '+' without escaping them first.)
								// Even worse, it actually uses its own interpolation logic to replace
								// {{lng}} and {{ns}} in the url so it also escapes things such as ' as &#39; which
								// is not such a brilliant idea in a URL.
								// This is https://github.com/i18next/i18next-xhr-backend/issues/316.
								return xhr.replace("{{lng}}", encodeURIComponent(JSON.stringify(lngs)))
									.replace("{{ns}}", encodeURIComponent(JSON.stringify(namespaces)));
							},
							// axios already does the parsing, so the only thing we do here is plug in the sanitizer,
							// so we can notify people of resources containing invalid HTML.
							parse: (data: II18NextXHRFormat): II18NextXHRFormat =>
								mapValues(data, (culture) =>
									mapValues(culture, (ns, key) =>
										mapValues(ns, (value) => {
											const sanitized =
												I18Next.sanitizer.process(value, undefined as never, undefined as never, undefined as never);
											if (sanitized !== value) {
												const message = `\`${key}\` had unsupported HTML. \`${value}\` was normalized into \`${sanitized}\`.`;
												void Log.error(message);
											}
											return sanitized;
							}))),
							withCredentials: false,
						},
					},
				],
				backends: [
					// We lookup strings in the browers local storage first…
					LocalStorageBackend,
					// …and then fallback to loading them from the server in bulk.
					MultiloadAdapter,
				],
			},
			fallbackLng: MiaPlaza.Control.Vue.I18Next.US,
			keySeparator: false,
			load: "currentOnly",
			// We do not curtail i18next's "nested" feature. However, we only recommend to use nesting with
			// the glossary namespace that is loaded initially by writing text resources such as
			// "Your $t(glossary:child) has completed an assessment."
			ns: [MiaPlaza.Control.Vue.I18Next.GLOSSARY],
			nsSeparator: ":",
			postProcess: ["html-sanitizer"],
		});

		I18Next.resolveReset(singleton);
	}

	// Called by Vue.use() to enable the v-tr directive.
	public static install(): void {
		Vue.directive("tr", {
			// eslint-disable-next-line @typescript-eslint/unbound-method
			bind: I18Next.bind,
			// eslint-disable-next-line @typescript-eslint/unbound-method
			update: I18Next.bind,
			// eslint-disable-next-line @typescript-eslint/unbound-method
			unbind: I18Next.unbind,
		});

		// eslint-disable-next-line @typescript-eslint/unbound-method
		Vue.prototype.$tr = I18Next.$tr;
	}

	// Return a promise that resolves to the value of the TextResource `key`.
	public static tr(key: string, interpolations?: Record<string, unknown>): Promise<string> {
		return I18Next.isInstance(I18Next.singleton)
			? I18Next.singleton.tr(key, interpolations || {})
			: I18Next.singleton.then((i18n) => i18n.tr(key, interpolations || {}));
	}

	protected static $tr(key: string, loading?: string): string;
	protected static $tr(key: string, interpolations: Record<string, unknown>, loading?: string): string;
	protected static $tr(key: string, interpolations?: string | Record<string, unknown>, loading?: string): string {
		if (typeof interpolations === "string") {
			loading = interpolations;
			interpolations = {};
		}
		loading = loading || "…";

		if (!I18Next.isInstance(I18Next.singleton)) {
			const reactive = Vue.observable({ ready: false });
			void I18Next.singleton.then(() => {
				// Render again, when the text resource system has initialized.
				reactive.ready = true;
			});
			reactive.ready;
			return loading;
		}

		return I18Next.singleton.$tr(key, interpolations || {}, loading);
	}

	// Helper method for the v-tr directive. Sets el.innerHTML to the interpolated text resource specified in the binding,
	// i.e., as the value of v-tr.
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected static bind(this: undefined, el: Element, binding: any, vnode: VNode): void {
		el.textContent = "…";

		const [key, interpolations] = (() => {
			if (typeof binding.value === "string") {
				return [binding.value, []];
			} else {
				const {key, ...rest} = binding.value;
				return [key, rest];
			}
		})();

		const setInnerHtml = (html: string) => {
			// Why is it save to set innerHTML to a TextResource value with replacements?
			// * We do not trust our content authors to produce valid HTML, and only allow a certain subset of HTML to go through
			//	 (see XHR loader.)
			// * Interpolations (formerly called Replacements) are automatically escaped by the i18next library - unless they are
			//	 marked specifically, see -trusted below.
			// * Finally, we apply the sanitizer again to the result of interpolation, so even if somebody managed to inject
			//	 something malicious into the interpolation, we take it out if it would not have been allowed in a TextResource
			//	 in the first place. E.g., we would take out a <script> block that somebody managed to inject but we would not
			//	 take out an <a> pointing to the wrong place.
			if (el.innerHTML !== html) {
				el.innerHTML = html;
			}
		};

		const value = I18Next.$tr(key, interpolations as Record<string, unknown>, MiaPlaza.Control.Vue.I18Next.I18NEXT_KEY);
		if (value !== MiaPlaza.Control.Vue.I18Next.I18NEXT_KEY) {
			setInnerHtml(value);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			void I18Next.tr(key, interpolations as Record<string, unknown>).then(() => vnode.context!.$forceUpdate());
		}
	}

	// Helper method for the v-tr directive. Resets el.innerHTML so Vue.js can
	// safely use this DOM Node elsewhere:
	// Vue.js does not keep track of the innerHTML that has been set by others
	// such as this directive assuming that the node is still empty.
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected static unbind(this: undefined, el: Element): void {
		el.innerHTML = "";
	}

	// The one I18Next instance, once initialize() has been called or a promise that resolves once I18Next is ready.
	// (Singleton is not a good pattern, but we do not want to go down the road of Dependency Injection with reactivity.)
	private static singleton: I18Next | Promise<I18Next>;
	private static resolveReset: (i18n: I18Next) => void = I18Next.reset();
	private static uninitialized = true;

	// A i18next plugin to make sure that no weird HTML is contained in our Text Resources
	private static readonly sanitizer: i18next.PostProcessorModule = {
		name: "html-sanitizer",
		// This sanitizer is only relevant for interpolations which might contain user-provided content.
		// Our text resources are already sanitized by the XHR loader which reports to our logs if there
		// has been illegal HTML.
		process: (value) => sanitize(value),
		type: "postProcessor",
	};

	private static reset() {
		let resolve: ((i18n: I18Next) => void);

		const previousResolve = I18Next.resolveReset;

		// Because of the above singleton pattern, we have to ressort to this really nasty Promise foo,
		// basically abusing promises as synchronization primitives.
		const wrappedResolve = (i18n: I18Next) => {
			resolve(i18n);
			if (previousResolve != null) {
				previousResolve(i18n);
			}
			I18Next.singleton = i18n;
		};
		I18Next.singleton = new Promise((res) => { resolve = res; });
		return wrappedResolve;
	}

	private static isInstance(i18n: I18Next | Promise<I18Next>): i18n is I18Next {
		return (i18n as I18Next).store !== undefined;
	}

	private store: Store<any>;

	private constructor(store: Store<any>) {
		this.store = store;
	}

	private get culture() {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return useModule<I18NextModule>(this.store, [MiaPlaza.Control.Vue.I18Next.MODULE])!.culture;
	}

	private async ajax(url: string, _options: never, callback: (data: string | null, {status}: {status: number}) => void) {
		let response: any;
		try {
			response = await axios.get(url);
		} catch (err) {
			// See https://github.com/i18next/i18next-xhr-backend/issues/315 for a discussion
			void Log.error(err);
			callback(null, {status: err.status || 500});
			return;
		}
		callback(response.data, { status: response.status });
	}

	private $tr(key: string, interpolations: Record<string, unknown>, loading: string): string {
		const reactive = Vue.observable({ value: this.fetch(key, interpolations, this.culture) });
		if (reactive.value === MiaPlaza.Control.Vue.I18Next.I18NEXT_KEY) {
			void this.tr(key, interpolations).then((value) => {
				if (value !== reactive.value) {
					reactive.value = value;
				}
			});
			return loading;
		} else {
			return reactive.value;
		}
	}

	private tr(key: string, interpolations: Record<string, unknown>): Promise<string> {
		const culture = this.culture;
		const value = this.fetch(key, interpolations, culture);
		return new Promise((resolve) => {
			if (value === MiaPlaza.Control.Vue.I18Next.I18NEXT_KEY) {
				// Load the text from the server. We do not load them one by one but bulk load
				// with the multiload adapter defined below.
				// We load each TextResource as a separate namespace into i18next and cache it in the local storage
				// at the client. We need the namespacing hack because i18next uses namespaces as the atomic unit
				// that can be loaded at a time.

				if (culture !== i18next.language) {
					// Change i18next language so we do not load namespaces for the wrong language
					const resolveReset = I18Next.reset();
					void i18next.changeLanguage(culture).then(() => resolveReset(this));
				}
				if (!I18Next.isInstance(I18Next.singleton)) {
					// I18Next is in the process of resetting. Postpone querying the server until it is done.
					void I18Next.singleton.then((i18n) => i18n.tr(key, interpolations).then(resolve));
					return;
				}
				void i18next.loadNamespaces([this.toNamespace(key)]).then(() => {
					const newValue = this.fetch(key, interpolations, culture);
					resolve(newValue);
					if (newValue === MiaPlaza.Control.Vue.I18Next.I18NEXT_KEY) {
						throw new Error(`Failed to load resource for \`${key}\` with interpolations ${JSON.stringify(interpolations)}.`);
					}
				});
			} else {
				return resolve(value);
			}
		});
	}

	private fetch(key: string, interpolations: Record<string, unknown>, lng: string): string {
		if (lng === MiaPlaza.Control.Vue.I18Next.C) {
			let prettyInterpolations = JSON.stringify(interpolations);
			if (prettyInterpolations === "{}") {
				prettyInterpolations = "none";
			}
			return `[${key}](interpolations: ${prettyInterpolations})`;
		}

		return i18next.t(MiaPlaza.Control.Vue.I18Next.I18NEXT_KEY, {
			...(interpolations || {}),
			fallbackLng: lng,
			// strangely, loading does not work anymore if we put this into the global init() options.
			interpolation: {
				prefix: "{",
				suffix: "}",
				unescapePrefix: "-(?=trusted)",
			},
			lng,
			ns: this.toNamespace(key),
		});
	}

	private toNamespace(key: string) {
		// Model.TextResources says that a key must not contain whitespace.
		return key.replace(/ /g, "_");
	}
}
