var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mia-AccessibilityInfoPanel" }, [
    _c("div", { staticClass: "mia-accessibilityInfoColumns" }, [
      _c(
        "div",
        { staticClass: "mia-accessibility-info-column" },
        [
          _c("h1", {
            directives: [
              {
                name: "tr",
                rawName: "v-tr",
                value: "accessibility_info_panel_title",
                expression: "'accessibility_info_panel_title'"
              }
            ],
            staticClass: "mia-Heading"
          }),
          _vm._v(" "),
          _c("h3", {
            directives: [
              {
                name: "tr",
                rawName: "v-tr",
                value: "accessibility_info_panel_subtitle",
                expression: "'accessibility_info_panel_subtitle'"
              }
            ]
          }),
          _vm._v(" "),
          _vm._l(_vm.infoPoints, function(infoPoint, index) {
            return _c(
              "div",
              { key: index, staticClass: "mia-accessibility-info-point" },
              [
                _c("responsive-image", {
                  staticClass: "mia-accessibility-icon",
                  attrs: { from: infoPoint.IconUrl }
                }),
                _vm._v(" "),
                _c("span", {
                  directives: [
                    {
                      name: "tr",
                      rawName: "v-tr",
                      value: infoPoint.MessageTextResource,
                      expression: "infoPoint.MessageTextResource"
                    }
                  ],
                  staticClass: "mia-accessibility-icon-comment"
                })
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mia-accessibility-picture-columm" },
        [_c("responsive-image", { attrs: { from: _vm.mainImg } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }