import { AntiSpamCountryLaw } from "../../../utils/AntiSpamCountryLaw";
import { FormHelper } from "../../form/FormHelper";
import { PasswordInputGroup } from "../../form/PasswordInputGroup";
import { StepType } from "../StepType";
import I18Next from "./../../../Control/Vue/I18Next";
import { RegisterStep } from "./RegisterStep";

/// mobile-detect is a UMD, we can not "import" it, see https://github.com/Microsoft/TypeScript/issues/10178
// eslint-disable-next-line no-var
declare var MobileDetect: any;

export class RegisterParent extends RegisterStep {
	private readonly parentEmail: JQuery;
	constructor(step: JQuery) {
		super(step, StepType.RegisterParent);

		this.parentEmail = this.step.find("#parentEmail");
		// eslint-disable-next-line @typescript-eslint/unbound-method, @typescript-eslint/no-misused-promises
		this.parentEmail.on("input", FormHelper.proposeEmailSuggestion);
	}

	public initialize(): void {
		const facebookSignInButton = jQuery(this.step).find(".mia-btn-facebook");
		facebookSignInButton.attr("type", "button");
		facebookSignInButton.click(this.onFacebookSignInButtonClick.bind(this));

		const logInButton = this.step.find(".mia-logInButton");
		logInButton.attr("type", "button");
		logInButton.click(this.onLogInButtonClick.bind(this));

		const countryDropDown = this.step.find("#parentCountry");
		countryDropDown.change(AntiSpamCountryLaw.OnCountryDropDownChange.bind(this));
	}

	public onStepLoad(): void {
		if (!new MobileDetect(window.navigator.userAgent).mobile()) {
			jQuery("#parentFirstName").focus();
		}
		// eslint-disable-next-line no-new
		new PasswordInputGroup(this.step.find(".mia-PasswordInputGroup"));
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public async setUpValidation(rules: any): Promise<void> {
		jQuery.validator.addMethod("startWithAlphabetical", (value, _element) => {
			return value[0].match(/[a-zA-Z]/);
		}, await I18Next.tr("lastname_should_start_with_alphabetical_error"));

		rules.parentLastName = {
			required: true,
			startWithAlphabetical: true,
		};
	}

	public trackCompletedStepWithGA(): void {
		if (typeof gtag === 'function') {
			gtag('event', 'completed RegisterParent step', {
				event_category: 'register'
			});
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public fillInData(data: any): void {
		const countryDropDown = this.step.find("#parentCountry");

		data.parentFirstName = this.step.find("#parentFirstName").val();
		data.parentLastName = this.step.find("#parentLastName").val();
		data.parentEmail = this.parentEmail.val();
		data.parentCountry = countryDropDown.val();
		data.parentPassword = this.step.find("#parentPassword").val();

		if (AntiSpamCountryLaw.IsAntiSpamCountrySelected.bind(countryDropDown)) {
			data.acceptNewsletters = AntiSpamCountryLaw.GetNewsletterValue();
			data.acceptChildReports = AntiSpamCountryLaw.GetChildReportValue();
		}
	}

	private onFacebookSignInButtonClick(event: JQuery.ClickEvent) {
		event.preventDefault();

		const facebookSignInButton = jQuery(event.target);
		if (typeof gtag === 'function') {
			gtag('event', 'RegisterParent step FacebookSignIn', {
				event_category: 'register',
				event_callback: () => {
					this.signInWithFacebook(facebookSignInButton);
				}
			});
		} else {
			this.signInWithFacebook(facebookSignInButton);
		}
	}

	private onLogInButtonClick(event: JQuery.ClickEvent) {
		event.preventDefault();

		const logInButton = jQuery(event.target);
		if (typeof gtag === 'function') {
			gtag("event", "RegisterParent step LogIn", {
				event_category: "register",
				event_callback: () => {
					this.logInWithExistingAccount(logInButton);
				},
			});
		} else {
			this.logInWithExistingAccount(logInButton);
		}
	}

	private signInWithFacebook(facebookSignInButton: JQuery<HTMLElement>) {
		const signInUrl = facebookSignInButton.data("url");
		this.storeChildDataInSessionAndRedirect(signInUrl);
	}

	private logInWithExistingAccount(logInButton: JQuery<HTMLElement>) {
		const email = jQuery(document).find("#parentEmail").val();
		const logInUrl = logInButton.data("url").replace("PLH_email", email);
		this.storeChildDataInSessionAndRedirect(logInUrl);
	}

	private storeChildDataInSessionAndRedirect(redirectUrl: string) {
		if (this.isRequestExecutionAllowed()) {
			const storeChildDataInSessionUrl = this.step.find(".mia-StepBody").data("store-in-session-url");

			this.requestPromise = jQuery.post(storeChildDataInSessionUrl, this.wizardData)
				.done((data) => {
					if (data.success) {
						window.location.href = redirectUrl;
					} else {
						this.showErrorMessage(data.errorMessage);
					}
				})
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(this.onRequestFailed)
				.promise();
		}
	}
}
