

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DateSelector extends Vue {
	@Prop({required: false, type: String}) protected initialDate!: string;
	@Prop({required: false, type: String}) protected minDate!: string;
	@Prop({required: false, type: String}) protected maxDate!: string;

	protected date = new Date(this.initialDate);
}
