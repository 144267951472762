import jQuery from "jquery";

export class SelectControlledControls {
	private readonly selector: HTMLSelectElement;
	private readonly controlsPerIndex: HTMLElement[];

	constructor(containerId: DOMElementIdentifier, constructionParameters: ConstructionParameters) {
		this.selector = document.getElementById(constructionParameters.selectorId) as HTMLSelectElement;

		this.controlsPerIndex = new Array<HTMLElement>(constructionParameters.controlIdsPerIndex.length);
		for (let i = 0; i < this.controlsPerIndex.length; ++i) {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			this.controlsPerIndex[i] = document.getElementById(constructionParameters.controlIdsPerIndex[i])!;
		}

		this.showOnlySelectedControl();
		jQuery(this.selector).on("change", () => this.showOnlySelectedControl());
	}

	private showOnlySelectedControl(): void {
		const selected = this.selector.selectedIndex;
		for (let i = 0; i < this.controlsPerIndex.length; ++i) {
			this.controlsPerIndex[i].hidden = i !== selected;
		}
	}
}
