var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "mia-Logo",
      class: { "mia-Logo--maintenance": _vm.maintenance },
      attrs: { href: "/", title: _vm.$tr("Login0") }
    },
    [
      _vm.logo
        ? _c("responsive-image", { attrs: { from: _vm.logo } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }