import { IAsyncComputedProperty } from "vue-async-computed";
import { createDecorator, VueDecorator } from "vue-class-component";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// A decorator which turns a method returning a promise into an asynchronously computed prop with vue-async-computed.
export default function AsyncComputed<T>(computedOptions?: Omit<IAsyncComputedProperty<T>, "get">): VueDecorator {
	return createDecorator((options, key) => {
		options.asyncComputed = options.asyncComputed || {};
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const method = options.methods![key];
		options.asyncComputed[key] = {
			get: method,
			...computedOptions,
		} as IAsyncComputedProperty<T>;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		delete options.methods![key];
	});
}
