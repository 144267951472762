import { MiaPlaza } from "../../Reinforced.Typings";
import { HandlerType } from "../base/HandlerType";

export class ChildProfile implements HandlerType {
	private readonly modal: JQuery;
	private readonly endFriendshipLink: JQuery;

	constructor(element: JQuery) {
		this.modal = element.find(".mia-endFriendshipModal");
		this.endFriendshipLink = jQuery(`.${MiaPlaza.Navigation.MenuBuilder.Profile.END_FRIENDSHIP_BUTTON}`);
		this.endFriendshipLink.on("click", this.openModal.bind(this));
	}

	private openModal(event: JQuery.ClickEvent) {
		event.preventDefault();

		this.modal.modal({
			backdrop: "static",
			keyboard: false,
		});
	}
}
