import I18Next from "../Control/Vue/I18Next";
import { AntiSpamCountryLaw } from "../utils/AntiSpamCountryLaw";
import { prompt } from "./../Control/Vue/Notifications/Prompts";

export class ProfileForm {
	private readonly profileForm: JQuery;
	private readonly updateButton: JQuery;
	private readonly form: JQuery;

	private requestPromise: JQueryXHR | null = null;

	constructor(containerId: DOMElementIdentifier, _constructionParameters: ConstructionParameters) {
		this.profileForm = jQuery("#" + containerId);

		this.updateButton = this.profileForm.find(".mia-updateButton");
		this.updateButton.click(this.onUpdateButtonClick.bind(this));

		this.form = jQuery("form");
		this.form.validate().settings.errorPlacement = (error: JQuery, element: JQuery) => {
			element.parents(".form-group").find(".error-container").append(error);
		};

		const emailPreferences = jQuery(".mia-emailPreferences");
		if (emailPreferences) {
			const countryDropDown = this.profileForm.find("#parentCountry");
			countryDropDown.change(AntiSpamCountryLaw.OnCountryDropDownChange.bind(this));
		}
	}

	private onUpdateButtonClick(event: JQuery.Event) {
		event.preventDefault();

		if (!this.form.valid()) {
			return;
		}

		const firstName = this.profileForm.find("#parentFirstName").val();
		const lastName = this.profileForm.find("#parentLastName").val();
		const country = this.profileForm.find("#parentCountry").val();
		const timeZoneId = this.profileForm.find("#parentTimeZone").val();
		const acceptNewsletters = AntiSpamCountryLaw.GetNewsletterValue();
		const acceptChildReports = AntiSpamCountryLaw.GetChildReportValue();

		const updateURL = this.updateButton.data("url")
			.replace("PLH_fname", firstName)
			.replace("PLH_lname", lastName)
			.replace("PLH_country", country)
			.replace("PLH_tzid", timeZoneId)
			.replace("PLH_acceptNewsletters", acceptNewsletters)
			.replace("PLH_acceptChildReports", acceptChildReports);

		if (this.isRequestExecutionAllowed()) {
			this.updateButton.button("loading");
			this.requestPromise = jQuery.get(updateURL)
				.done(this.onUpdateSucceeded.bind(this))
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(this.onRequestFailed)
				.promise();
		}
	}

	private onUpdateSucceeded(data: any) {
		this.updateButton.button("reset");
		if (data.success) {
			window.location.reload();
		} else {
			if (data.errorMessage) {
				void prompt(data.errorMessage, [{ label: I18Next.tr("OkCancel_Ok"), value: undefined }]);
			}
		}
	}

	private isRequestExecutionAllowed() {
		return this.requestPromise == null || this.requestPromise.state() !== "pending";
	}

	private onRequestFailed(xhr: JQuery.jqXHR, error: string): void {
		// eslint-disable-next-line no-console
		console.log(xhr);
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
