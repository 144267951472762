import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";
import { MiaPlaza } from "../../../../Reinforced.Typings";
import { ForNotifier } from "./DerivedInterfaces";
import IdGenerator from "@/utils/IdGenerator";

@Component({
	components: {
		AkiMessage: async () => (await import("../Aki/AkiMessage.vue")),
		Banner: async () => (await import("../Banner.vue")),
		ChallengeSolved: async () => (await import("../ChallengeSolved.vue")),
		CurriculumInitialized: async () => (await import("../CurriculumInitialized.vue")),
		MemberPenalty: async () => (await import("../MemberPenalty.vue")),
		MiaPrepAnnouncement: async () => (await import("../MiaPrepAnnouncement.vue")),
		FullStoryConsent: async () => (await import("../FullStoryConsent.vue")),
		MultiplayerGameInvitation: async () => (await import("../MultiplayerGameInvitation.vue")),
		Prompt: async () => (await import("../Prompt.vue")),
		SessionMessage: async () => (await import("../SessionMessage.vue")),
		SiteSlowWarning: async () => (await import("../SiteSlowWarning.vue")),
		ValidationError: async () => (await import("../ValidationError.vue")),
		IntroductionTutorialVideos: async () => (await import("../IntroductionTutorialVideos.vue")),
	},
	template: `<span>
					<vue-snotify/>
					<component v-for="item in notifications" :key="item.UniqueIdentifier" :is="item.Template" :from="item" />
				</span>`,
})
export default class Notifier extends Vue {
	// A singleton so we can show notifications from anywhere in our JS/TS code.
	public static Instance: Notifier;

	public static show<T extends MiaPlaza.Control.Vue.Notifications.Shared.INotification>(notification: ForNotifier<T>): void {
		Notifier.Instance.show(notification);
	}

	@Prop({required: true, type: Array}) protected initialNotifications!:
		MiaPlaza.Control.Vue.Notifications.Shared.INotification[];

	private notifications: MiaPlaza.Control.Vue.Notifications.Shared.INotification[] = [];

	public show<T extends MiaPlaza.Control.Vue.Notifications.Shared.INotification>(notification: ForNotifier<T>): void {
		if (this.hasReachedMaximumNotificationAmount()) {
			return;
		}

		const notificationWithDefaults = {
			Lifetime: MiaPlaza.Model.Lifetime.VOLATILE,
			UniqueIdentifier: IdGenerator.Generate(),
			...notification
		};
		this.notifications.push(notificationWithDefaults);
	}

	protected created(): void {
		this.notifications = [];
		this.showNotifications();
		Notifier.Instance = this;
	}

	private showNotifications() {
		this.initialNotifications.forEach(notification => this.show(notification));
	}

	private hasReachedMaximumNotificationAmount(): boolean {
		const maxNotificationAmount = this.$snotify.config.global?.maxOnScreen ?? 0;
		return this.$snotify.notifications.length >= maxNotificationAmount;
	}
}
