import { CheckboxPanel } from "./CheckboxPanel";

export class DateCheckboxPanel extends CheckboxPanel {
	private readonly dateSelector: JQuery;

	constructor(panel: JQuery) {
		super(panel);

		this.dateSelector = this.panel.find(".mia-panelContent");
	}

	protected OnCheckboxChecked(): void {
		super.OnCheckboxChecked();
		this.dateSelector.show();
	}

	protected OnCheckboxUnhecked(): void {
		super.OnCheckboxUnhecked();
		this.dateSelector.hide();
	}
}
