import { RequestHandler } from "../base/RequestHandler";

export class TextEditor extends RequestHandler {
	protected readonly primaryButton: JQuery;

	constructor(element: JQuery) {
		super(element);

		this.primaryButton = this.element.find(".mia-texteditor-primary");
		this.primaryButton.click(this.onPrimaryClick.bind(this));
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onFail(res: any): void {
		this.primaryButton.button("reset");
		super.onFail(res);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onDone(res: any): void {
		this.primaryButton.button("reset");
		super.onDone(res);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onSuccess(_res: any): void {
		this.primaryButton.button("reset");
	}

	protected onPrimaryClick(event: JQuery.Event): void {
		event.preventDefault();

		this.primaryButton.button("loading");
		this.setRequest(jQuery.post(this.primaryButton.data("url"), {text: this.element.find("textarea").val()}));
	}
}
