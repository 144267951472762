export class ModerationNotifier {
	private readonly container: JQuery;

	constructor(containerId: DOMElementIdentifier, private constructionParameters: ConstructionParameters) {
		this.container = jQuery("#" + containerId);
		jQuery(document).ready(this.onDocumentReady.bind(this));
	}

	private onDocumentReady() {
		void jQuery.get(this.constructionParameters.countDiscussionItemsUrl)
			.done(this.onCount.bind(this))
			.fail(this.onRequestFailed.bind(this))
			.promise();
	}

	private onCount(data: any) {
		if (data.count !== 0) {
			this.container.removeClass("hidden");
			this.container.text(data.message);
		}
	}

	private onRequestFailed(xhr: JQuery.jqXHR, error: string) {
		// eslint-disable-next-line no-console
		console.log(xhr);
		// eslint-disable-next-line no-console
		console.log(error);
	}
}
