import { VNode } from "vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import IBuffered from "./IBuffered";

const abstractMethodNotOverridenException = Error("abstract method not implemented");

@Component
export default class BufferedBase<T> extends Vue implements IBuffered<T> {
	// The value of this element in the underlying store or previous value if the element is currently
	// being invalidated and reloaded.
	// This element is prone to some flickering, e.g., when you populate
	// text boxes with this and then click a save button to store & reload, this prop is recomputed before
	// the reload is complete (because isInvalidating has changed). It then returns previousValue.
	// This is the same value that you have seen before but it will make your text boxes rerender to their
	// original state. If you don't want this (exactly, you don't) then read "value" instead.
	public get computedValue(): T | null {
		if (this.isResetting) {
			void this.loadInBackend();
			return this.previousValue;
		}
		if (this.isInvalidating) {
			return this.previousValue;
		}

		const val = this.getFromBackend();
		if (val == null) {
			void this.loadInBackend();
			return this.previousValue;
		} else {
			this.previousValue = val;
			return this.previousValue;
		}
	}

	// Whether this element has currently no value in the backend but the backend is loading a new value.
	// It could also be that this element simply does not exist but we do not distinguish these cases.
	protected get isLoading(): boolean {
		return this.getFromBackend() == null;
	}

	public get isResetting(): boolean {
		return this.isBackendResetting();
	}

	// The value of this element in the underlying store or previous value if the element is currently
	// being invalidated and reloaded.
	public value: T | null = null;

	// While the backend is reloading, we serve this value to avoid flickering.
	protected previousValue: T | null = null;

	// Non-zero iff a call to invalidate() has not resolved yet.
	private isInvalidating = 0;

	// Invalidate the underlying data source and reload.
	// Returns a promise that resolves once the reload is complete.
	public async invalidate(until?: Promise<unknown>): Promise<void> {
		this.isInvalidating++;
		await this.invalidateInBackend();
		if (until != null) {
			await until;
		}
		await this.invalidateInBackend();
		this.isInvalidating--;
		if (this.isInvalidating === 0) {
			await this.loadInBackend();
		}
	}

	@Watch("computedValue", {immediate: true})
	protected onComputedValueChange(newValue: T | null, oldValue: T | null): void {
		if (newValue === oldValue) {
			return;
		}
		this.value = newValue;
	}

	protected getFromBackend(): T { throw abstractMethodNotOverridenException; }
	protected loadInBackend(): Promise<unknown> { throw abstractMethodNotOverridenException; }
	protected invalidateInBackend(): Promise<unknown> { throw abstractMethodNotOverridenException; }
	protected isBackendResetting(): boolean { throw abstractMethodNotOverridenException; }

	protected render(): VNode[] {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return this.$scopedSlots.default!({
			buffered: this,
		})!;
	}
}
