import { VueConstructor } from "vue";
import { Component, Prop } from "vue-property-decorator";
import BufferedBase from "./BufferedBase";
import ConversionModule from "./ConversionModule";
import IModuleAdapter from "./ModuleAdapters/IModuleAdapter";

export type WithOptionalImplicitFields<T> = T & {
	Component?: string | VueConstructor;
};

@Component
export default class BufferedConversion<S, T> extends BufferedBase<WithOptionalImplicitFields<T>> {
	@Prop({ required: true }) protected readonly from!: S;
	@Prop({ required: true }) protected readonly adapter!: IModuleAdapter<S, T, ConversionModule<S, T>>;

	protected getFromBackend(): WithOptionalImplicitFields<T> {
		return this.adapter.data(this.from);
	}

	protected loadInBackend(): Promise<void> {
		return this.adapter.load([this.from]);
	}

	protected invalidateInBackend(): Promise<void> {
		return this.adapter.module.invalidate(this.from);
	}

	protected isBackendResetting(): boolean {
		return this.adapter.module.isResetting;
	}
}
