class ProgressIndicatingPrototype { }

interface ProgressIndicatingPrototype {
	showProgressIndicator: (positionRelative: boolean, cssRuleForAlignEl: string, searchCssRuleDown: boolean) => void;
	hideProgressIndicator: () => void;
}

/**
 * Extend your TS class from ProgressIndicator to access the legacy js progress indicator control methods from this.
 */
export class ProgressIndicator extends ProgressIndicatingPrototype {
	constructor(private readonly el: HTMLElement) {
		super();

		ProgressIndicatingPrototype.prototype.showProgressIndicator = ProgressIndicatingControl.showProgressIndicator;
		ProgressIndicatingPrototype.prototype.hideProgressIndicator = ProgressIndicatingControl.hideProgressIndicator;
	}
}
