import { Store } from "vuex";
import { useModule } from "vuex-simple";
import IModule from "../IModule";
import IModuleAdapter from "./IModuleAdapter";

export default class ModuleAdapter<S, T, M extends IModule<S, T>> implements IModuleAdapter<S, T, M> {
	public readonly module: M;

	constructor(store: Store<any>, module: string) {
		const maybeModule = useModule<M>(store, [module]);
		if (maybeModule == null) {
			throw Error(`Module ${module} does not exist.`);
		}
		this.module = maybeModule;
	}

	public data(key: S): T { return (this.module.items as any)[key]; }
	public load(keys: S[]): Promise<void> { return this.module.load(keys); }
}
