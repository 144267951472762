























import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Lightbox extends Vue {
	@Prop({ required: false, type: Boolean, default: true }) private showCrossInverted!: boolean;

	protected turnOffTTS(): void {
		window.soundManager.stopAll();
	}
}
