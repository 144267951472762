




































import "swiper/dist/css/swiper.css";

import cloneDeep from "lodash-es/cloneDeep";
import { Component, Prop, Watch } from "vue-property-decorator";

import Conditional from "./../../ConditionalReplacement.vue";
import { getMaxSlidesPerView } from "./Items/Config";
import ListItemLoader from "./ListItemLoader.vue";
import VueListBase from "./VueListBase";
import { ISwiperOptions } from "./VueListOptions";

const virtualPages = 2;

interface ISwiper {
	activeIndex: number;
	slideTo: (index: number, speed: number, runCallbacks: boolean) => void;
}

@Component({
	components: {
		swiper: async () => (await import("vue-awesome-swiper")).swiper,
		swiperSlide: async () => (await import("vue-awesome-swiper")).swiperSlide,
		Conditional,
		ListItemLoader,
	},
})
export default class SwiperList<S, T> extends VueListBase<S, T> {
	@Prop({type: Number, required: false, default: 0}) protected leftmost!: number;

	/**
	 * We pretend to swiper that this subset of itemsAsRows are all the items that exist.
	 * This way we only keep a small number of items in the DOM, namely virtualPages many pages in each direction.
	 */
	protected virtualItems = [] as S[][];

	/**
	 * The offset between itemsAsRows and virtualItems, i.e., itemsAsRows[i + virtualOffset] == virtualItems[i].
	 */
	protected virtualOffset = 0;

	/**
	 * The underlying JavaScript object from the swiper.js library once the swiper has been initialized.
	 */
	private swiper = null as ISwiper | null;

	/**
	 * Move the swiper such that list item `offset` (zero based index) is in the
	 * leftmost visible position. Equivalently, move such that the slides
	 * `offset/slidesPerColumn` is in the leftmost visible position.
	 */
	@Watch("leftmost", {immediate: true})
	public async goto(offset: number): Promise<void> {
		await this.preload(offset);

		this.virtualOffset = Math.floor(offset / this.slidesPerColumn);

		if (this.swiper === null || this.isEmpty) {
			// If the swiper has not initialized yet, we cannot do much. We will wait
			// for it which will call us again from onReady().
			return;
		}

		// The virtualOffset has been set such that the very first virtual slide is
		// the slide corresponding to offset. We now go to that very first slide.
		// The virtual slides will be mangled a bit by refreshFrom*() but the
		// currently "active" slide will be kept on the visible page. I.e., we will
		// effectively go to the offset slide.
		this.swiper.slideTo(0, 0, false);

		this.virtualItems = [];
		this.refreshFromBackend();
		this.refreshFromFrontend();
	}

	get effectiveOptions(): ISwiperOptions {
		const effectiveOptions = cloneDeep(this.options.backendOptions as ISwiperOptions);
		if (effectiveOptions.includeNavigationButtons) {
			effectiveOptions.navigation = {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			};
		}

		if (effectiveOptions.displayAdjacentItems) {
			this.makeAdjacentItemsVisible(effectiveOptions);
		}

		// we are handling slidesPerColumn setting ourselves while loading data
		// and swiper list doesn't know about it, for swiper list each slide always has 1 slide per column
		effectiveOptions.slidesPerColumn = 1;

		return effectiveOptions;
	}

	get slidesPerColumn() : number {
		return (this.options.backendOptions as ISwiperOptions).slidesPerColumn;
	}

	get useTransitionOnEmptying(): boolean {
		return this.effectiveOptions.useTransitionOnEmptying ?? true;
	}

	onReady(): void {
		this.swiper = (this.$refs.swiper as any).swiper;
		void this.goto(this.virtualOffset * this.slidesPerColumn);
	}

	get itemsAsRows(): S[][] {
		const items = this.items;
		if (items.length === 0) {
			return [];
		}

		const ret: S[][] = [[]];
		for (const item of items) {
			if (ret[ret.length - 1].length >= this.slidesPerColumn) {
				ret.push([]);
			}
			ret[ret.length - 1].push(item);
		}
		return ret;
	}

	@Watch("isResetting")
	protected onReset(isResetting: boolean): void {
		if (!isResetting) {
			void this.goto(this.leftmost);
		}
	}

	// Display 'slidesPerView' number of slides per view and some portion of the next item (previous for the last view)
	private makeAdjacentItemsVisible(options: ISwiperOptions) {
		const adjacentItemPortion = options.adjacentItemPortion ?? 0.2;
		if (options.slidesPerView) {
			options.slidesPerView += adjacentItemPortion;
		}

		Object.values(options.breakpoints || {})
			.forEach((config) => {
				if (config.slidesPerView) {
					config.slidesPerView += config.adjacentItemPortion? config.adjacentItemPortion: adjacentItemPortion;
				}
			},
		);
	}

	private listItemIndex(index: number) {
		return this.virtualOffset + index;
	}

	@Watch("itemsAsRows", {immediate: true})
	private handler() {
		this.refreshFromBackend();
		this.refreshFromFrontend();
	}

	private slideChange() {
		const mySwiper = (this.$refs.swiper as any);
		const firstVisibleItem = ((mySwiper.swiper.activeIndex as number) + this.virtualOffset) * this.slidesPerColumn;
		void this.preload(firstVisibleItem);
		mySwiper.$once("slideChangeTransitionEnd", () => this.refreshFromFrontend());
	}

	/**
	 * Update virtualItems with elements from itemsAsRows.
	 * Called when data in the backend changes or when the virtualOffset was modified.
	 */
	private refreshFromBackend() {
		const newVirtualItems = [];
		const maxSlidesPerView = getMaxSlidesPerView(this.options.backendOptions as ISwiperOptions);
		for (let i = 0; i < maxSlidesPerView * (2 * virtualPages + 1); i++) {
			const offset = this.virtualOffset + i;
			if (offset >= 0 && offset < this.itemsAsRows.length) {
				newVirtualItems.push(this.itemsAsRows[offset]);
			} else {
				break;
			}
		}
		this.virtualItems = newVirtualItems;
	}

	/**
	 * Update virtualItems when the swiper might run out of items to display soon.
	 * Called after each swipe.
	 */
	private refreshFromFrontend() {
		if (this.swiper == null) {
			// The swiper has not initialized yet. When it initializes, it will call
			// refreshFromFrontend() again through goto() and so override anything
			// we'd do here anyway.
			return;
		}
		let activeColumn = this.swiper.activeIndex;
		const maxSlidesPerView = getMaxSlidesPerView(this.options.backendOptions as ISwiperOptions);
		// Whenever changing to the previous or the following page would get us to the edge of the virtual slides,
		// we reset the virtual slides such that we are again in the center of them.
		// (We never want to get to the edge because it makes the previous/next buttons flicker.)
		if (activeColumn - maxSlidesPerView <= 0
			|| activeColumn + maxSlidesPerView + maxSlidesPerView >= this.virtualItems.length) {
			// We now move this.virtualOffset so that the activeColumn is not at boundary anymore.
			while (true) {
				// Determine which side of the center we are on with activeColumn; when this is zero, then we are in the center
				// of the virtualItems already and we cannot improve the situation anymore.
				const activeColumnDelta = Math.sign(maxSlidesPerView * virtualPages - activeColumn);
				if (activeColumnDelta === 0) {
					break;
				}
				// We move the virtualOffset into the opposite direction to move the activeColumnDelta towards zero.
				const virtualOffsetDelta = -activeColumnDelta;
				if (this.virtualOffset + virtualOffsetDelta < 0) {
					// We cannot go before the first page. Abort if the virtualOffset is already minimal.
					break;
				}
				if (virtualOffsetDelta > 0
				&& this.virtualOffset + virtualOffsetDelta + maxSlidesPerView * (2 * virtualPages + 1) > this.itemsAsRows.length) {
					// There is no more data so we cannot move the virtualOffset any further, symmetric to the preceding case.
					// We stop here but it's important that refreshFromBackend() calls us again, so we move further once new
					// data comes in.
					break;
				}
				// move virtualOffset and activeColumn one step in the right direction and try again.
				activeColumn += activeColumnDelta;
				this.virtualOffset += virtualOffsetDelta;
			}
		}

		if (activeColumn !== this.swiper.activeIndex) {
			this.refreshFromBackend();
			// Note that there can be a slight flicker here (depending on the size of involved images.)
			// There is not much we can do about it as Vue's DOM changes are picked up with the wrong offset originally.
			// Unfortunately Swiper's native virtual slides did not work sufficiently well in late 2018 to use them.
			this.$nextTick(() => {
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				this.swiper!.slideTo(activeColumn, 0, false);
			});
		}
	}
}
