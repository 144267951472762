import { Async } from "../utils/Async";
import { HandlerType } from "./base/HandlerType";

export class ForgotPasswordParent implements HandlerType {
	protected requestPromise!: JQueryXHR;
	private readonly emailTextBox: JQuery;
	private readonly sendButton: JQuery;
	private readonly messageArea: JQuery;

	constructor(private container: JQuery) {
		this.emailTextBox = container.find("#email");
		this.emailTextBox.val(this.container.data("message"));
		this.sendButton = container.find("#sendButton");
		this.messageArea = container.find("#messageArea");
		this.sendButton.click(this.onSendClick.bind(this));
	}

	private onLoginSucceeded(data: any) {
		this.sendButton.button("reset");

		if (data.success) {
			this.windowRedirectTo(data.redirectUrl);
		} else {
			if (data.message) {
				this.showMessage(data.message);
			}
		}
	}

	private onSendClick(event: JQuery.Event): void {
		event.preventDefault();

		if (!jQuery("form").valid()) {
			return;
		}

		this.sendButton.button("loading");
		if (Async.IsRequestExecutionAllowed(this.requestPromise)) {
			const data = {
				email: this.emailTextBox.val(),
			};

			this.requestPromise = jQuery.post(this.container.data("url"), data)
				.done(this.onLoginSucceeded.bind(this))
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(Async.OnRequestFailed)
				.promise();
		}
	}

	private showMessage(message: string) {
		this.messageArea.removeClass("hidden");
		this.messageArea.html(message);
	}

	private windowRedirectTo(location: string): void {
		if (location) {
			window.location.href = location;
		} else {
			window.location.reload();
		}
	}
}
