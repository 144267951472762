import findIndex from "lodash-es/findIndex";
import { Mutation, State } from "vuex-simple";
import { MiaPlaza } from "../../../Reinforced.Typings";

type Culture = string;

export const HUMAN_CULTURES = [MiaPlaza.Control.Vue.I18Next.US];
export const CULTURES = [MiaPlaza.Control.Vue.I18Next.C, ...HUMAN_CULTURES];

export default class I18NextModule {
	@State()
	public culture!: Culture;

	@State()
	public xhr!: string;

	@State()
	public version!: string;

	constructor(culture: Culture, xhr: string, version: string) {
		this.culture = culture;
		this.xhr = xhr;
		this.version = version;
	}

	@Mutation()
	public setCulture(culture?: Culture): void {
		if (culture != null) {
			this.culture = culture;
		} else {
			this.culture = CULTURES[(findIndex(CULTURES, (c) => c === this.culture) + 1) % CULTURES.length];
		}
	}
}
