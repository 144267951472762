import { HandlerType } from "../../../controls/base/HandlerType";

export class CheckboxPanel implements HandlerType {
	protected readonly checkbox: JQuery;

	constructor(protected panel: JQuery) {
		this.checkbox = panel.find(".mia-CheckBox");

		const checkboxLink = this.panel.find(".mia-checkboxLink");
		checkboxLink.click(this.onCheckboxLinkClick.bind(this));

		this.checkbox.change(this.onCheckboxChange.bind(this));
	}

	protected IsChecked(): boolean {
		return this.checkbox.is(":checked");
	}

	protected OnCheckboxChecked(): void {
		this.panel.addClass("active");
	}

	protected OnCheckboxUnhecked(): void {
		this.panel.removeClass("active");
	}

	private onCheckboxLinkClick(event: JQuery.ClickEvent) {
		if (event.target !== this.checkbox[0]) {
			event.preventDefault();
			this.checkbox.prop("checked", !this.IsChecked());
			this.checkbox.trigger("change");
		}
	}

	private onCheckboxChange(_event: JQuery.Event) {
		if (this.IsChecked()) {
			this.OnCheckboxChecked();
		} else {
			this.OnCheckboxUnhecked();
		}
	}
}
