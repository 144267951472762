











import { Component, Prop, Vue } from "vue-property-decorator";
import ResponsiveImage from "@/Control/Vue/ResponsiveImage";

@Component({
	components: {
		ResponsiveImage
	}
})
export default class Feature extends Vue {
	@Prop({ required: true, type: String }) public featureKey!: string;
}
