import IModule from "../IModule";
import IModuleAdapter from "./IModuleAdapter";

export default abstract class WrappingModuleAdapterBase<S, T, M extends IModule<S, T>>
	implements IModuleAdapter<S, T, M> {
	protected readonly wrapped: IModuleAdapter<S, T, M>;

	constructor(wrapped: IModuleAdapter<S, T, M>) {
		this.wrapped = wrapped;
	}

	public get module(): Omit<M, "load"> { return this.wrapped.module; }
	public data(key: S): T { return this.wrapped.data(key); }
	public load(keys: S[]): Promise<void> { return this.wrapped.load(keys); }
}
