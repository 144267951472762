










import { Component, Prop, Vue } from "vue-property-decorator";
import ResponsiveImage, { Image } from "../../Control/Vue/ResponsiveImage";
import AsyncComputed from "../../Control/Vue/AsyncComputed";
import NavbarLogo from "theme/logos/navbar_logo.svg";

@Component({
	components: { ResponsiveImage },
})
export default class Logo extends Vue {
	@Prop({required: false, type: Boolean, default: false}) maintenance!: boolean;
	@Prop({required: true, type: Boolean}) development!: boolean;
	@Prop({required: false, type: Boolean, default: false}) isActiveMohsStudent!: boolean;

	@AsyncComputed({default: () => null })
	private async logo(): Promise<Image> {
		if (this.development) {
			// Show a scary logo since now DeveloperTasks are available which would
			// be very dangerous on the production site.
			return (await import("theme/logos/navbar_dev_logo.svg")).default;
		} else if (this.maintenance) {
			return (await import("theme/logos/maintenance_logo.svg")).default;
		} else if (this.isActiveMohsStudent) {
			return (await import("theme/logos/mohs_logo.svg")).default;
		} else {
			return NavbarLogo;
		}
	}
}
