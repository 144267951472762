import { Mutation, State } from "vuex-simple";

export const moduleName = "funds-module";

export default class FundsModule {
	@State()
	public amount!: number;

	constructor(amount: number) {
		this.amount = amount;
	}

	@Mutation()
	public set(amount: number): void {
		this.amount = amount;
	}

	public get formattedAmount(): string {
		return new Intl.NumberFormat().format(this.amount);
	}
}
