


























import { Component } from "vue-property-decorator";
import Conditional from "./../../ConditionalReplacement.vue";
import ListItemLoader from "./ListItemLoader.vue";
import VueListBase from "./VueListBase";

@Component({
	components: { Conditional, ListItemLoader },
})
export default class ExpandableList<S, T> extends VueListBase<S, T> {
	private visibleLength = 0;

	private get hasMore() {
		if (this.items == null) {
			return false;
		}
		return this.bufferedItems.totalCountEstimate > this.items.length;
	}

	private get showLoadMore() {
		if (this.items == null) {
			return false;
		}
		if (this.items.length === 0) {
			return false;
		}
		return this.hasMore || this.visibleLength < this.items.length;
	}

	private created() {
		this.visibleLength = this.options.pageSize;
		void this.preload(this.visibleLength);
	}

	private get visibleItems() {
		if (this.items == null) {
			return [];
		}
		return this.items.slice(0, this.visibleLength);
	}

	private onMoreClick() {
		this.visibleLength += this.options.pageSize;
		void this.preload(this.visibleLength);
	}
}
