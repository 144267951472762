import { BuyMembership } from "./registration/BuyMembership";
import { ConfigureChild } from "./registration/ConfigureChild";
import { RegisterChild } from "./registration/RegisterChild";
import { RegisterParent } from "./registration/RegisterParent";
import { Step } from "./Step";
import { StepType } from "./StepType";

export class StepFactory {
	public static GetStep(name: string, stepControl: JQuery): Step {
		return new (Function.prototype.bind.apply(({
			[StepType.RegisterChild]: RegisterChild,
			[StepType.ConfigureChild]: ConfigureChild,
			[StepType.RegisterParent]: RegisterParent,
			[StepType.BuyMembership]: BuyMembership,
			// eslint-disable-next-line @typescript-eslint/ban-types
		} as any)[name] as Function, [null, stepControl]));
	}
}
