/**
 * Defines a Vue.js directive, v-md, that allows you to parse and render Markdown 
 * text directly in your Vue templates. The directive uses the 'micromark' library for parsing 
 * Markdown, and the parsing behavior can be customized using the 'MarkdownExtentions' configuration object.
 *
 * The 'v-md' directive can be used in any Vue template like this:
 * 
 * <div v-md="markdownText"></div>
 *
 * Where 'markdownText' is a variable in your Vue component's data that contains the Markdown 
 * text to be rendered. The directive will automatically parse the Markdown text and render 
 * the resulting HTML inside the div.
 *
 * If an error occurs during parsing, the raw Markdown text will be displayed instead, and 
 * the error will be logged using the 'Log.error()' function.
 */
import Vue from "vue";
import { MarkdownExtentions } from './MarkdownExtentions';
import Log from "@/utils/Log";

export default class Markdown {
	// Called by Vue.use() to enable the v-md directive.
	public static install(): void {
		Vue.directive("md", {
			// eslint-disable-next-line @typescript-eslint/unbound-method
			bind: Markdown.bind,
			// eslint-disable-next-line @typescript-eslint/unbound-method
			unbind: Markdown.unbind,
		});
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected static unbind(this: undefined, el: Element): void {
		el.innerHTML = "";
	}
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected static async bind(this: undefined, el: Element, binding: any): Promise<void> {
		try {
			const html = (await import("micromark")).micromark(binding.value, { extensions: [MarkdownExtentions] });
			el.innerHTML = html;
		} catch (err) {
			(el as HTMLElement).innerText = binding.value;
			void Log.error(err);
		}
	}
}
