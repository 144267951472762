var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "conditional",
    {
      attrs: {
        condition: !_vm.isEmpty,
        "message-otherwise": _vm.messageIfEmpty
      },
      scopedSlots: _vm._u(
        [
          {
            key: "replacement",
            fn: function() {
              return [_vm._t("empty")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "transition-group",
        { attrs: { name: "mia-InfiniteLoadingList-animation" } },
        _vm._l(_vm.visibleItems, function(item, index) {
          return _c(
            "div",
            { key: item, staticClass: "mia-listItem" },
            [
              _c("list-item-loader", {
                attrs: {
                  index: index,
                  from: item,
                  "item-props": _vm.$attrs,
                  adapter: _vm.conversionAdapter,
                  component: _vm.options.component
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._t(
        "load-more-button",
        [
          _vm.showLoadMore
            ? _c("span", [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-link mia-ExpandableListButton",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.onMoreClick($event)
                      }
                    }
                  },
                  [
                    _vm._t("load-more", [
                      _c("span", {
                        directives: [
                          {
                            name: "tr",
                            rawName: "v-tr",
                            value: "loadMore",
                            expression: "'loadMore'"
                          }
                        ]
                      })
                    ])
                  ],
                  2
                )
              ])
            : _vm._e()
        ],
        {
          visibleLength: _vm.visibleLength,
          totalLength: _vm.items.length,
          onMoreClick: _vm.onMoreClick
        }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }