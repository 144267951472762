var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mia-Iframe vld-parent" },
    [
      _c(
        "loading",
        { attrs: { active: !_vm.isLoaded, "is-full-page": false } },
        [
          _c("span", {
            directives: [
              {
                name: "tr",
                rawName: "v-tr",
                value: "iframe_loading",
                expression: "'iframe_loading'"
              }
            ],
            staticClass: "mia-loaderMessage"
          })
        ]
      ),
      _vm._v(" "),
      _c("iframe", {
        attrs: { src: _vm.src, width: _vm.width, height: _vm.height },
        on: {
          load: function($event) {
            _vm.isLoaded = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }