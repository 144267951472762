import { CreateElement, VNode } from "vue";
import InlineSvg from "vue-inline-svg";
import { Component, Prop, Vue } from "vue-property-decorator";
import { MiaPlaza } from "../../Reinforced.Typings";

export interface IResponsiveImage {
	src: string;
	srcSet: string;
	placeholder: string;
	images: Array<{path: string, width: number, height: number}>;
	width: number;
	height: number;
}

export type IFileImage = string;

export type Image = MiaPlaza.Navigation.JSON.Converters.ILazySizes | IResponsiveImage | IFileImage;

function isLazySizes(from: Image): from is MiaPlaza.Navigation.JSON.Converters.ILazySizes {
	return (from as MiaPlaza.Navigation.JSON.Converters.ILazySizes).OriginalUrl !== undefined;
}

function isFileImage(from: Image): from is IFileImage {
	return typeof(from) === "string";
}

@Component({
	components: { InlineSvg },
})
export default class ResponsiveImage extends Vue {
	@Prop({required: true}) protected from!: Image;
	@Prop({required: false, default: true}) protected inlineSvg!: boolean;

	private isLazy = false;

	get OriginalUrl(): string {
		if (isLazySizes(this.from)) {
			return this.from.OriginalUrl;
		} else if (isFileImage(this.from)) {
			return this.from;
		} else {
			return this.from.src;
		}
	}

	get Sizes(): MiaPlaza.Navigation.JSON.Converters.IPictureAtSize[] | null {
		if (isLazySizes(this.from)) {
			return this.from.Sizes || null;
		} else if (isFileImage(this.from)) {
			return null;
		} else {
			return this.from.images.map((variant) => {
				return {
					Url: variant.path,
					Width: variant.width,
				};
			});
		}
	}

	get AspectRatio(): string | number | null {
		if (isLazySizes(this.from)) {
			return this.from.AspectRatio;
		} else if (isFileImage(this.from)) {
			return null;
		} else {
			return this.from.width / this.from.height;
		}
	}

	protected render(h: CreateElement): VNode {
		const attrs: any = {};
		const classes: string[] = ["mia-img"];
		if (this.OriginalUrl.endsWith(".svg") && this.inlineSvg) {
			// If the image is svg, we want to make it inline.
			// So we can apply CSS to it and make animations work in Safari, see #12244.
			return h("div", {
				class: classes,
			},
			[h("inline-svg", {
					attrs:	{
						src: this.OriginalUrl,
					},
				}),
			]);
		}
		if (this.Sizes && this.Sizes.length > 0) {
			attrs.src = this.Sizes[0].Url;
			attrs["data-sizes"] = "auto";
			attrs["data-aspectratio"] = this.AspectRatio;
			attrs["data-srcset"] = this.Sizes.map(
				(pic: MiaPlaza.Navigation.JSON.Converters.IPictureAtSize) => `${pic.Url} ${pic.Width}w`)
				.join(", ");
			classes.push("lazyload");
			classes.push("mia-blur-up");
			this.isLazy = true;
		} else {
			attrs.src = this.OriginalUrl;
		}

		return h("img", {
			attrs,
			class: classes,
		});
	}

	protected updated(): void {
		// This is probably not necessary if all for loops set keys correctly:
		// https://github.com/aFarkas/lazysizes/issues/367#issuecomment-289264840
		if (this.isLazy) {
			this.$el.classList.add("lazyload");
		}
	}
}
