import { CancellableTextEditor } from "../texteditor/CancellableTextEditor";

export class ChildProfileInfoBlockEdit extends CancellableTextEditor {
	private readonly editBtn: JQuery;
	private readonly view: JQuery;
	private readonly edit: JQuery;
	private readonly panel: JQuery;

	constructor(element: JQuery) {
		super(element);

		this.panel = element.find(".mia-infoblock-panel");
		this.view = element.find(".mia-infoblock-view");
		this.edit = element.find(".mia-CancellableTextEditor");

		this.editBtn = element.find(".mia-infoblock-editBtn");
		this.editBtn.click(this.onEditClick.bind(this));
	}

	protected onSecondaryClick(event: JQuery.Event): void {
		event.preventDefault();
		this.editMode(false);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onSuccess(res: any): void {
		this.view.html(res.value);
		this.editMode(false);
	}

	private onEditClick(event: JQuery.Event) {
		event.preventDefault();
		this.editMode(true);
	}

	private editMode(isEditMode: boolean) {
		if (isEditMode) {
			this.panel.show();
			this.editBtn.css("visibility", "hidden");
			this.view.hide();
			this.edit.show();
			this.edit.find("textarea").focus();
			this.panel.addClass("mia-primaryBorder");
		} else {
			this.panel[this.view.html() ? "show" : "hide"]();
			this.editBtn.css("visibility", "visible");
			this.view.show();
			this.edit.hide();
			this.panel.removeClass("mia-primaryBorder");
		}
	}
}
