

















import { VueConstructor } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import BufferedListItems from "../../Vuex/BufferedListItems";
import ConversionModule from "../../Vuex/ConversionModule";
import ListModule from "../../Vuex/ListModule";
import DebouncingModuleAdapter from "../../Vuex/ModuleAdapters/DebouncingModuleAdapter";
import ModuleAdapter from "../../Vuex/ModuleAdapters/ModuleAdapter";
import PreloadingModuleAdapter from "../../Vuex/ModuleAdapters/PreloadingModuleAdapter";
import BootstrapTableList from "./BootstrapTableList.vue";
import ExpandableList from "./ExpandableList.vue";
import InfiniteLoadingList from "./InfiniteLoadingList.vue";
import Config from "./Items/Config";
import SwiperList from "./SwiperList.vue";
import {isBootstrapTable, isExpandable, isInfiniteLoading, isSwiper} from "./VueListOptions";

@Component({
	components: { BufferedListItems },
	inheritAttrs: false,
})
export default class VueList<S, T> extends Vue {
	@Prop({type: String, required: true}) protected module!: string;
	@Prop({type: String, required: true}) protected conversionModule!: string;
	@Prop({type: String, required: true}) protected template!: string;

	private get config() {
		const config = Config[this.template];
		if (!config) {
			throw new Error(`ConfigError: No config for template "${this.template}" found in wwwroot/typescript/Control/Vue/Lists/VueList/Items/Config.ts`);
		}
		return config;
	}

	private get effectiveComponent(): VueConstructor {
		const options = this.config.backendOptions;
		if (isSwiper(options)) {
			return SwiperList;
		} else if (isInfiniteLoading(options)) {
			return InfiniteLoadingList;
		} else if (isBootstrapTable(options)) {
			return BootstrapTableList;
		} else if (isExpandable(options)) {
			return ExpandableList;
		} else {
			throw new Error("unknown list configuration");
		}
	}

	private get listAdapter() {
		return new PreloadingModuleAdapter<S>(new DebouncingModuleAdapter<number, S, ListModule<S>>(
			new ModuleAdapter<number, S, ListModule<S>>(this.$store, this.module)),
			this.config.pageSize);
	}

	private get conversionAdapter() {
		return new DebouncingModuleAdapter<S, T, ConversionModule<S, T>>(
		new ModuleAdapter<S, T, ConversionModule<S, T>>(this.$store, this.conversionModule));
	}
}
