export class Carousel {
	private readonly carousel: JQuery;

	constructor(containerId: DOMElementIdentifier, _constructionParameters: ConstructionParameters) {
		this.carousel = jQuery("#" + containerId);
		this.carousel.carousel({
			interval: 16000,
		});

		this.carousel.find(".mia-carousel-control, .mia-carousel-indicators > li")
			.click(this.onCarouselControlClick.bind(this));
	}

	private onCarouselControlClick(_event: JQuery.Event) {
		this.carousel.carousel("pause");
	}
}
