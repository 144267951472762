var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mia-SiteImpressum mia-Panel panel panel-default" },
    [
      _c("div", {
        directives: [
          {
            name: "tr",
            rawName: "v-tr",
            value: "siteimpressumtitle",
            expression: "'siteimpressumtitle'"
          }
        ],
        staticClass: "panel-heading"
      }),
      _vm._v(" "),
      _c("div", {
        directives: [
          {
            name: "tr",
            rawName: "v-tr",
            value: {
              key: "siteimpressum",
              CompanyAddress: _vm.$tr("CompanyAddressBlock_NoBreaks"),
              FeedbackEmail: _vm.$tr("FeedbackEmail")
            },
            expression:
              "{key: 'siteimpressum', \n\t\t\t'CompanyAddress': $tr('CompanyAddressBlock_NoBreaks'), \n\t\t\t'FeedbackEmail': $tr('FeedbackEmail'),\n\t\t}"
          }
        ],
        staticClass: "panel-body"
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }