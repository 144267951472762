export class MemberList extends ListBase {
	constructor(el: DOMElementIdentifier, protected options: ConstructionParameters) {
		super(el, options);

		jQuery("#" + el).data("attachedJSObject", this);
	}

	public loadPage(page: number): void {
		super.loadPage(page);

		const url = this.options.url.replace("PLH_pagenum", page);
		this.standardJSONRequest(url);
	}

	public fixDimensions(): void {
		super.fixDimensions();
	}

	public search(firstName: string, nickName: string, interest: string, friendCode: string): void {
		this.options.url = this.options.urlTemplate
			.replace("PLH_fname", firstName)
			.replace("PLH_nname", nickName)
			.replace("PLH_inter", interest)
			.replace("PLH_friendcode", friendCode);
		this.loadPage(0);
		this.resetDimensions();
	}
}
