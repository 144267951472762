import { RequestHandler } from "../base/RequestHandler";

export class Birthday extends RequestHandler {
	private readonly closeBtn: JQuery;
	private readonly submitBtn: JQuery;

	constructor(element: JQuery) {
		super(element);
		this.submitBtn = this.element.find(".mia-submit");
		this.submitBtn.click(this.onSubmitClick.bind(this));

		this.closeBtn = this.element.find(".close");
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onFail(res: any): void {
		this.closeBtn.click();
		super.onFail(res);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onDone(res: any): void {
		this.closeBtn.click();
		super.onDone(res);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onSuccess(_res: any): void {
		this.closeBtn.click();
		location.reload(true);
	}

	private onSubmitClick(event: JQuery.Event) {
		event.preventDefault();

		this.setRequest(jQuery.post(this.submitBtn.data("url"),
			{birthday: new Date(jQuery("#dateSelectorInput").val() as string).toDateString()}));
	}
}
