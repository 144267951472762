let inErrorHandlingMode = false;

import { MiaPlaza } from "../Reinforced.Typings";

// Writes to the Log on the *server*.
export default class Log {
	// Log an error to the server.
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public static async error(message: any): Promise<any> {
		try {
			// eslint-disable-next-line no-console
			console.error(message);

			const StackTrace = await import("stacktrace-js");
			const stack = await StackTrace.get();
			// The message is processed as a string to ensure that a string is explicitly being passed to 
			// the logger in the backend, as passing an object would cause deserialization errors.
			return StackTrace.report(stack, MiaPlaza.LO.JavaScript.Log.ErrorLoggingUrl, message.toString());
		} catch (err) {
			// eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
			console.error(`Could not report error ${message} to server due to ${err}.`);
		}
	}

	public static async onerror(message: string, url: string, line: number, column: number, error: Error): Promise<void> {
		// eslint-disable-next-line no-console
		console.error(error);

		// The general error message 'Script error.' ('Script error'
		// for IE) may be sent when an error was thrown in a script from
		// a different origin.
		// This is a safety feature inside some browsers to prevent
		// information leaks
		// (https://en.wikipedia.org/wiki/Same-origin_policy).
		// As the meta information for such errors is modified in a
		// way that makes it useless (line number 0, script '', ...), we
		// can safely throw it away.
		// If we ever host some scripts on different domains
		// ourselves, we should make sure that they can get through.
		// For more information, see
		// https://danlimerick.wordpress.com/2014/01/18/how-to-catch-javascript-errors-with-window-onerror-even-on-chrome-and-firefox/.
		if (message === "Script error." || message === "Script error") {
			return;
		}

		// Sentry was being overflown with logs from a malicious ad blocker
		// extension, upon further investigation, every log contained this number,
		// the next statement is to ensure that those logs are dropped, and not sent
		// to the backend. For more information, see 
		// https://dev.miaplaza.com/miaplaza/website/-/issues/15816
		if (error.message?.includes("198230182308109283091823098102938908128390")) {
			return;
		}

		if (inErrorHandlingMode) {
			// eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
			console.error(`Failed to log error as logging called itself recursively: ${message}`);
			return;
		}

		inErrorHandlingMode = true;
		// parse sourcemaps with stacktrace-js asynchronously to get readable stacktraces
		try {
			const StackTrace = await import("stacktrace-js");
			const stack = await StackTrace.fromError(error);
			await StackTrace.report(stack, MiaPlaza.LO.JavaScript.Log.ErrorLoggingUrl, message);
		} catch (err) {
			// eslint-disable-next-line no-console, @typescript-eslint/restrict-template-expressions
			console.error(`Failed to log error: ${err}`);
		} finally {
			inErrorHandlingMode = false;
		}
	}

	public static onUnhandledRejection(event: PromiseRejectionEvent): void {
		void Log.error(event.reason);
	}
}
