









import { Component, Prop, Vue } from "vue-property-decorator";
import Loading from "vue-loading-overlay";

@Component({
	components: {
		Loading
	}
})
export default class Iframe extends Vue {
	@Prop({ type: String, required: true }) protected src!: string;
	@Prop({ type: String, required: true }) protected width!: string;
	@Prop({ type: String, required: true }) protected height!: string;

	private isLoaded = false;
}
