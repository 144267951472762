
















import { Component, Prop, Vue } from "vue-property-decorator";
import ResponsiveImage from "@/Control/Vue/ResponsiveImage";
import { MiaPlaza } from "../../Reinforced.Typings";

@Component({
	components: {
		ResponsiveImage,
	},
})
export default class FlipCard extends Vue {
	@Prop({required: true}) protected headerTr!: string;
	@Prop({required: true}) protected descriptionTr!: string;
	@Prop({required: true}) protected buttonTextTr!: string;
	@Prop({required: true}) protected buttonLink!: string;
	@Prop({required: false}) protected icon!: MiaPlaza.Navigation.JSON.Converters.ILazySizes;

	private isFlipped = false;
}
