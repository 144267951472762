import { Mutation, State } from "vuex-simple";

export default class SelectedItemModule<T> {
	@State()
	public selected!: T | null;

	constructor(selected: T | null) {
		this.selected = selected;
	}

	@Mutation()
	public set(item: T | null): void {
		this.selected = item;
	}
}
