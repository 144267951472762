import axios, { AxiosRequestConfig } from "axios";
import parse from "url-parse";
import I18Next from "./Control/Vue/I18Next";
import { ForNotifier } from "./Control/Vue/Notifications/Shared/DerivedInterfaces";
import Notifier from "./Control/Vue/Notifications/Shared/Notifier";
import { MiaPlaza } from "./Reinforced.Typings";

export function setup(): void {
	setupNotificationOnError();
	setupPostLongQueries();
}

function setupNotificationOnError(): void {
	// Register an error handler for all Axios requests
	axios.interceptors.response.use(
		(response) => response,
		(error) => {
			// do not show error message popup if the request has been canceled.
			if (axios.isCancel(error)) {
				throw error;
			}

			const showErrorMessage = (message: string) => {
				const notification: ForNotifier<MiaPlaza.Control.Vue.Notifications.ISessionMessage> = {
					Message: message,
					Template: "SessionMessage",
					Type: MiaPlaza.Control.Vue.Notifications.SessionMessageType.Error,
					// Set a unique identifier so we only show one of these messages at once
					// (also so we do not flood the screen if the error handler ends up calling itself recursively.)
					UniqueIdentifier: "AxiosError",
				};
				Notifier.show(notification);
			};

			if (error.response?.data?.message != null) {
				showErrorMessage(error.response.data.message);
			} else {
				void I18Next.tr("ErrorUnexpectedAnswerFromServer").then(showErrorMessage);
			}

			if (error.response?.status === 503 && error.response?.data?.location) {
				window.location = error.response.data.location;	
			}

			// It's the responsibility of the server-logging to break infinite recursions here, i.e.,
			// they should disable all error handling when posting the error with axios to the server.
			throw new Error(error.message);
		},
	);
}

function setupPostLongQueries() {
	// Rewrite GET requests that would exceed typical URL size limits to POST requests
	// (our newer server code, i.e., the Routing framework can handle this)
	axios.interceptors.request.use(
		(request) => {
			if (request.method === "get") {
				// All browsers can handle URLs with 2000 characters: https://stackoverflow.com/a/417184/812379.
				// However, IIS is sometimes limited to 260 characters.
				if (request.url != null && request.url.length > 260) {
					rewriteToPost(request);
				}
			}
			return request;
		}, (error) => {
			return Promise.reject(error);
		},
	);
}

function rewriteToPost(request: AxiosRequestConfig) {
	request.method = "post";
	request.data = request.data || new FormData();

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const parsed = parse(request.url!, true);
	const query = parsed.query;
	parsed.set("query", {});

	for (const [key, value] of Object.entries(query)) {
		if (value == null || value.length < 32) {
			// We keep short bits in the query string, honestly, so that the _= marker parameter
			// is not lost. But also because this makes things possibly a bit more readable.
			parsed.query[key] = value;
		} else {
			parsed.query[key] = MiaPlaza.HttpPipeline.Middlewares.RoutingMiddleware.POST_DATA_MARKER;
			request.data.append(key, value);
		}
	}

	request.url = parsed.href;
	request.params = parsed.query;
}
