import { render, staticRenderFns } from "./RewardAndSocialFeaturesPanel.vue?vue&type=template&id=49bc8712&"
import script from "./RewardAndSocialFeaturesPanel.vue?vue&type=script&lang=ts&"
export * from "./RewardAndSocialFeaturesPanel.vue?vue&type=script&lang=ts&"
import style0 from "./RewardAndSocialFeaturesPanel.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/miaplaza/website/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49bc8712')) {
      api.createRecord('49bc8712', component.options)
    } else {
      api.reload('49bc8712', component.options)
    }
    module.hot.accept("./RewardAndSocialFeaturesPanel.vue?vue&type=template&id=49bc8712&", function () {
      api.rerender('49bc8712', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "wwwroot/typescript/Parents/Control/Vue/LandingPage/RewardAndSocialFeaturesPanel.vue"
export default component.exports