import { HandlerType } from "../base/HandlerType";

export class PasswordInputGroup implements HandlerType {
	private input: JQuery;
	private icon: JQuery;

	private isPasswordShown = false;

	constructor(inputGroup: JQuery) {

		this.input = inputGroup.find("input");
		this.icon = inputGroup.find("i");

		const togglePassword = inputGroup.find("span");
		togglePassword.on("click", this.onToggle.bind(this));
	}

	private onToggle(event: JQuery.Event) {
		if (this.isPasswordShown) {
			this.onHide(event);
		} else {
			this.onShow(event);
		}
		this.isPasswordShown = !this.isPasswordShown;
	}

	private onShow(_event: JQuery.Event) {
		this.input.attr("type", "text");

		this.icon.removeClass("glyphicon-eye-open")
			.addClass("glyphicon-eye-close");
	}

	private onHide(_event: JQuery.Event) {
		this.input.attr("type", "password");

		this.icon.removeClass("glyphicon-eye-close")
			.addClass("glyphicon-eye-open");
	}
}
