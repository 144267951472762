





























import { StateChanger } from "vue-infinite-loading";
import { Component, Watch } from "vue-property-decorator";
import AsyncComputed from "../../AsyncComputed";
import I18Next from "../../I18Next";
import Conditional from "./../../ConditionalReplacement.vue";
import ListItemLoader from "./ListItemLoader.vue";
import VueListBase from "./VueListBase";

@Component({
	components: {
		Conditional,
		InfiniteLoading: async () => (await import("vue-infinite-loading")).default,
		ListItemLoader,
	},
})
export default class InfiniteLoadingList<S, T> extends VueListBase<S, T> {
	// When something goes wrong in this list, often the infinite loader loads items foverer,
	// this tends to leave the browser tab unrespansive, so it's helpful (mostly for development)
	// to intercept errors and display a message instead.
	private hasError = false;

	private infiniteId = +new Date();

	@AsyncComputed({ default: "…", lazy: true })
	protected messageIfError(): Promise<string> {
		return I18Next.tr("ListFailedToLoad_GenericError");
	}

	protected errorCaptured(): void { this.hasError = true; }

	@Watch("isResetting")
	protected onReset(isResetting: boolean): void {
		if (!isResetting) {
			this.goto(0);
			// Restart the infinite loading if we have reached the end of the list in the meantime
			this.infiniteId++;
		}
	}

	private handleInfinite($callback: StateChanger) {
		const lengthBefore = this.items.length;
		void this.preload(this.items.length).then(() => {
			const lengthAfter = this.items.length;
			if (lengthBefore === lengthAfter) {
				$callback.complete();
			} else {
				$callback.loaded();
			}
		});
	}
}
