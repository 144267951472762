import { PasswordInputGroup } from "../../form/PasswordInputGroup";
import { StepType } from "../StepType";
import { RegisterStep } from "./RegisterStep";

/// mobile-detect is a UMD, we can not "import" it, see https://github.com/Microsoft/TypeScript/issues/10178
// eslint-disable-next-line no-var
declare var MobileDetect: any;

export class RegisterChild extends RegisterStep {
	private childFirstName: JQuery;

	constructor(step: JQuery) {
		super(step, StepType.RegisterChild);
		this.step = step;
		this.childFirstName = jQuery("#childFirstName");
	}

	public onStepLoad(): void {
		this.childFirstName = jQuery("#childFirstName");
		if (!new MobileDetect(window.navigator.userAgent).mobile()) {
			this.childFirstName.focus();
		}
		// eslint-disable-next-line no-new
		new PasswordInputGroup(this.step.find(".mia-PasswordInputGroup"));
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public setUpValidation(rules: any): void {
		rules.childFirstName = {
			maxlength: this.childFirstName.data("max-length"),
			normalizer: (value: string) => {
				return value.trim();
			},
			required: true,
		};
	}

	public onStepChanging(_newStepType: StepType): Promise<boolean> {
		this.showProgressIndicator(true, "", true);

		return new Promise((resolve) => {
			const validateUrl = this.step.find(".mia-StepBody").data("validate-url");
			void jQuery.post(validateUrl, this.wizardData)
				.done((data: any) => {
					if (!data.success && data.errorMessage) {
						this.showErrorMessage(data.errorMessage);
					}
					this.hideProgressIndicator();
					resolve(data.success);
				})
				.fail(this.onRequestFailed.bind(this));
		});
	}

	public trackCompletedStepWithGA(): void {
		if (typeof gtag === 'function') {
			gtag('event', 'completed RegisterChild step', {
				event_category: 'register'
			});
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public fillInData(data: any): void {
		data.childFirstName = this.childFirstName.val();
		data.childLastName = this.step.find("#childLastName").val();
		data.childPassword = this.step.find("#childPassword").val();
	}
}
