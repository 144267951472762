










import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { registerModule, useModule } from "vuex-simple";

import SelectedItemModule from "./SelectedItemModule";

@Component
export default class SelectedItemModuleRegistration<T> extends Vue {
	// The Vuex module name, i.e., the namespace of this selection.
	@Prop({required: true, type: String}) protected module!: string;
	// The initially selected item. Note that this prop is not reactive, i.e., updates to it are ignored.
	@Prop({required: false, default: null}) protected selectedItem!: T | null;

	@Watch("module")
	protected moduleChanged(module: string, old: string): void {
		// See ListModuleRegistration for an explanation why this is not supported.
		throw Error(`Module name changed from ${old} to ${module}. You cannot change the name of a registered module. Make sure that the module name is bound to the lifetime of the parent.`);
	}

	protected created(): void {
		if (!this.$store.hasModule(this.module)) {
			registerModule(this.$store, [this.module], new SelectedItemModule<T>(this.selectedItem));
		}
	}

	private get selected(): T | null {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return useModule<SelectedItemModule<T>>(this.$store, [this.module])!.selected;
	}
}
