import debounce from "debounce-promise";
import IModule from "../IModule";
import WrappingModuleAdapterBase from "./WrappingModuleAdapterBase";

export default class DebouncingModuleAdapter<S, T, M extends IModule<S, T>> extends WrappingModuleAdapterBase<S, T, M> {
	private readonly debouncedLoad = debounce(this.loadMany.bind(this), 250, { accumulate: true });

	public async load(keys: S[]): Promise<void> {
		await this.debouncedLoad(keys);
	}

	public loadMany(keys: S[][][]): Array<Promise<void>> {
		const promise = this.wrapped.load(([] as S[]).concat(...keys.map((actualKeys) => ([] as S[]).concat(...actualKeys))));
		return keys.map(() => promise);
	}
}
