import I18Next from "../../Control/Vue/I18Next";
import { MiaPlaza } from "../../Reinforced.Typings";
import { HandlerType } from "../base/HandlerType";
import { prompt } from "./../../Control/Vue/Notifications/Prompts";

export class FeatureLevelSelect implements HandlerType {
	private previousValue?: string;

	constructor(private select: JQuery) {
		select.change(this.onChange.bind(this));

		const currentValue = select.val() as string;
		this.updatePreviousValue(currentValue);
	}

	private onChange(event: JQuery.ChangeEvent) {
		const select = jQuery(event.target);
		const currentValue = select.val() as string;

		if (currentValue === MiaPlaza.Model.FeatureAccessLevel.FullSocial) {
			void prompt(this.select.data("full-social-message"),
				[{label: I18Next.tr("OkCancel_Ok"), value: true}, {label: I18Next.tr("OkCancel_Cancel"), value: false}])
				.then( (isConfirmed) => {
					if (isConfirmed) {
						this.updatePreviousValue(currentValue);
						jQuery(document).trigger("featureLevelChange", currentValue);
					} else {
						if (this.previousValue !== undefined) {
							select.val(this.previousValue);
						}
						select.trigger("change");
					}
				});
		} else {
			jQuery(document).trigger("featureLevelChange", currentValue);
			this.updatePreviousValue(currentValue);
		}
	}

	private updatePreviousValue(newValue: string) {
		this.previousValue = newValue;
	}
}
