/*

	The data backend powering a VueList.

	This is essentially a list of "data" (say member ids) that is loaded lazily from the server.

*/
import { VNode } from "vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { registerModule, useModule } from "vuex-simple";

import { MiaPlaza } from "@/Reinforced.Typings";
import ListModule, { DataProvider } from "./ListModule";

@Component
export default class ListModuleRegistration<T> extends Vue {
	// Raw data can be pulled from this URL by replacing PLACEHOLDER_LIMIT and PLACEHOLDER_OFFSET.
	@Prop({required: false, default: null }) protected dataUrl!: string | DataProvider<T> | null | T[];
	// The Vuex module name, i.e., the namespace of this list.
	@Prop({required: true, type: String}) protected module!: string;
	// Seeded data that has not been pulled through an AJAX call.
	@Prop({required: false, type: Object, default() {
		return {Items: [], TotalCountEstimate: 4, UnfilteredLimit: 0, UnfilteredOffset: 0};
	}}) protected initialData!: MiaPlaza.Control.Vue.Vuex.Shared.IRangeWithOffset<T>;

	protected created(): void {
		if (!this.$store.hasModule(this.module)) {
			registerModule(this.$store, [this.module], new ListModule<T>(this.dataProvider, this.initialData.Items,
				this.initialData.TotalCountEstimate, this.initialData.UnfilteredOffset + this.initialData.UnfilteredLimit));
		} else {
			this.dataProviderChanged();
		}
	}

	@Watch("module")
	protected moduleChange(module: string, old: string): void {
		// We cannot change a module name:
		// * We could unregister the existing module and register the new one.
		//	 However, unregistering is tricky since we do not know whether there are
		//	 any pending actions for this module (or other modules referring to it)
		//	 that would then call mutations/action of the module that would lead to
		//	 errors.
		// * We could leave the existing module alone (that's what we do) and
		//	 register the new one. However, typically, you do not only change the
		//	 module name but also the data-url. There's little control over the order
		//	 in which these two change so was the new url meant for the old module or
		//	 only the new one? (We could seemingly fix this by turning the props here
		//	 into a single object that then updates at once. But this puts the burden
		//	 on the caller to make sure that all the inputs into this prop are
		//	 updated in an atomic way as well which is not sustainable.)
		throw Error(`Module name changed from ${old} to ${module}. You cannot change the name of a registered module. Make sure that the module name is bound to the lifetime of the parent.`);
	}

	@Watch("dataProvider")
	protected dataProviderChanged(): void {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		void useModule<ListModule<T>>(this.$store, [this.module])!.reset(this.dataProvider);
	}

	protected get dataProvider(): DataProvider<T> {
		if (typeof this.dataUrl === "string") {
			return ListModule.makeDataProvider<T>(this.dataUrl);
		}
		if (Array.isArray(this.dataUrl)) {
			return ListModule.makeDataProvider<T>(this.dataUrl);
		}
		if (this.dataUrl === null) {
			if (this.initialData.Items.length !== this.initialData.TotalCountEstimate) {
				throw Error(`When no dataUrl has been provided, the initial data must be complete, i.e., TotalCountEstimate must be ${this.initialData.Items.length}`);
			}
			return ListModule.makeDataProvider<T>(this.initialData.Items);
		}
		return this.dataUrl;
	}

	protected render(): VNode[] | undefined { return undefined; }
}
