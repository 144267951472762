export class CodeInputGroup {
	private readonly codeInput: JQuery;
	private readonly errorMessage: JQuery;
	private readonly applyCodeButton: JQuery;
	private requestPromise: JQueryXHR | null = null;

	constructor(containerId: DOMElementIdentifier, _constructionParameters: ConstructionParameters) {
		const group = jQuery("#" + containerId);

		group.on("resetCodeInputGroup", this.onReset.bind(this));

		this.codeInput = group.find(".mia-codeInput");
		this.errorMessage = group.find(".mia-message");

		this.applyCodeButton = group.find(".mia-applyButton");
		this.applyCodeButton.click(this.onApplyCodeButtonClick.bind(this));

		this.codeInput.on("keypress", (event) => {
			if (event.keyCode === 13) {
				event.preventDefault();
				this.applyCodeButton.trigger("click");
			}
		});
	}

	private onReset(_event: JQuery.Event) {
		this.hideMessage();
		this.codeInput.val("");
	}

	private onApplyCodeButtonClick(event: JQuery.Event) {
		event.preventDefault();

		const code = this.codeInput.val();

		if (!code) {
			this.showMessage(this.codeInput.data("error-empty"), false);
			return;
		}

		if (this.isRequestExecutionAllowed()) {
			this.applyCodeButton.button("loading");

			const url = this.applyCodeButton.data("url").replace("PLH_code", code);
			this.requestPromise = jQuery.post(url)
				.done(this.onApplyCodeSucceeded.bind(this))
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(this.onRequestFailed)
				.promise();
		}
	}

	private showMessage(message: string, success: boolean) {
		this.errorMessage.html(message);
		this.errorMessage.addClass(success ? "success" : "error");
		this.errorMessage.show();
	}

	private hideMessage() {
		this.errorMessage.hide();
		this.errorMessage.removeClass("error");
		this.errorMessage.removeClass("success");
	}

	private onApplyCodeSucceeded(data: any): void {
		this.applyCodeButton.button("reset");
		this.hideMessage();

		if (data.success) {
			if (data.redirectUrl) {
				window.location.href = data.redirectUrl;
			}
			if (data.successMessage) {
				this.showMessage(data.successMessage, true);
			}
			if (data.updatePurchaseSummary) {
				this.updatePurchaseSummary();
			}
			if (data.updateProductSelector) {
				this.updateProductSelector();
			}
		} else {
			this.showMessage(data.errorMessage, false);
		}
	}

	private updateProductSelector() {
		const productSelector = jQuery(".mia-ProductSelector");
		productSelector.trigger("updateProductSelector");
	}

	private updatePurchaseSummary() {
		const purchaseSummary = jQuery(".mia-PurchaseSummary");
		purchaseSummary.trigger("updatePurchaseSummary");

		const informationBox = jQuery(".mia-PurchaseInformationBox");
		informationBox.trigger("updatePurchaseInformationBox");
	}

	private isRequestExecutionAllowed() {
		return this.requestPromise == null || this.requestPromise.state() !== "pending";
	}

	private onRequestFailed(_xhr: JQuery.jqXHR, error: string): void {
		throw new Error(error);
	}
}
