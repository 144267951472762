import axios from "axios";

export class LoginFormChildren {

	protected requestPromise: JQueryXHR | null = null;
	private readonly firstNameInput: JQuery;
	private readonly lastNameSelect: JQuery;
	private readonly passwordInput: JQuery;
	private readonly rememberMeCheckBox: JQuery;

	private readonly loginButton: JQuery;
	private readonly messageArea: JQuery;
	private readonly forgotPasswordLink: JQuery;
	private readonly parentLoginLink: JQuery;

	constructor(containerId: DOMElementIdentifier, private constructionParameters: ConstructionParameters) {
		const container = jQuery("#" + containerId);

		this.firstNameInput = container.find("#firstName");
		this.lastNameSelect = container.find("#lastName");
		this.passwordInput = container.find("#password");
		this.rememberMeCheckBox = container.find("#rememberMe");

		this.loginButton = container.find("#loginButton");
		this.forgotPasswordLink = container.find("#forgotPassword");
		this.parentLoginLink = container.find("#parentLogin");
		this.messageArea = container.find("#messageArea");

		jQuery(document).ready(this.initialize.bind(this));
	}

	private initialize() {
		this.firstNameInput.focus();

		this.loginButton.click(this.onLoginClick.bind(this));
		this.forgotPasswordLink.click(this.onForgotPasswordClick.bind(this));

		this.setUpFormValidation();
	}

	private setUpFormValidation() {
		jQuery("form").validate({
			errorPlacement: (error: JQuery, element: JQuery) => {
				element.parents(".form-group").append(error);
			},
			messages: {
				firstName: {
					required: this.constructionParameters.validationMessageFirstName,
				},
				password: {
					required: this.constructionParameters.validationMessagePassword,
				},
			},
		});
	}

	private onLoginClick(event: JQuery.Event): void {
		event.preventDefault();

		if (!jQuery("form").valid()) {
			return;
		}

		this.loginButton.button("loading");
		if (this.isRequestExecutionAllowed()) {
			const loginData = {
				firstName: this.firstNameInput.val(),
				lastName: this.lastNameSelect.val(),
				password: this.passwordInput.val(),
				rememberMe: this.rememberMeCheckBox.is(":checked"),
			};

			this.requestPromise = jQuery.post(this.constructionParameters.loginUrl, loginData)
				.done(this.onLoginSucceeded.bind(this))
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(this.onRequestFailed)
				.promise();
		}
	}

	private onLoginSucceeded(data: any) {
		this.loginButton.button("reset");

		if (data.success) {
			this.windowRedirectTo(data.redirectUrl);
		} else {
			if (data.message) {
				this.showMessage(data.message);
				if (data.linkText && data.linkUrl) {
					jQuery("<br>").appendTo(this.messageArea);
					jQuery("<a>", {
						href: data.linkUrl,
						text: data.linkText,
					}).appendTo(this.messageArea);
				}
			}
		}
	}

	private showMessage(message: string) {
		this.messageArea.removeClass("hidden");
		this.messageArea.html(message);
	}

	private async onForgotPasswordClick(event: JQuery.Event) {
		event.preventDefault();
		await this.saveChildDataInSessions(this.constructionParameters.saveChildDataInSessionUrl);
		window.location.href = this.constructionParameters.forgotPasswordUrl;
	}

	private async saveChildDataInSessions(url: string) {
		const firstName = this.firstNameInput.val() as string || "" ;
		const lastName = this.lastNameSelect.val() as string;

		await axios.get(url.replace("PLH_lname", lastName).replace("PLH_fname", firstName));
	}

	private windowRedirectTo(location: string): void {
		if (location) {
			window.location.href = location;
		} else {
			window.location.reload();
		}
	}

	private onRequestFailed(xhr: JQuery.jqXHR, error: string) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		throw new Error(`${xhr}: ${error}`);
	}

	private isRequestExecutionAllowed() {
		return this.requestPromise == null || this.requestPromise.state() !== "pending";
	}
}
