import { HandlerType } from "./base/HandlerType";

export class Banner implements HandlerType {
	constructor(private banner: JQuery) {
		jQuery("body").css("transition", "margin .3s");

		const closeButton = banner.find(".mia-close");
		closeButton.click(this.onCloseButtonClick.bind(this));

		setTimeout(this.show.bind(this), 3);
	}

	private show() {
		this.banner.addClass("mia-show-banner");
		this.ensureVisibilityOfBodyContent();

		jQuery(window).on("resize", this.onWindowResize.bind(this));
	}

	private hide() {
		this.banner.removeClass("mia-show-banner");
		jQuery("body").css("margin-bottom", 0);

		jQuery(window).off("resize", this.onWindowResize.bind(this));
	}

	private ensureVisibilityOfBodyContent() {
		const height = this.banner.innerHeight();
		if (height !== undefined) {
			jQuery("body").css("margin-bottom", height);
		}
	}

	private onWindowResize(event: JQuery.Event) {
		event.preventDefault();

		this.ensureVisibilityOfBodyContent();
	}

	private onCloseButtonClick(event: JQuery.Event) {
		event.preventDefault();

		const url = this.banner.find(".mia-close").data("url");
		void jQuery.post(url);

		this.hide();
	}
}
