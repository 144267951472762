












import { Vue, Component, Prop } from "vue-property-decorator";
import CurrencyIcon from "theme/currency_icon.svg";
import FundsModule, { moduleName } from "@/Control/Vue/Vuex/FundsModule";
import { useModule } from "vuex-simple";
import ResponsiveImage from "@/Control/Vue/ResponsiveImage";

@Component({
	components: {
		ResponsiveImage,
	},
})
export default class Funds extends Vue {
	@Prop({ type: String, required: true }) private fundsPageUrl!: string;

	private readonly currencyIcon = CurrencyIcon;

	private get fundsAmount() {
		return useModule<FundsModule>(this.$store, [moduleName])?.formattedAmount;
	}
}
