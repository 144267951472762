import { FormWithValidation } from "../controls/form/FormWithValidation";

export class ChangePassword extends FormWithValidation {
	private readonly newPasswordInput: JQuery;
	private readonly oldPasswordInput: JQuery;

	constructor(element: JQuery) {
		super(element);

		this.newPasswordInput = element.find("#newPassword");
		this.oldPasswordInput = element.find("#oldPassword");
	}

	protected collectFormData(): { newPassword: string, oldPassword: string } {
		return {
			newPassword: this.newPasswordInput.val() as string,
			oldPassword: this.oldPasswordInput.val() as string,
		};
	}

	protected clearInputs(): void {
		this.newPasswordInput.val("");
		this.oldPasswordInput.val("");
	}
}
