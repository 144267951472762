









import { VueConstructor, AsyncComponent } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import BufferedConversion from "../../Vuex/BufferedConversion";
import ConversionModule from "../../Vuex/ConversionModule";
import IModuleAdapter from "../../Vuex/ModuleAdapters/IModuleAdapter";
import Config from "./Items/Config";

@Component({
	components: { BufferedConversion },
})
export default class ListItemLoader<S, T> extends Vue {
	@Prop({ required: true }) protected component!: VueConstructor | string | AsyncComponent;
	@Prop({ required: true }) protected adapter!: IModuleAdapter<S, T, ConversionModule<S, T>>;
	@Prop({ required: true }) protected from!: S;
	@Prop({ required: false, type: Object }) protected itemProps!: Record<string, unknown>;
	@Prop({ required: false, type: Number, default: null }) protected index!: number | null;

	private effectiveComponent(buffered: BufferedConversion<S, T>): VueConstructor | AsyncComponent {
		
		const value = buffered.value?.Component || this.component;
		if (typeof(value) === "string") {
				return Config[value].component;
		}
		return value;
	}
}
