import { HandlerType } from "../controls/base/HandlerType";
import { Async } from "../utils/Async";
import { prompt } from "./../Control/Vue/Notifications/Prompts";

export class FeatureLevelSettings implements HandlerType {
	private readonly featureLevelSelect: JQuery;
	private currentAJAXRequest?: JQueryXHR;

	constructor(container: JQuery) {
		this.featureLevelSelect = container.find(".mia-FeatureLevelSelect");
		this.featureLevelSelect.change(this.onSelectChange.bind(this));
	}

	private onSelectChange(event: JQuery.Event) {
		event.preventDefault();

		if (this.currentAJAXRequest != null) {
			this.currentAJAXRequest.abort();
			this.currentAJAXRequest = undefined;
		}

		const featureLevel = this.featureLevelSelect.val() as string;
		const url = this.featureLevelSelect.data("url")
			.replace("PLH_FeatureAccessLevel", featureLevel);

		this.currentAJAXRequest = jQuery.post(url)
			.done(this.onSubmitDone.bind(this))
			.fail(this.onSubmitFail.bind(this));
	}

	private onSubmitDone(data: any) {
		this.currentAJAXRequest = undefined;
		if (!data.success) {
			this.showErrorMessage();
		}
	}

	private onSubmitFail(xhr: JQuery.jqXHR, error: string) {
		this.currentAJAXRequest = undefined;
		this.showErrorMessage();
		Async.OnRequestFailed(xhr, error);
	}

	private showErrorMessage() {
		void prompt(this.featureLevelSelect.data("error"));
	}
}
