






















import { Component, Vue } from "vue-property-decorator";
import Feature from "@/Control/Vue/Lists/VueList/Items/Feature.vue";

@Component({
	components: {
		Feature
	}
})
export default class RewardAndSocialFeaturesPanel extends Vue { }
