type QueueEntry<T> = {
	key: T;
	generation: number;
}

export default abstract class QueueModule<T> {
	// We count the resets and drop load requests that were issued before the last reset
	private generation = 0;

	// Pending is null if no dequeue is running
	// Otherwise it is a promise that resolves once the running dequeue is finished.
	private pending: Promise<void> | null = null;

	// We queue all load requests and do not perform any additional loads until all pending ones have resolved.
	private loadQueue = [] as Array<QueueEntry<T>>;

	protected enqueue(key: T): void {
		this.loadQueue.push({
			key,
			generation: this.generation,
		});
	}

	// Return a promise that resolves once every item that was in loadQueue before this call has been loaded.
	protected async dequeue(): Promise<void> {
		while (this.pending !== null) {
			await this.pending;
		}

		let requestCompleted = false;
		const request = (async () => {
			try {
				this.loadQueue = this.loadQueue.filter((q) => q.generation === this.generation);
				if (this.loadQueue.length === 0) {
					return;
				}

				const generation = this.generation;

				await this.doLoad(this.loadQueue.map((entry) => entry.key), () => this.generation === generation);
			} finally {
				requestCompleted = true;
				this.pending = null;
			}
		})();
		if (!requestCompleted) {
			// Note that we do net set this.pending if "request" finished synchronously already. Otherwise,
			// the above while loop would loop forever since the request's finally has already run here.
			this.pending = request;
			await this.pending;
		}
	}

	protected markInvalid(): void {
		this.generation++;
	}

	protected abstract doLoad(keys: T[], isValid: () => boolean): Promise<void>;
}
