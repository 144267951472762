import { prompt } from "./../../Control/Vue/Notifications/Prompts";

export class EndFriendshipButton {
	protected readonly endFriendshipButton: JQuery;
	private readonly removeFriendUrl: string;
	private readonly errorMessage: string;

	constructor(button: JQuery) {
		this.endFriendshipButton = button;
		this.removeFriendUrl = button.data("url");
		this.errorMessage = button.data("error-request-fail");
		this.endFriendshipButton.on("click", this.onButtonClick.bind(this));
	}

	private onButtonClick(event: JQuery.Event) {
		event.preventDefault();

		void jQuery.post(this.removeFriendUrl).done(() => {
			jQuery("." + "mia-ChildProfile-endFriendship").remove();
			jQuery("." + "mia-endFriendshipModal").modal("hide");
		})
		.fail(() => {
			void prompt(this.errorMessage);
		});
	}
}
