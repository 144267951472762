import { SnotifyDefaults } from "vue-snotify";

/*
 * A maximum amount of notifications displayed at a time on the screen.
 *
 * It should be assigned both maxOnScreen and maxAtPosition to make it work properly.
 */
const maxToastAmount = 32;

export const SnotifyConfig: SnotifyDefaults = {
	global: {
		maxOnScreen: maxToastAmount,
		maxAtPosition: maxToastAmount
	},
	toast: {
		bodyMaxLength: 2048,
		closeOnClick: true,
		timeout: 0,
	}
}
