import axios from "axios";
import { Async } from "../utils/Async";
import { HandlerType } from "./base/HandlerType";

export class LoginFormParent implements HandlerType {
	protected requestPromise?: JQueryXHR;
	private readonly emailTextBox: JQuery;
	private readonly passwordInput: JQuery;
	private readonly loginButton: JQuery;
	private readonly messageArea: JQuery;
	private readonly rememberMeCheckBox: JQuery;

	constructor(private container: JQuery) {
		this.emailTextBox = this.container.find("#email");
		this.passwordInput = this.container.find("#password");
		this.loginButton = this.container.find("#loginButton");
		this.messageArea = this.container.find("#messageArea");
		this.rememberMeCheckBox = this.container.find("#rememberMe");

		const forgotPasswordLink = container.find(".forgot-password");
		forgotPasswordLink.click(this.onForgotPasswordClick.bind(this));

		const registerLink = container.find(".register");
		registerLink.click(this.onRegisterLinkClick.bind(this));

		this.loginButton.click(this.onLoginClick.bind(this));
		this.setUpFormValidation();
	}

	private async onForgotPasswordClick(event: JQuery.Event) {
		event.preventDefault();
		await this.saveEmailInSession();
		window.location.href = this.container.data("forgot-password-link");
	}

	private onRegisterLinkClick(event: JQuery.Event): void {
		event.preventDefault();
		window.location.href = this.updateEmailInUrl(this.container.data("registerLink"));
	}

	private async saveEmailInSession() {
		const email = this.emailTextBox.val() as string || "";
		await axios.get(this.container.data("save-email-link").replace("PLH_email", email));
	}

	private updateEmailInUrl(url: string): string {
		const email = this.emailTextBox.val() as string || "";
		return url.replace("PLH_email", email);
	}

	private onLoginSucceeded(data: any) {
		this.loginButton.button("reset");

		if (data.success) {
			this.windowRedirectTo(data.redirectUrl);
		} else {
			if (data.message) {
				this.showMessage(data.message);
			}
		}
	}

	private setUpFormValidation() {
		jQuery("form").validate({
			errorPlacement: (error: JQuery, element: JQuery) => {
				element.parents(".form-group").append(error);
			},
			messages: {
				email: {
					email: this.container.data("validationMessageEmailValid"),
					required: this.container.data("validationMessageEmail"),
				},
				password: {
					required: this.container.data("validationMessagePassword"),
				},
			},
		});
	}

	private onLoginClick(event: JQuery.Event): void {
		event.preventDefault();

		if (!jQuery("form").valid()) {
			return;
		}

		this.loginButton.button("loading");

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		if (Async.IsRequestExecutionAllowed(this.requestPromise!)) {
			const loginData = {
				email: this.emailTextBox.val(),
				password: this.passwordInput.val(),
				rememberMe: this.rememberMeCheckBox.is(":checked"),
			};

			this.requestPromise = jQuery.post(this.container.data("loginUrl"), loginData)
				.done(this.onLoginSucceeded.bind(this))
				// eslint-disable-next-line @typescript-eslint/unbound-method
				.fail(this.onRequestFailed)
				.promise();
		}
	}

	private showMessage(message: string) {
		this.messageArea.removeClass("hidden");
		this.messageArea.html(message);
	}

	private windowRedirectTo(location: string): void {
		if (location) {
			window.location.href = location;
		} else {
			window.location.reload();
		}
	}

	private onRequestFailed(_xhr: JQuery.jqXHR, error: string) {
		throw new Error(error);
	}
}
