import * as signalR from "@microsoft/signalr";
import { MiaPlaza } from "../Reinforced.Typings";

/**
 * A lightweight wrapper around `@aspnet/signalr#HubConnection`
 */
export default class SignalRConnection {
	/**
	 * If no signalR.HubConnection exists to the given hub, a new connection is instantiated and started.
	 * Returns a promise that is resolved when the connection is started `@aspnet/signalr#HubConnection`.
	 * Optionally the second parameter is a callback that runs before the connection is started. Use this to
	 * call to bind client methods with `connection.on()`.
	 * @param hub The hub endpoint in the website
	 */
	public static async connect(hub: string, on?: (connection: signalR.HubConnection) => void): Promise<signalR.HubConnection> {
		if (SignalRConnection.hubs[hub] == null) {
			const connection = new signalR.HubConnectionBuilder()
				.withUrl(MiaPlaza.SignalR.Configuration.PATH + hub)
				.withHubProtocol(new signalR.JsonHubProtocol())
				.build();

			// call on callback before start() so handlers are already bound
			if (on) {
				on(connection);
			}
			const promise = connection.start().then(() => connection);
			SignalRConnection.hubs[hub] = promise;
			return promise;
		} else {
			const promise = SignalRConnection.hubs[hub];

			// we need to wait for the promise to resolve. if we call connection.on()
			// while start() has not returned yet, signalr will throw
			if (on) {
				return promise.then((connection) => {
					on(connection)
					return connection;
				});
			}
			return promise;
		}
	}

	private static readonly hubs: { [hub: string]: Promise<signalR.HubConnection>} = {};
}
