var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "conditional",
    {
      attrs: {
        condition: !_vm.isEmpty,
        "message-otherwise": _vm.messageIfEmpty
      },
      scopedSlots: _vm._u(
        [
          {
            key: "empty",
            fn: function() {
              return [_vm._t("replacement")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "div",
        {
          staticClass: "mia-BootstrapTableList",
          class: {
            "table-responsive": _vm.options.backendOptions.responsive !== false
          }
        },
        [
          _c(
            "table",
            {
              class: {
                table: true,
                "table-hover": _vm.options.backendOptions.hover !== false,
                "table-condensed":
                  _vm.options.backendOptions.condensed !== false,
                "table-striped": _vm.options.backendOptions.striped
              }
            },
            [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.columnHeaders, function(header, index) {
                    return _c("th", { key: index }, [
                      _vm._v("\n\t\t\t\t\t\t" + _vm._s(header) + "\n\t\t\t\t\t")
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.visibleItems, function(item, index) {
                  return _c("list-item-loader", {
                    key: index + _vm.offset,
                    attrs: {
                      from: item,
                      "item-props": _vm.$attrs,
                      adapter: _vm.conversionAdapter,
                      component: _vm.options.component
                    }
                  })
                }),
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.hasPreviousPage || _vm.hasNextPage
            ? _c("nav", { staticClass: "center-text" }, [
                _c(
                  "ul",
                  { staticClass: "pagination" },
                  [
                    _c("li", { class: { disabled: !_vm.hasPreviousPage } }, [
                      _c(
                        "a",
                        {
                          staticClass: "paginationButton",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.gotoPage(_vm.page - 1)
                            }
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\t«\n\t\t\t\t\t")]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.paginationPages, function(p) {
                      return _c(
                        "li",
                        { key: p, class: { active: _vm.page === p } },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "paginationButton",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.gotoPage(p)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(p + 1) +
                                  "\n\t\t\t\t\t"
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("li", { class: { disabled: !_vm.hasNextPage } }, [
                      _c(
                        "a",
                        {
                          staticClass: "paginationButton",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.gotoPage(_vm.page + 1)
                            }
                          }
                        },
                        [_vm._v("\n\t\t\t\t\t\t»\n\t\t\t\t\t")]
                      )
                    ])
                  ],
                  2
                )
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }