import { TextEditor } from "./TextEditor";

export abstract class CancellableTextEditor extends TextEditor {
	protected readonly secondaryButton: JQuery;

	constructor(element: JQuery) {
		super(element);

		this.secondaryButton = this.element.find(".mia-texteditor-secondary");
		this.secondaryButton.click(this.onSecondaryClick.bind(this));
	}

	/* Override this to implement on cancel behaviour */
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected abstract onSecondaryClick(res: any): void;
}
