


























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { MiaPlaza } from "./../../../Reinforced.Typings";
import ResponsiveImage from "@/Control/Vue/ResponsiveImage";
import jQuery from "jquery";
import IdGenerator from "@/utils/IdGenerator";

@Component({
	components: {
		ResponsiveImage,
	},
})
export default class Modal extends Vue {
	@Prop({ required: false, default: null }) private cross!: MiaPlaza.Navigation.JSON.Converters.ILazySizes;
	@Prop({ required: false, type: String, default: () => IdGenerator.Generate('modal') }) private modalId!: string;
	@Prop({ required: false, type: Boolean, default: false }) private initiallyShown!: boolean;
	@Prop({ required: false, type: Boolean, default: true }) protected inlineSvgCross!: boolean;

	/**
	 * This Modal vue component is just a wrapper of Bootstrap modal.
	 * And Bootstrap modal is always added to the page. Even when modal is 
	 * closed, the modal content is hidden but rendered into the page.
	 * 
	 * Set this to false, when modal content should not be rendered on the page when modal is closed
	 * (this is useful for heavy modals, e.g. modal containing lists like breaks & holidays modal).
	 */
	@Prop({required: false, type: Boolean, default: true}) private renderModalWhenClosed!: boolean;

	/**
	 * This field is updated on show/hide actions of Bootstrap modal.
	 * Is used to not render the content of modal until it is actually opened when renderModalWhenClosed is true.
	 */
	private shown = false;

	private mounted() {
		const modal = jQuery(`#${this.modalId}`);
		modal.on('hide.bs.modal', () => {
			this.shown = false;
			this.$emit('close');
		});
		modal.on('show.bs.modal', () => {
			this.shown = true;
			this.$emit('open');
		});

		if (this.initiallyShown) {
			modal.modal('show');
		}
	}
}
