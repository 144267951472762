var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mia-Lightbox" }, [
    _c(
      "div",
      {
        staticClass: "modal modal-centered fade in",
        attrs: {
          tabindex: "-1",
          role: "dialog",
          "data-backdrop": "static",
          "data-keyboard": "false"
        }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _vm.showCrossInverted
              ? _c("div", { staticClass: "mia-Cross--inverted" })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: function() {
                      _vm.turnOffTTS()
                      _vm.$emit("close")
                    },
                    expression:
                      "() => {\n\t\t\t\t\tturnOffTTS();\n\t\t\t\t\t$emit('close');\n\t\t\t\t}"
                  }
                ],
                staticClass: "mia-modalContent"
              },
              [_vm._t("default")],
              2
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal-backdrop fade in" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }