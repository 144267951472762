import { errorPrompt, successPrompt } from "../../Control/Vue/Notifications/Prompts";
import { RequestHandler } from "../base/RequestHandler";
import { FormHelper } from "./FormHelper";
import noop from "lodash-es/noop";

/// This class defines a form with inline error handling and submit button.
/// You can inherit it, when building the form, but you must override method collectFormData(),
/// which returns object to send with post request.
/// You can also define custom validation rules and clearing of inputs.
/// Also you can override onSuccess and onError methods, if you don't want messages to pop up.
export abstract class FormWithValidation extends RequestHandler {
	protected readonly form: JQuery;
	protected readonly submitButton: JQuery;

	protected formRules: JQueryValidation.RulesDictionary;

	constructor(element: JQuery) {
		super(element);

		this.form = jQuery("#Form");
		this.submitButton = jQuery(".mia-submitButton");
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		this.formRules = this.form.validate().settings.rules!;
		const errorContainerClass = ".error-container";

		this.form.validate().settings.errorPlacement = (error: JQuery, input: JQuery) => {
			const errorContainer = FormHelper.getParentFormGroup(input).find(errorContainerClass);
			errorContainer.html("");
			errorContainer.append(error);
		};
		this.form.validate().settings.highlight = (input: HTMLElement) => {
			FormHelper.getParentFormGroup(jQuery(input)).addClass("has-error");
		};
		this.form.validate().settings.unhighlight = (input: HTMLElement) => {
			FormHelper.getParentFormGroup(jQuery(input)).removeClass("has-error");
		};
		this.setCustomRules();

		this.submitButton.on("click", this.onSubmitButtonClick.bind(this));
	}

	protected onSubmitButtonClick(event: JQuery.Event): void {
		event.preventDefault();

		const formData = this.collectFormData();

		if (this.form.valid()) {
			const submitUrl = this.submitButton.data("url");
			this.setRequest(jQuery.post(submitUrl, formData));
		}
	}

	protected abstract collectFormData(): Record<string, unknown>;

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onSuccess(res: any): void {
		successPrompt(res.message);
		this.clearInputs();
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	protected onError(res: any): void {
		errorPrompt(res.message);
	}

	protected setCustomRules(): void {
		noop();
	}

	protected clearInputs(): void {
		noop();
	}
}
