// Breakpoints of Bootstrap 3, see https://getbootstrap.com/docs/3.3/css/#grid-options
export const xs = 0;
export const sm = 768;
export const md = 992;
export const lg = 1120;

export class ScreenSize {
	public static isMobile(): boolean {
		return screen.width <= sm;
	}

	public static isTablet(): boolean {
		return screen.width > sm && screen.width <= md;
	}

	public static isDesktop(): boolean {
		return screen.width > md;
	}
}
