var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", {
      directives: [
        {
          name: "tr",
          rawName: "v-tr",
          value: "landingpage_heading_membership",
          expression: "'landingpage_heading_membership'"
        }
      ],
      staticClass: "mia-Heading"
    }),
    _vm._v(" "),
    _c("p", {
      directives: [
        {
          name: "tr",
          rawName: "v-tr",
          value: "landingpage_membershippanel_description",
          expression: "'landingpage_membershippanel_description'"
        }
      ],
      staticClass: "mia-description"
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mia-satisfactionGuaranteedSection" },
      [
        _c(
          "tippy",
          {
            attrs: {
              placement: _vm.isDesktop ? "left" : "top",
              trigger: "click",
              distance: "0"
            },
            scopedSlots: _vm._u([
              {
                key: "trigger",
                fn: function() {
                  return [
                    _c(
                      "span",
                      { staticClass: "mia-satisfactionGuaranteed" },
                      [
                        _c("responsive-image", {
                          attrs: { from: _vm.satisfactionGuaranteedIcon }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          directives: [
                            {
                              name: "tr",
                              rawName: "v-tr",
                              value: "satisfactionguaranteed_title",
                              expression: "'satisfactionguaranteed_title'"
                            }
                          ],
                          staticClass: "col-xs-30"
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "tr",
                  rawName: "v-tr",
                  value:
                    "landingpage_membershippanel_satisfactionguaranteed_tooltip",
                  expression:
                    "'landingpage_membershippanel_satisfactionguaranteed_tooltip'"
                }
              ]
            })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }