






















import { Vue, Component, Prop } from "vue-property-decorator";
import NewIcon from "theme/Icon_New.svg";
import ResponsiveImage from "@/Control/Vue/ResponsiveImage";

@Component({
	components: {
		ResponsiveImage,
	},
})
export default class MiaPrepAnnouncement extends Vue {
	@Prop({ type: Boolean, required: true }) protected includeLink!: boolean;

	private get newIcon(): string {
		return NewIcon;
	}
}
