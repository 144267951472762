var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "conditional",
    {
      attrs: {
        condition: !_vm.isEmpty && !_vm.hasError,
        "message-otherwise": _vm.hasError
          ? _vm.messageIfError
          : _vm.messageIfEmpty
      },
      scopedSlots: _vm._u(
        [
          {
            key: "replacement",
            fn: function() {
              return [_vm._t("empty")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "transition-group",
        { attrs: { name: "mia-InfiniteLoadingList-animation" } },
        _vm._l(_vm.items, function(item) {
          return _c(
            "div",
            { key: item, staticClass: "mia-inifiniteLoadingListItem" },
            [
              _c("list-item-loader", {
                attrs: {
                  from: item,
                  "item-props": _vm.$attrs,
                  adapter: _vm.conversionAdapter,
                  component: _vm.options.component
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("infinite-loading", {
        attrs: { distance: _vm.options.distance, identifier: _vm.infiniteId },
        on: { infinite: _vm.handleInfinite },
        scopedSlots: _vm._u([
          {
            key: "no-results",
            fn: function() {
              return [_c("span")]
            },
            proxy: true
          },
          {
            key: "no-more",
            fn: function() {
              return [_c("span")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }