import axios from "axios";

import { FormHelper } from "./form/FormHelper";
import { FormWithValidation } from "./form/FormWithValidation";

export class ProfileEmailChange extends FormWithValidation {

	private readonly newEmailInput: JQuery;
	private readonly clearBtn: JQuery;

	constructor(element: JQuery) {
		super(element);

		this.newEmailInput = element.find("#email");
		this.clearBtn = element.find(".mia-clear-btn");

		this.clearBtn.on("click", this.onClearButtonClick.bind(this));
		// eslint-disable-next-line @typescript-eslint/unbound-method, @typescript-eslint/no-misused-promises
		this.newEmailInput.on("input", FormHelper.proposeEmailSuggestion);
	}

	protected collectFormData(): any {
		return {
			newEmail: this.newEmailInput.val(),
		};
	}

	protected clearInputs(): void {
		this.newEmailInput.val("");
	}

	protected setCustomRules(): any {
		this.formRules.email = {
			required: true,
		};
	}

	private onClearButtonClick(event: JQuery.Event) {
		event.preventDefault();

		void axios.get(this.element.data("clear-url")).then(() => location.reload());
	}
}
