var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "btn mia-btn-primary mia-HeaderFundControl",
      attrs: { href: _vm.fundsPageUrl }
    },
    [
      _c("responsive-image", {
        staticClass: "mia-currencyIcon",
        attrs: { from: _vm.currencyIcon }
      }),
      _vm._v("\n\t" + _vm._s(_vm.fundsAmount) + "\n")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }