import { VNode } from "vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { registerModule, useModule } from "vuex-simple";

import ConversionModule, { ConversionProvider } from "./ConversionModule";

@Component
export default class ConversionModuleRegistration<S, T> extends Vue {
	@Prop({type: String, required: true}) protected module!: string;
	// Seeded data that has not been pulled through an AJAX call.
	@Prop({required: false, type: Object, default: () => ({})}) protected initialConversions!: {[K: string]: T };
	@Prop({required: false, default: null}) protected conversionUrl!: string | ConversionProvider<S, T> | null;
	// When set, the conversions are not reset when the conversion module is recreated.
	// You might want to set this when a conversion module is used in many places
	// on a page but the contained conversions never change, except when they are
	// invalidated explicitly. When set to true, initialConversions will be loaded 
	// only on module registration (e.g. when this component is added to page for the first time)
	// and will be ignored afterwards.
	@Prop({required: false, type: Boolean, default: false}) shared!: boolean;

	protected created(): void {
		if (!this.$store.hasModule(this.module)) {
			let conversionProvider = this.conversionProvider;
			if (conversionProvider === null) {
				conversionProvider = (keys: S[]) => {
					keys;
					throw Error("No conversions can be loaded dynamically for this list since no conversion-url has been specified.");
				}
			}
			registerModule(this.$store, [this.module], new ConversionModule<S, T>(conversionProvider, this.initialConversions));
		} else if (!this.shared) {
			this.conversionProviderChanged();
		}
	}

	@Watch("conversionProvider")
	protected conversionProviderChanged(): void {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		void useModule<ConversionModule<S, T>>(this.$store, [this.module])!.reset(this.conversionProvider);
	}

	@Watch("module")
	protected moduleChange(module: string, old: string): void {
		// see ListModuleRegistration for an explanation why this is not possible.
		throw Error(`Module name changed from ${old} to ${module}. You cannot change the name of a registered module. Make sure that the module name is bound to the lifetime of the parent.`);
	}

	private get conversionProvider() {
		if (typeof this.conversionUrl === "string") {
			return ConversionModule.makeConversionProvider<S, T>(this.conversionUrl);
		}
		if (this.conversionUrl === null) {
			return (keys: S[]) => Promise.resolve(keys.map((key: any) => {
				if (key in this.initialConversions) {
					return this.initialConversions[key];
				}
				throw Error("No conversions except for the initial-conversions can be loaded dynamically for this list since no conversion-url has been specified.");
			}));
		}
		return this.conversionUrl;
	}

	protected render(): VNode[] | undefined {
		return undefined;
	}
}
