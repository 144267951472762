import { render, staticRenderFns } from "./SwiperList.vue?vue&type=template&id=0bd7f3a8&"
import script from "./SwiperList.vue?vue&type=script&lang=ts&"
export * from "./SwiperList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/miaplaza/website/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0bd7f3a8')) {
      api.createRecord('0bd7f3a8', component.options)
    } else {
      api.reload('0bd7f3a8', component.options)
    }
    module.hot.accept("./SwiperList.vue?vue&type=template&id=0bd7f3a8&", function () {
      api.rerender('0bd7f3a8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "wwwroot/typescript/Control/Vue/Lists/VueList/SwiperList.vue"
export default component.exports