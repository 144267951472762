/*

	A Vuex module that knows about the currently active UI culture and initialized the i18next framework.

*/
import { VNode } from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { registerModule } from "vuex-simple";

import { MiaPlaza } from "../../../Reinforced.Typings";
import I18Next from "../I18Next";
import I18NextModule from "./I18NextModule";

@Component
export default class I18NextModuleRegistration extends Vue {
	@Prop({required: true, type: String}) protected version!: string;
	@Prop({required: true, type: String}) protected xhr!: string;
	@Prop({required: true, type: String}) protected culture!: string;

	protected created(): void {
		registerModule(this.$store, [MiaPlaza.Control.Vue.I18Next.MODULE],
			new I18NextModule(this.culture, this.xhr, this.version));

		void I18Next.initialize(this.$store);
	}

	protected render(): VNode[] | undefined { return undefined; }
}
