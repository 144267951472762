export class Navbar {
	private readonly navbar: JQuery;
	private readonly toggleButton: JQuery;

	constructor(containerId: DOMElementIdentifier, _constructionParameters: ConstructionParameters) {
		this.navbar = jQuery("#" + containerId);

		this.toggleButton = this.navbar.find("#navbarToggleButton");
		this.toggleButton.click(this.onToggleButtonClick.bind(this));

		const link = this.navbar.find(".mia-buyMembershipLink");
		link.click(this.onBuyMembershipLinkClick.bind(this));

		const saleTag = this.navbar.find(".mia-saleTag");
		saleTag.click(this.onSaleTagClick.bind(this));

		jQuery(window).scroll(this.onScroll.bind(this));
		jQuery(window).ready(this.onInit.bind(this));
	}

	private isNavbarOpen(): boolean {
		return this.toggleButton.hasClass("collapsed");
	}

	private colorNavbar() {
		this.navbar.addClass("mia-Navbar-background");
	}

	private transparentNavbar() {
		this.navbar.removeClass("mia-Navbar-background");
	}

	private onToggleButtonClick() {
		if (this.isNavbarOpen()) {
			this.colorNavbar();
		} else if (jQuery(window).scrollTop() === 0) {
			this.transparentNavbar();
		}
	}

	private onBuyMembershipLinkClick(event: JQuery.ClickEvent) {
		event.preventDefault();
		const href = jQuery(event.target).attr("href");
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		window.location.href = href!;
	}

	private onScroll() {
		const windowScrollTop = jQuery(window).scrollTop() || 0;
		if (windowScrollTop > 0) {
			this.colorNavbar();
		} else if (this.isNavbarOpen()) {
			this.transparentNavbar();
		}
	}

	private onInit() {
		const windowScrollTop = jQuery(window).scrollTop() || 0;
		if (windowScrollTop > 0) {
			this.colorNavbar();
		}
	}
	
	private onSaleTagClick(event: JQuery.ClickEvent) {
		event.preventDefault();
		const membershipPanelElement = document.querySelector(".mia-MembershipPanel");

		if (membershipPanelElement == null) {
			const href = jQuery(event.target).attr("href");
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			window.location.href = href!;
		} 
		else {
			membershipPanelElement.scrollIntoView({behavior: "smooth", block: 'center'});
		}
	}
}
