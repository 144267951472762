import Vue from "vue";

import axios from "axios";
import flatten from "lodash-es/flatten";
import uniq from "lodash-es/uniq";
import zipObject from "lodash-es/zipObject";

import { Action, Getter, Mutation, State } from "vuex-simple";
import { MiaPlaza } from "../../../Reinforced.Typings";
import IModule from "./IModule";

import AjaxHelpers = MiaPlaza.Control.Vue.Vuex.Shared.AjaxHelpers;
import QueueModule from "./QueueModule";

export type ConversionProvider<S, T> = (keys: S[]) => Promise<T[]>;

// This is essentially a dictionary mapping "data" (say member ids) to their conversions (say
// detailed member data for display.) This resembles a REST interface but can be a bit more flexible.
// (Note also that implementing REST in ASP.NET was too rigid at the time so it did not seem worth the effort.)
export default class ConversionModule<S, T> extends QueueModule<S[]> implements IModule<S, T> {
	@State()
	protected conversionProvider: ConversionProvider<S, T>;

	@State()
	protected data: {[key: string]: T};

	constructor(conversionProvider: ConversionProvider<S, T>, initialData: {[key: string]: T}) {
		super();
		this.conversionProvider = conversionProvider;
		this.data = initialData;
	}

	// Return a promise that resolves once all keys have been loaded.
	@Action()
	public async load(keys: S[]): Promise<void> {
		if (keys == null || keys.length === 0) {
			return;
		}
		this.enqueue(keys);
		await this.dequeue();
	}

	public static makeConversionProvider<S, T>(converterUrl: string) : ConversionProvider<S, T> {
		return async (keys: S[]) => {
			const response = await axios.get<T[]>(converterUrl
				.replace(AjaxHelpers.PLACEHOLDER_CONVERT_MANY, encodeURIComponent(JSON.stringify(keys))));
			return response.data;
		}
	}

	protected async doLoad(keys: S[][], isValid: () => boolean): Promise<void> {
		const flatKeys = uniq(flatten(keys))
			.filter((key) => this.data[key as unknown as string] === undefined);

		if (flatKeys.length === 0) {
			return;
		}

		const response = await this.conversionProvider(flatKeys);

		if (!isValid()) {
			return;
		}

		this.insert(Object.entries(zipObject(flatKeys as unknown[] as string[], response)));
	}

	@Action()
	public async reset(conversionProvider?: string | ConversionProvider<S, T>): Promise<void> {
		this.markInvalid();
		if (conversionProvider != null) {
			if (typeof(conversionProvider) === "string") {
				conversionProvider = ConversionModule.makeConversionProvider<S, T>(conversionProvider);
			}
			this.conversionProvider = conversionProvider;
		}
		this.doReset();
		await Promise.resolve();
	}

	@Action()
	public async invalidate(key: S): Promise<void> {
		// We do not trigger a reload here. Reloading here is wrong as we might have to wait until some backend action has
		// completed. Also, reloads should be debounced and therefore not be triggered down here.
		this.doInvalidate(key);
		await Promise.resolve();
	}

	@Mutation()
	protected doInvalidate(key: S): void {
		Vue.delete(this.data, key as unknown as string);
	}

	@Mutation()
	protected insert(items: Array<[string, T]>): void {
		for (const [key, value] of items) {
			// see https://vuejs.org/v2/guide/list.html#Caveats for
			// why we cannot do: state.data[at] = item
			Vue.set(this.data, key.toString(), value);
		}
	}

	@Mutation()
	protected doReset(): void {
		this.data = {};
	}

	@Getter()
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public get items(): any {
		return this.data as any;
	}

	@Getter()
	public get isResetting(): boolean {
		return Object.keys(this.data).length === 0;
	}
}
