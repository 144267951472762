/*
 * A responsive list powered by one of several backends.
 */
import { Component, Prop, Provide, Vue } from "vue-property-decorator";
import BufferedListItems from "../../Vuex/BufferedListItems";
import ConversionModule from "../../Vuex/ConversionModule";
import IModuleAdapter from "../../Vuex/ModuleAdapters/IModuleAdapter";
import Config from "./Items/Config";
import {IVueListOptions} from "./VueListOptions";

@Component
export default class VueListBase<S, T> extends Vue {
	@Prop({ type: Object, required: true }) protected bufferedItems!: BufferedListItems<S>;
	@Prop({ type: Object, required: true }) protected conversionAdapter!: IModuleAdapter<S, T, ConversionModule<S, T>>;
	@Prop({ type: String, required: true}) protected template!: string;

	/**
	 * Make sure that the item at offset is visible, possibly focusing it.
	 * @param offset zero-based index into items.
	 */
	public goto(_offset: number): void {
		void this.preload(0);
	}

	// Discard the content of this list from the store (and reload it from the
	// server.) Note that this does not reset the conversions in the
	// converterModule, use resetItem() for this.
	@Provide() protected reset(until?: Promise<unknown>): Promise<void> { return this.bufferedItems.invalidate(until); }

	// Fetch more items (the previous and the next page or more) in the background.
	protected preload(currentOffset: number): Promise<unknown> {
		return this.bufferedItems.preload(currentOffset);
	}

	protected get options(): IVueListOptions { return Config[this.template]; }

	// The items displayed in this list.
	protected get items(): S[] { return this.bufferedItems.value || []; }

	// The number of items to preload for the next page.
	// Note that this should be the maximum number of items that fit onto one page in any
	// resolution so that a higher resolution page does not create empty columns.
	protected get pageSize(): number {
		// The default options, i.e., the ones not inside .responsive are for the maximum resolution.
		return this.options.pageSize;
	}

	// An approximate count of the total numbers of items that can be provided by the backend.
	// Might change as more items are loaded.
	protected get totalCount(): number { return this.bufferedItems.totalCountEstimate; }

	protected get isEmpty(): boolean { return this.bufferedItems.totalCountEstimate === 0; }

	protected get messageIfEmpty(): string {
		return this.$tr(`${this.template}_EmptyList`);
	}

	protected get isResetting(): boolean {
		return this.bufferedItems.isResetting;
	}
}
